import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {Button, Card, Container, Grid, Header, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {useQuery} from '../../library/useQuery';
import {useHistory} from 'react-router';
import Network from '../../library/Network';

const BusinessBookCategoryMainContainer = (props) => {


  useEffect(() => {

  }, []);

  return (
    <>
      <Container as={Segment} vertical>
        <Header content={'카테고리 관리'}/>
      </Container>
      <Container as={Segment} vertical>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={'신청불가 카테고리'}/>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    content={'자세히 보기'}
                    as={Link}
                    to={'/business-book-category/list/disabled'}
                  />
                </Card.Content>
              </Card>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={'카테고리 전체'}/>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    content={'자세히 보기'}
                    as={Link}
                    to={'/book-category/list'}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={'신청불가 예외처리 책'}/>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    content={'자세히 보기'}
                    as={Link}
                    to={'/business-book-category/book/list/exception'}
                  />
                </Card.Content>
              </Card>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={'신청불가책'}/>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    content={'자세히 보기'}
                    as={Link}
                    to={'/business-book-disabled/list'}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessBookCategoryMainContainer);
