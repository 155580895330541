import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const EventTempMonthlyHashTagMainContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  return (
    <div>
      <Button as={Link} to={'/event/temp/monthly-hash-tag/1?hashTag=5월의스크랩'} content={'5월의스크랩'}/>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(EventTempMonthlyHashTagMainContainer);
