import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";
import { Button, Container, Form, Pagination, Segment, Table } from "semantic-ui-react";
import { useQuery } from "../../library/useQuery";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import { useHistory } from "react-router";
import Network from '../../library/Network';
import { Link } from "react-router-dom";

const BusinessBookRequestListContainer = (props) => {

  const history = useHistory();
  let { startDate, endDate, activePage, perPage } = useQuery();
  startDate = !!startDate ? startDate : moment().startOf("month").toISOString();
  endDate = !!endDate ? endDate : moment().endOf("month").toISOString();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;

  const [isLoaded, setIsLoaded] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [businessBookRequests, setBusinessBookRequests] = useState([]);


  const _load = async () => {
    setBusinessBookRequests([]);
    const res = await Network.businessBookRequestListGET(
      {
        offset: ( Number(activePage || 1) - 1 ) * Number(perPage),
        limit: perPage,
        startDate,
        endDate,
      },
    );
    if(!res.err) {
      setIsLoaded(true);
      setBusinessBookRequests(res.businessBookRequests);
      setTotalPage(res.meta?.totalPage);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setBusinessBookRequests([]);
    _load();
  }, [activePage, startDate, endDate, perPage]);

  const _handleChangeDateAndUnit = (startDate, endDate, activePage, perPage) => {
    history.push(`/business-book-request/list?startDate=${moment(startDate).toISOString()}&endDate=${moment(endDate).toISOString()}&activePage=${activePage}&perPage=${perPage}`);
  };


  if(!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical>
        <Form>
          <Form.Group style={{ alignItems: "flex-end" }}>
            <Form.Dropdown
              label={"표시개수"}
              selection
              value={perPage}
              onChange={(e, { value }) => {
                console.log({ value });
                _handleChangeDateAndUnit(startDate, endDate, 1, value);
              }}
              options={[
                { key: 0, text: "20", value: 20 },
                { key: 1, text: "50", value: 50 },
                { key: 2, text: "100", value: 100 },
                { key: 3, text: "500", value: 500 },
                { key: 4, text: "5000", value: 5000 },
              ]}
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, { value }) => {
                                  if(!value) return;
                                  _handleChangeDateAndUnit(value, endDate, 1, perPage);
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, { value }) => {
                                  if(!value) return;
                                  _handleChangeDateAndUnit(startDate, value, 1, perPage);
                                }}/>
            <Button.Group>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).subtract(1, "month").startOf("month").toISOString(),
                    moment(startDate).subtract(1, "month").endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                icon={"chevron left"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment().startOf("month").toISOString(),
                    moment().endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                content={"이번달"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).add(1, "month").startOf("month").toISOString(),
                    moment(startDate).add(1, "month").endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                icon={"chevron right"}/>
            </Button.Group>
          </Form.Group>
        </Form>
      </Container>
      <Container as={Segment} vertical>
        <Pagination totalPages={totalPage}
                    defaultActivePage={1}
                    activePage={activePage}
                    onPageChange={(e, { activePage }) => {
                      _handleChangeDateAndUnit(startDate, endDate, activePage, perPage);
                    }}/>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={"No."} width={1}/>
              <Table.HeaderCell content={"id"} width={2}/>
              <Table.HeaderCell content={"이름"} width={2}/>
              <Table.HeaderCell content={"부서"} width={2}/>
              <Table.HeaderCell content={"title"} width={5}/>
              <Table.HeaderCell content={"신청일"} width={2}/>
              <Table.HeaderCell content={""}/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {businessBookRequests?.map((o, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell content={( ( Number(activePage) || 1 ) - 1 ) * 10 + i + 1}/>
                  <Table.Cell>
                    <Link to={`/business-book-request/${o.id}`}>
                      {o.id}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/user/${o.userId}`}>
                      {o.user?.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {o.user?.businessDepartment?.title}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/book/${o.bookId}`}>
                      {o.book?.title}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {moment(o.createdAt).format('YYYY.MM.DD(ddd)HH:mm')}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessBookRequestListContainer);
