import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";
import { Button, Container, Form, Message, Pagination, Segment, Table } from "semantic-ui-react";
import { useQuery } from "../../library/useQuery";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import numeral from "numeral";
import { useHistory } from "react-router";
import Truncate from "react-truncate";
import ReactExport from "react-export-excel";
import _ from "lodash";
import Network from '../../library/Network';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const BusinessBookRequestCalculateContainer = (props) => {

  const history = useHistory();
  let { startDate, endDate, activePage, perPage } = useQuery();
  startDate = !!startDate ? startDate : moment().startOf("month").toISOString();
  endDate = !!endDate ? endDate : moment().endOf("month").toISOString();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;

  const [isLoaded, setIsLoaded] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [businessBookRequests, setBusinessBookRequests] = useState([]);
  const [excelDataset, setExcelDataset] = useState([]);


  const _load = async () => {
    setBusinessBookRequests([]);
    const res = await Network.businessBookRequestListForSalesGET(
      {
        offset: ( Number(activePage || 1) - 1 ) * Number(perPage),
        limit: perPage,
        startDate,
        endDate,
      },
    );
    if (!res.err) {
      setIsLoaded(true);
      setBusinessBookRequests(res.businessBookRequests);
      setTotalPage(res.meta?.totalPage);
      setTotal(res.meta?.total);
      const excelData = [];
      for (let i = 0; i < res.businessBookRequests.length; i++) {
        const data = {
          ...res.businessBookRequests[i],
          total: res.businessBookRequests[i].priceDiscounted + res.businessBookRequests[i].deliveryCost,
          createdAt: moment(res.businessBookRequests[i].createdAt).format("YYYY. M. D(ddd) HH:mm"),
        };
        excelData.push(data);
      }
      setExcelDataset(excelData);
    }
  };

  useEffect(() => {
    setBusinessBookRequests([]);
    _load();
  }, [activePage, startDate, endDate, perPage]);

  const _handleChangeDateAndUnit = (startDate, endDate, activePage, perPage) => {
    console.log({ perPage, activePage });
    history.push(`/business-book-request/for-calculate?startDate=${moment(startDate).toISOString()}&endDate=${moment(endDate).toISOString()}&activePage=${activePage}&perPage=${perPage}`);
  };


  if (!isLoaded) return <LoaderComponent/>;

  const _renderDownload = () => {
    return (
      <ReactExport.ExcelFile
        element={<Button disabled={excelDataset.length === 0} icon={"download"} content={"엑셀 파일 다운로드"}/>}
        filename={`도서신청내역_${moment(startDate).format("YYYYMMDD")}_${moment(endDate).format("YYYYMMDD")}_${excelDataset.length}건_createdAt${moment().format("YYMMDD_HHmmss")}`}>
        <ReactExport.ExcelFile.ExcelSheet data={excelDataset} name={"data"}>
          <ReactExport.ExcelFile.ExcelColumn label={"신청Id"} value={"id"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"사번"} value={"companyIdNumber"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"이름"} value={"name"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"조직명"} value={"businessDepartmentTitle"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"도서명"} value={"bookTitle"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"판매가"} value={"priceDiscounted"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"배송비"} value={"deliveryCost"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"합계"} value={"total"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"신청구분"} value={"businessBookRequestType"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"상태"} value={"businessBookRequestStatus"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"신청일"} value={"createdAt"}/>
        </ReactExport.ExcelFile.ExcelSheet>
      </ReactExport.ExcelFile>
    );
  };

  return (
    <>
      <Container as={Segment} vertical>
        <Form>
          <Form.Group style={{ alignItems: "flex-end" }}>
            <Form.Dropdown
              label={"표시개수"}
              selection
              value={perPage}
              onChange={(e, { value }) => {
                console.log({ value });
                _handleChangeDateAndUnit(startDate, endDate, 1, value);
              }}
              options={[
                { key: 0, text: "20", value: 20 },
                { key: 1, text: "50", value: 50 },
                { key: 2, text: "100", value: 100 },
                { key: 3, text: "500", value: 500 },
                { key: 4, text: "5000", value: 5000 },
              ]}
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(value, endDate, 1, perPage);
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(startDate, value, 1, perPage);
                                }}/>
            <Button.Group>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).subtract(1, "month").startOf("month").toISOString(),
                    moment(startDate).subtract(1, "month").endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                icon={"chevron left"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment().startOf("month").toISOString(),
                    moment().endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                content={"이번달"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).add(1, "month").startOf("month").toISOString(),
                    moment(startDate).add(1, "month").endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                icon={"chevron right"}/>
            </Button.Group>
          </Form.Group>
          {_renderDownload()}
        </Form>
      </Container>
      <Container as={Segment} vertical>
        <Pagination totalPages={totalPage}
                    activePage={activePage}
                    onPageChange={(e, { activePage }) => {
                      _handleChangeDateAndUnit(startDate, endDate, activePage, perPage);
                    }}/>
        <Message>
          총 {numeral(total).format("0,0")}건의 내역이 있습니다. <br/>
          금액 {numeral(_.sumBy(businessBookRequests, o => ( o.priceDiscounted + o.deliveryCost ))).format("0,0")} 원
        </Message>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={"No."} width={1}/>
              <Table.HeaderCell content={"bbrId"} width={1}/>
              <Table.HeaderCell content={"사번"} width={1}/>
              <Table.HeaderCell content={"이름"}/>
              <Table.HeaderCell content={"조직명"} width={1}/>
              <Table.HeaderCell content={"도서명"}/>
              <Table.HeaderCell content={"가격"}/>
              <Table.HeaderCell content={"배송비"}/>
              <Table.HeaderCell content={"신청구분"}/>
              <Table.HeaderCell content={"상태"}/>
              <Table.HeaderCell content={"신청일"}/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {businessBookRequests?.map((o, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell content={( ( Number(activePage) || 1 ) - 1 ) * 10 + i + 1}/>
                  <Table.Cell content={o.id}/>
                  <Table.Cell content={o.companyIdNumber}/>
                  <Table.Cell content={o.name}/>
                  <Table.Cell style={{ maxWidth: "100px" }}>
                    <Truncate lines={1}>
                      {o.businessDepartmentTitle}
                    </Truncate>
                  </Table.Cell>
                  <Table.Cell style={{ maxWidth: "150px" }}>
                    <Truncate lines={1}>
                      {o.bookTitle}
                    </Truncate>
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    {numeral(o.priceDiscounted).format("0,0")}
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    {numeral(o.deliveryCost).format("0,0")}
                  </Table.Cell>
                  <Table.Cell>
                    {o.businessBookRequestType}
                  </Table.Cell>
                  <Table.Cell>
                    {o.businessBookRequestStatus}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(o.createdAt).format("MM.DD(ddd)HH:mm")}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessBookRequestCalculateContainer);
