import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, Container, Form, Grid, Header, Segment, Table } from "semantic-ui-react";
import moment from "moment";
import { useHistory } from "react-router";
import { useQuery } from "../../library/useQuery";
import { Bar, Line } from "react-chartjs-2";
import LoaderComponent from "../../components/shared/LoaderComponent";
import _ from "lodash";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Link } from "react-router-dom";
import Network from '../../library/Network';

const BusinessLearningCreditMainContainer = (props) => {


  const history = useHistory();
  let { startDate, endDate, unit } = useQuery();
  unit = !!unit ? unit : "day";
  startDate = !!startDate ? startDate : moment().startOf("month").toISOString();
  endDate = !!endDate ? endDate : moment().endOf("month").toISOString();
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});
  const [businessLearningCreditsByBusinessBookRequest, setBusinessLearningCreditsByBusinessBookRequest] = useState([]);
  const [sumOfBusinessLearningCreditsByBusinessBookRequest, setSumOfBusinessLearningCreditsByBusinessBookRequest] = useState([]);
  const [businessLearningCreditsByBusinessBookForum, setBusinessLearningCreditsByBusinessBookForum] = useState([]);
  const [sumOfBusinessLearningCreditsByBusinessBookForum, setSumOfBusinessLearningCreditsByBusinessBookForum] = useState([]);
  const [dates, setDates] = useState([]);


  const _load = async () => {
    const res = await Network.businessLearningCreditMainGET(
      {
        startDate,
        endDate,
        unit,
      },
    );
    if (!res.err) {
      setIsLoaded(true);
      setBusinessLearningCreditsByBusinessBookRequest(res.businessLearningCreditsByBusinessBookRequest);
      setBusinessLearningCreditsByBusinessBookForum(res.businessLearningCreditsByBusinessBookForum);
      setSumOfBusinessLearningCreditsByBusinessBookRequest(res.sumOfBusinessLearningCreditsByBusinessBookRequest);
      setSumOfBusinessLearningCreditsByBusinessBookForum(res.sumOfBusinessLearningCreditsByBusinessBookForum);
    }
  };

  const _generateXAxis = () => {
    const dates = [];
    for (let i = 0; moment(startDate).add(i, 'day').isSameOrBefore(moment(endDate)); i++) {
      dates.push(moment(startDate).add(i, "day").format("YYYY-MM-DD"));
    }
    setDates(dates);
  };

  useEffect(() => {
    _generateXAxis();
    _load();
  }, [startDate, endDate, unit]);

  const _handleChangeDateAndUnit = (startDate, endDate, unit) => {
    history.push(`/business-learning-credit?startDate=${moment(startDate).toISOString()}&endDate=${moment(endDate).toISOString()}&unit=${unit}`);
  };

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical>
        <Header content={"교육 이수"}/>
        <Form>
          <Form.Group style={{ alignItems: "flex-end" }}>

            <Form.Dropdown
              label={"단위"}
              value={unit}
              onChange={(e, { value }) => {
                _handleChangeDateAndUnit(startDate, endDate, value);
              }}
              options={[
                { text: "일", value: "day", key: 0 },
                { text: "주", value: "week", key: 1 },
                { text: "월", value: "month", key: 2 },
              ]}
              selection
            />

            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(value, endDate, unit);
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(startDate, value, unit);
                                }}/>
            <Button.Group>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).subtract(1, "month").startOf("month").toISOString(),
                    moment(startDate).subtract(1, "month").endOf("month").toISOString(),
                    unit,
                  );
                }}
                icon={"chevron left"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment().startOf("month").toISOString(),
                    moment().endOf("month").toISOString(),
                    unit,
                  );
                }}
                content={"이번달"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).add(1, "month").startOf("month").toISOString(),
                    moment(startDate).add(1, "month").endOf("month").toISOString(),
                    unit,
                  );
                }}
                icon={"chevron right"}/>
            </Button.Group>
          </Form.Group>
        </Form>
      </Container>
      <Container as={Segment} vertical>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"도서신청"}/>
                  <div style={{}}>
                    <Line
                      height={300}
                      data={{
                        labels: sumOfBusinessLearningCreditsByBusinessBookRequest.map(o => moment(o.day).format('MM.DD')),//_.map(numberOfBookRequestsGroupByDate, o => moment(o.day).format('YYYY-MM-DD')),
                        datasets: [{
                          label: `도서 신청 이수 건수`,
                          data: sumOfBusinessLearningCreditsByBusinessBookRequest.map(o => o.count),
                          // data: sumOfBusinessLearningCreditsByBusinessBookRequest.map((o, i) => {
                          //
                          //   return {
                          //     x: moment(sumOfBusinessLearningCreditsByBusinessBookRequest[i].day).toISOString(),
                          //     y: o.count,
                          //   };
                          // }),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        scales: {
                          x: {
                            // type: 'time',
                            display: true,
                            offset: true,
                            time: {
                              unit: 'day'
                            }
                          },
                        },
                      }}
                    />
                  </div>

                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"토론회"}/>
                  <div style={{}}>
                    <Bar
                      height={300}
                      data={{
                        labels: sumOfBusinessLearningCreditsByBusinessBookForum.map(o => moment(o.day).format("YYYY-MM-DD")),//_.map(numberOfBookRequestsGroupByDate, o => moment(o.day).format('YYYY-MM-DD')),
                        datasets: [{
                          label: `토론회 이수 건수`,
                          data: _.map(sumOfBusinessLearningCreditsByBusinessBookForum, o => o.count),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        scales: {
                          yAxes: [{
                            stacked: true,
                          }],
                          xAxes: [{
                            stacked: true,
                            // distribution: 'series',
                            type: "time",
                            time: {
                              unit: "day",
                              // distribution:'series',
                              displayFormats: {
                                day: "MM/DD",
                                week: "MM/DD",
                              },
                            },

                          }],
                        },
                      }}
                    />
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"최근 내역"}/>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell content={"id"}/>
                        <Table.HeaderCell content={"이름"}/>
                        <Table.HeaderCell content={"사번"}/>
                        <Table.HeaderCell content={"지급일"}/>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        businessLearningCreditsByBusinessBookRequest?.map((o, i) => {
                          return (
                            <Table.Row key={i}>
                              <Table.Cell>
                                {o.id}
                              </Table.Cell>
                              <Table.Cell>
                                {o.user?.name}
                              </Table.Cell>
                              <Table.Cell>
                                {o.user?.businessDepartment?.title}
                              </Table.Cell>
                              <Table.Cell>
                                {moment(o.createdAt).format("MM.DD(ddd)")}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      }
                    </Table.Body>
                  </Table>
                  <Button basic
                          primary
                          as={Link}
                          to={`/business-learning-credit/list?type=BUSINESS_BOOK_REQUEST`}
                          floated={"right"}
                          content={"더보기"}
                  />
                </Card.Content>
              </Card>

            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"최근 내역"}/>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell content={"id"}/>
                        <Table.HeaderCell content={"이름"}/>
                        <Table.HeaderCell content={"사번"}/>
                        <Table.HeaderCell content={"지급일"}/>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        businessLearningCreditsByBusinessBookForum?.map((o, i) => {
                          return (
                            <Table.Row key={i}>
                              <Table.Cell>
                                {o.id}
                              </Table.Cell>
                              <Table.Cell>
                                {o.user?.name}
                              </Table.Cell>
                              <Table.Cell>
                                {o.user?.businessDepartment?.title}
                              </Table.Cell>
                              <Table.Cell>
                                {moment(o.createdAt).format("MM.DD(ddd)")}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      }
                    </Table.Body>
                  </Table>
                  <Button basic
                          primary
                          as={Link}
                          to={`/business-learning-credit/list?type=FORUM`}
                          floated={"right"}
                          content={"더보기"}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessLearningCreditMainContainer);
