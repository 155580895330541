import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {Table} from 'semantic-ui-react';
import numeral from 'numeral';

const OrderComponent = (props) => {

  const [state, setState] = useState({})

  const {order} = props;

  if (!order) return null;

  return (
    <div>
      <h4>
        order
      </h4>
      <ul>
        <li>
          id: {order.id}
        </li>
      </ul>
      <h4>
        orderDetails ({order?.orderDetails?.length})
      </h4>
      <Table size={'small'} compact={'very'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              odId
            </Table.HeaderCell>
            <Table.HeaderCell>
              productId
            </Table.HeaderCell>
            <Table.HeaderCell>
              price
            </Table.HeaderCell>
            <Table.HeaderCell>
              quantity
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            order?.orderDetails?.map((od, i) => {
              return (
                <Table.Row
                  key={i}>
                  <Table.Cell>
                    {od.id}
                  </Table.Cell>
                  <Table.Cell>
                    {od.productId}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(od.price).format('0,0')}
                  </Table.Cell>
                  <Table.Cell>
                    {od.quantity}
                  </Table.Cell>
                </Table.Row>
              )
            })
          }
        </Table.Body>
      </Table>
      <h4>
        orderDelivery
      </h4>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>name</Table.Cell>
            <Table.Cell>{order.orderDelivery?.name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>address</Table.Cell>
            <Table.Cell>{order.orderDelivery?.address}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>phoneNumber</Table.Cell>
            <Table.Cell>{order.orderDelivery?.phoneNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>orderDeliveryStatus</Table.Cell>
            <Table.Cell
              style={{
                color: order.orderDelivery?.orderDeliveryStatus?.color || ''
              }}
            >{order.orderDelivery?.orderDeliveryStatus?.title}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>url</Table.Cell>
            <Table.Cell>
              {
                !!order.orderDelivery?.url ? (
                  <a href={order.orderDelivery?.url || ''} target={'_blank'}>
                    {order.orderDelivery?.url || '등록된 URL이 없습니다. '}
                  </a>
                ) : (
                  <>
                    등록된 URL이 없습니다.
                  </>
                )
              }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(OrderComponent);
