import {createLogic} from 'redux-logic';
import {authActionCreators, authActions} from "./authStore";
import {auth} from "../../constants/firebase";
import Network from '../../library/Network';

const appInitializeLogic = createLogic({
  type: authActions.APP_INITIALIZE,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    done();

  },
});
const signInLogic = createLogic({
  type: authActions.SIGN_IN,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    const res = await Network.signInWithSignInIdAndPassword(action.signInId, action.password, action.businessId);
    // TODO 로그인 처리 하고,
    if (!res.err) {
      // TODO 성공하였을 때,
      auth.signInWithCustomToken(res.firebaseToken)
        .then(user => {
          console.log({user});
        })
        .catch(e => {
          console.log({e});
        });

      dispatch(authActionCreators.signInSuccess(res.user));
    } else {
      // TODO 로그인 실패하였을 때, 처리
      dispatch(authActionCreators.signInFailure("비밀번호가 잘못되었습니다."));
    }

    action?.cb?.()


    done();

  },
});


const signOutLogic = createLogic({
  type: authActions.SIGN_OUT,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {

    auth.signOut()
      .then(() => {
        Network.setFirebaseUid(null);
        // Network_READERS.setFirebaseUid(null);
        done();
      })
      .catch(() => {
        done();
      })

  },
});


const fetchMyInfoLogic = createLogic({
  type: authActions.FETCH_MY_INFO,
  latest: true,
  process: async ({getState, action}, dispatch, done) => {
    const res = await Network.userMyGET({});
    if (!res.err) {
      console.log(res);
      dispatch(authActionCreators.fetchMyInfoSuccess(res.user));
    }
    action?.cb?.();
    done();
  }
})


export default [
  appInitializeLogic,
  signInLogic,
  signOutLogic,
  fetchMyInfoLogic,
];
