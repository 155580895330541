import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";

const BusinessContentGroupMainContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});

  const _load = async () => {

  };

  useEffect(() => {
    window.scrollTo(0, 0);
    _load();
  }, []);

  if (!isLoaded) return <LoaderComponent text={"BusinessContentGroupMainContainer"}/>;

  return (
    <div>
      BusinessContentGroupMainContainer
    </div>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessContentGroupMainContainer);
