import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Container, Image, Message, Segment, Tab, Table} from "semantic-ui-react";
import {useQuery} from "../../library/useQuery";
import {useHistory} from "react-router";
import UserComponent from "../../components/user/UserComponent";
import removeSubTitle from '../../library/removeSubTitle';
import Network from '../../library/Network';
import Truncate from 'react-truncate';
import moment from 'moment';
import {Link} from 'react-router-dom';

const SearchMainContainer = (props) => {

  let {keyword, itemType, activePage, perPage} = useQuery();
  itemType = !!itemType ? itemType : "USER";
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false)
  const [searching, setSearching] = useState(false)
  const [searchedList, setSearchedList] = useState([])

  const _load = async () => {
    setSearching(true);
    const res = await Network.searchGET(
      {
        itemType,
        keyword,
        offset: (activePage - 1) * perPage,
        limit: perPage,
        aladinCategoryId: null,
        // method: "es",
      },
    )
    setSearching(false);
    if (!res.err) {
      setIsLoaded(true);
      setSearchedList(res.searchedList);
    }
  }

  useEffect(() => {
    setSearchedList([]);
    _load();
  }, [keyword, itemType, activePage, perPage])

  const _handleChange = ({}) => {
    history.push(`/search?keyword=${keyword}&itemType=${itemType}&activePage=${activePage}&perPage=${perPage}`)
  }

  if (!isLoaded) return <LoaderComponent/>;

  const _renderSearchedList = () => {
    if (searching) return <LoaderComponent text={'검색중'}/>
    switch (itemType) {
      case "USER":
        return (
          <>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    No.
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    유저
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    사번
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    -
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  searchedList.map((o, i) => {
                    return (
                      <Table.Row key={o.id}>
                        <Table.Cell>{(activePage - 1) * perPage + 1 + i}</Table.Cell>
                        <Table.Cell>
                          <UserComponent user={o}/>
                        </Table.Cell>
                        <Table.Cell>
                          {o.businessMember?.companyIdNumber}
                        </Table.Cell>
                        <Table.Cell>
                          <Link to={`/business-department/${o.businessDepartment?.id}`}>
                            {o.businessDepartment?.title}
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
            </Table>
          </>
        )
      case "BOOK":
        return (
          <>
            <Table singleLine compact={'very'}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>No.</Table.HeaderCell>
                  <Table.HeaderCell width={1}>BookId</Table.HeaderCell>
                  <Table.HeaderCell width={1}>-</Table.HeaderCell>
                  <Table.HeaderCell width={5}>제목</Table.HeaderCell>
                  <Table.HeaderCell width={3}>저자</Table.HeaderCell>
                  <Table.HeaderCell width={2}>출판사</Table.HeaderCell>
                  <Table.HeaderCell width={2}>출판일</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  searchedList?.map((o, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{(activePage - 1) * perPage + 1 + i}</Table.Cell>
                        <Table.Cell>
                          <Link to={`/book/${o.id}`}>
                            {o.id}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          <Image size={'mini'} src={o.coverImageUrl}/>
                        </Table.Cell>
                        <Table.Cell style={{}}>
                          <Link to={`/book/${o.id}`}>
                            {removeSubTitle({book: o})}
                          </Link>
                        </Table.Cell>
                        <Table.Cell style={{maxWidth: "100px"}}>
                          <Truncate lines={1}>
                            {o.author}
                          </Truncate>
                        </Table.Cell>
                        <Table.Cell>
                          {o.publisher}
                        </Table.Cell>
                        <Table.Cell>
                          {moment(o.pubDate).format('YYYY-MM')}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                }
              </Table.Body>
            </Table>
          </>
        )

      case "SCRAP":
      default:
        return null;
    }
  }

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        {_renderSearchedList()}
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(SearchMainContainer);
