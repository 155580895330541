import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {useQuery} from '../../library/useQuery';
import {useHistory} from 'react-router';
import Network from '../../library/Network';
import {Container, List, Message, Modal, Pagination, Segment, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const BusinessBookDisabledListContainer = (props) => {

  let {activePage, perPage, keyword} = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;
  keyword = !!keyword ? keyword : '';
  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false);
  const [meta, setMeta] = useState({})
  const [businessBooksDisabled, setBusinessBooksDisabled] = useState([])

  const [newModalOpen, setNewModalOpen] = useState(false);


  const _load = async () => {

    let res;
    if (!!keyword) {
      res = await Network.businessBookDisabledSearchGET(
        {
          keyword,
          offset: (activePage - 1) * perPage,
          limit: perPage,
        }
      )
    } else {
      res = await Network.businessBookDisabledListGET(
        {
          offset: (activePage - 1) * perPage,
          limit: perPage,
        }
      )
    }
    if (!res.err) {
      setIsLoaded(true);
      setBusinessBooksDisabled(res.businessBooksDisabled)
      setMeta(res.meta);
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    setBusinessBooksDisabled([]);
    _load();
  }, [])

  const _handleChangeQuery = (
    {
      newActivePage = activePage,
      newPerPage = perPage,
      newKeyword = keyword,
    },
  ) => {
    history.push(`/business-book-disabled/list?activePage=${newActivePage}&perPage=${newPerPage}&keyword=${newKeyword}`)
  }
  if (!isLoaded) return <LoaderComponent/>



  return (
    <>
      <Container as={Segment} vertical>
        <Message>
          <Message.Content>
            <List bulleted>
              <List.Item>
                {meta.total}개의 카테고리가 있습니다.
              </List.Item>
              <List.Item>
                {meta.totalDisabled}개의 카테고리가 신청불가로 처리되어 있습니다.
              </List.Item>
            </List>
          </Message.Content>
        </Message>
      </Container>
      <Container as={Segment} vertical>
        <Pagination
          activePage={activePage}
          totalPages={meta?.totalPage}
          onPageChange={(e, {activePage}) => {
            _handleChangeQuery({newActivePage: activePage});
          }}
        />
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>NO.</Table.HeaderCell>
              <Table.HeaderCell>bookId</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>사유</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              businessBooksDisabled?.map((bbd, i) => {
                return (
                  <Table.Row key={bbd.id}>
                    <Table.Cell>{(activePage - 1) * perPage + i + 1}</Table.Cell>
                    <Table.Cell>
                      {bbd.bookId}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/business-book-disabled/${bbd.id}`}>
                        {bbd.book?.title}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {bbd.comment}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessBookDisabledListContainer);
