import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from "react-router";
import {useQuery} from "../../library/useQuery";
import Network from "../../library/Network";
import {Table} from "semantic-ui-react";
import UserComponent from "../../components/user/UserComponent";

const BusinessBookClubDetailUserTab = (props) => {

  const {businessBookClubId} = useParams();
  let {activePage, perPage, keyword} = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 10;

  const [isLoaded, setIsLoaded] = useState(false)
  const [meta, setMeta] = useState({total: 0, toalPage: 1});
  const [users, setUsers] = useState([])

  const _load = async () => {
    const res = await Network
      .businessBookClubUsersGET({
        businessBookClubId,
        offset: ( activePage - 1 ) * perPage,
        limit: perPage,
      })
    if (!res.err) {
      setMeta(res.meta);
      setIsLoaded(true);
      setUsers(res.users)
    }
  }

  useEffect(() => {
    _load();
  }, [])

  return (
    <div>
      BusinessBookClubDetail {businessBookClubId}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            users.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <UserComponent user={item}/>
                  </Table.Cell>
                  <Table.Cell>

                  </Table.Cell>
                </Table.Row>
              )
            })
          }

        </Table.Body>
      </Table>
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessBookClubDetailUserTab);
