import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Container, Segment, Table} from "semantic-ui-react";
import moment from "moment";
import Network from '../../library/Network';

const BusinessBookForumMainContainer = (props) => {


  const [isLoaded, setIsLoaded] = useState(false);
  const [businessBookForums, setBusinessBookForums] = useState([]);
  const [perPage, setPerPage] = useState(50);

  const _load = async () => {
    const res = await Network.businessBookForumListGET({offset: 0, limit: perPage});
    if (!res.err) {
      setIsLoaded(true);
      setBusinessBookForums(res.businessBookForums);
    }
  };

  useEffect(() => {
    _load();
  }, []);

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical>

      </Container>
      <Container as={Segment} vertical>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={"No."}/>
              <Table.HeaderCell content={"id"}/>
              <Table.HeaderCell content={"모임명"}/>
              <Table.HeaderCell content={"일시"}/>
              <Table.HeaderCell content={"장소"}/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {businessBookForums.map((o, i) => {
              return (
                <Table.Row key={o.id}>
                  <Table.Cell content={i + 1}/>
                  <Table.Cell content={o.id}/>
                  <Table.Cell content={o.title}/>
                  <Table.Cell content={moment(o.datetime).format("YYYY.MM.DD(ddd) HH:mm")}/>
                  <Table.Cell content={o.locationDescription}/>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessBookForumMainContainer);
