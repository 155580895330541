import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import LoaderComponent from "../shared/LoaderComponent";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";

const UserComponent = (props) => {


  const [user, setUser] = useState(props.user || {});

  useEffect(() => {
    setUser(props.user);
  }, [props.user, props.user?.id]);


  switch (props.type) {
    case "HORIZONTAL":
      return (
        <div style={{ display: "flex" }}>
          <Link to={`/user/${user?.id}`}>
            <Image src={user?.photoUrl} avatar style={{ marginRight: "0.5rem" }}/>
            {user?.name}
          </Link>
        </div>
      );
    default:
      return (
        <div>
          <Link to={`/user/${user?.id}`}>
            {user?.name}
          </Link>
        </div>
      );
  }
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(UserComponent);
