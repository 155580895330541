import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Network from "../../library/Network";
import {useQuery} from "../../library/useQuery";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Button, Container, Form, Header, Message, Pagination, Segment, Table} from "semantic-ui-react";
import {useHistory} from "react-router";
import moment from "moment";
import {Link} from "react-router-dom";

const BusinessBookClubMainContainer = (props) => {


  let {activePage, perPage, keyword} = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;

  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [meta, setMeta] = useState({total: 0, totalPage: 1})
  const [businessBookClubs, setBusinessBookClubs] = useState([])

  const _load = async () => {
    const res = await Network
      .businessBookClubListGET({
        offset: ( activePage - 1 ) * perPage,
        limit: perPage,
      })

    if (!res.err) {
      setIsLoaded(true);
      setMeta(res.meta);
      setBusinessBookClubs(res.businessBookClubs);
    }
  }
  const _onRefresh = async () => {
    setRefreshing(true);
    await _load()
    setRefreshing(false);
  }

  useEffect(() => {
    _load();
  }, [activePage, perPage, keyword])


  const _handleChangeQuery = (
    {
      newActivePage = activePage,
      newPerPage = perPage,
      newKeyword = keyword,
    },
  ) => {
    history.push(`/business-book-club?activePage=${newActivePage}&perPage=${newPerPage}`)
  }


  if (!isLoaded) return <LoaderComponent/>;
  return (
    <div>
      <Container as={Segment} vertical>
        <Header content={'북클럽'}/>
        <Message>
          <Message.Content>
            {
              !!keyword ?
                `${keyword}로 ${meta?.total}개의 결과가 있습니다.`
                :
                `총 ${meta.total}개가 있습니다. `
            }

          </Message.Content>
        </Message>
        <Button
          as={Link}
          to={`/business-book-club/make`}
          positive
          content={"만들기"}
        />
        <Button
          loading={refreshing}
          disabled={refreshing}
          onClick={_onRefresh}
          icon={'refresh'}
          content={'새로고침'}
        />
      </Container>
      <Container as={Segment} vertical>
        <Pagination
          activePage={activePage}
          totalPages={meta?.totalPage}
          onPageChange={(e, {activePage}) => {
            _handleChangeQuery({newActivePage: activePage});
          }}
        />
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>공개</Table.HeaderCell>
              <Table.HeaderCell>제목</Table.HeaderCell>
              <Table.HeaderCell>신청수</Table.HeaderCell>
              <Table.HeaderCell>미션수</Table.HeaderCell>
              <Table.HeaderCell>미션포인트</Table.HeaderCell>
              <Table.HeaderCell>시작일</Table.HeaderCell>
              <Table.HeaderCell>첫미션 마감일</Table.HeaderCell>
              <Table.HeaderCell>마지막 미션 마감일</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {businessBookClubs.map((o, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{( activePage - 1 ) * perPage + i + 1}</Table.Cell>
                  <Table.Cell>{o.id}</Table.Cell>
                  <Table.Cell>{o.isPublic ? '공개' : '비공개'}</Table.Cell>
                  <Table.Cell>{o.title}</Table.Cell>
                  <Table.Cell>{o.statOfRegister?.count}</Table.Cell>
                  <Table.Cell>{o.statOfMission?.count}</Table.Cell>
                  <Table.Cell>{o.statOfMission?.businessPointExpected}</Table.Cell>
                  <Table.Cell>{moment(o.startDate).format('YY.MM.DD(ddd)HH:mm')}</Table.Cell>
                  <Table.Cell>{moment(o.statOfMission?.minEndDate).format('YY.MM.DD(ddd)HH:mm')}</Table.Cell>
                  <Table.Cell>{moment(o.statOfMission?.maxEndDate).format('YY.MM.DD(ddd)HH:mm')}</Table.Cell>
                  <Table.Cell>
                    <Button
                      as={Link}
                      to={`/business-book-club/${o.id}`}
                    >
                      자세히
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            })}

          </Table.Body>
        </Table>
      </Container>
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessBookClubMainContainer);
