import moment from "moment";
import {v4 as uuidv4} from "uuid";
import {storage} from "../constants/firebase";
import extractExtension from "./extractExtension";


export const uploadImageToFirebase = async (
  {
    file,
    storageRef
  }
) => {
  try {
    const Ref = storageRef || `temp/${moment().format("YYYY_MM")}/`;
    const filename = moment().format("DD_HH") + uuidv4() + extractExtension(file.name);
    const metadata = {};
    await storage.ref(Ref).child(filename).put(file, metadata);
    return await storage.ref(Ref).child(filename).getDownloadURL();
  } catch (e) {
    console.log(e.message);
    return false;
  }
}
