import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQuery } from "../../library/useQuery";
import { useHistory } from "react-router";
import moment from "moment";
import LoaderComponent from "../../components/shared/LoaderComponent";
import { Button, Container, Form, Header, Message, Pagination, Segment, Table } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import ReactExport from "react-export-excel";
import Network from '../../library/Network';

const BusinessLearningCreditListContainer = (props) => {

  const history = useHistory();
  let { type, activePage, perPage, startDate, endDate } = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 50;
  startDate = !!startDate ? startDate : moment().startOf("month").toISOString();
  endDate = !!endDate ? endDate : moment().endOf("month").toISOString();


  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState(false);
  const [totalPage, setTotalPage] = useState(false);
  const [businessLearningCredits, setBusinessLearningCredits] = useState([]);
  const [excelDataset, setExcelDataset] = useState([]);

  const _load = async () => {
    const res = await Network.businessLearningCreditListGET(
      {
        type,
        offset: ( activePage - 1 ) * perPage,
        limit: perPage,
        startDate,
        endDate,
      },
    );
    if (!res.err) {
      setIsLoaded(true);
      setBusinessLearningCredits(res.businessLearningCredits || []);
      setTotalPage(res.meta?.totalPage);
      setTotal(res.meta?.total);

      const excelData = [];
      for (let i = 0; i < res.businessLearningCredits.length; i++) {
        const d = res.businessLearningCredits[i];
        const data = {
          ...d,
          name: d.user?.name || "",
          businessDepartmentTitle: d.user?.businessDepartment?.title || "",
          companyIdNumber: d.user?.businessMember?.companyIdNumber || "",
          type: d.businessLearningCreditType?.text || "",
          createdAt: moment(d.createdAt).format("YYYY. M. D(ddd) HH:mm"),
        };
        excelData.push(data);
      }
      setExcelDataset(excelData);
    }
  };

  const _handleChangeDateAndUnit = (startDate, endDate, activePage, perPage) => {
    history.push(`/business-learning-credit/list?startDate=${moment(startDate).toISOString()}&endDate=${moment(endDate).toISOString()}&activePage=${activePage}&perPage=${perPage}&type=${type}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setBusinessLearningCredits([]);
    _load();
  }, [activePage, startDate, endDate, perPage]);

  if (!isLoaded) return <LoaderComponent text={"이수 내역을 불러오고 있습니다."}/>;

  const _renderDownload = () => {
    return (
      <ReactExport.ExcelFile
        element={<Button disabled={excelDataset.length === 0} icon={"download"} content={"엑셀 파일 다운로드"}/>}
        filename={`교육이수시간_${type}_${moment(startDate).format("YYYYMMDD")}_${moment(endDate).format("YYYYMMDD")}_${excelDataset.length}건_createdAt${moment().format("YYMMDD_HHmmss")}`}>
        <ReactExport.ExcelFile.ExcelSheet data={excelDataset} name={"data"}>
          <ReactExport.ExcelFile.ExcelColumn label={"부여Id"} value={"id"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"사번"} value={"companyIdNumber"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"이름"} value={"name"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"조직명"} value={"businessDepartmentTitle"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"교육이수시간"} value={"amount"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"종류"} value={"type"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"부여일"} value={"createdAt"}/>
        </ReactExport.ExcelFile.ExcelSheet>
      </ReactExport.ExcelFile>
    );
  };

  return (
    <>
      <Container as={Segment} vertical>
        <Header content={"교육 이수 시간"}/>
        <Form>
          <Form.Group style={{ alignItems: "flex-end" }}>
            <Form.Dropdown
              label={"표시개수"}
              selection
              value={perPage}
              onChange={(e, { value }) => {
                console.log({ value });
                _handleChangeDateAndUnit(startDate, endDate, 1, value);
              }}
              options={[
                { key: 0, text: "20", value: 20 },
                { key: 1, text: "50", value: 50 },
                { key: 2, text: "100", value: 100 },
                { key: 3, text: "500", value: 500 },
                { key: 4, text: "5000", value: 5000 },
              ]}
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(value, endDate, 1, perPage);
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(startDate, value, 1, perPage);
                                }}/>
            <Button.Group>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).subtract(1, "month").startOf("month").toISOString(),
                    moment(startDate).subtract(1, "month").endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                icon={"chevron left"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment().startOf("month").toISOString(),
                    moment().endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                content={"이번달"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).add(1, "month").startOf("month").toISOString(),
                    moment(startDate).add(1, "month").endOf("month").toISOString(),
                    1,
                    perPage,
                  );
                }}
                icon={"chevron right"}/>
            </Button.Group>
          </Form.Group>
          {_renderDownload()}
        </Form>
      </Container>
      <Container as={Segment} vertical>
        <Pagination totalPages={totalPage}
                    defaultActivePage={1}
                    activePage={activePage}
                    onPageChange={(e, { activePage }) => {
                      _handleChangeDateAndUnit(startDate, endDate, activePage, perPage);
                    }}/>
        <Message>
          총 {total}건의 내역이 있습니다.
        </Message>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={"id"}/>
              <Table.HeaderCell content={"사번"}/>
              <Table.HeaderCell content={"이름"}/>
              <Table.HeaderCell content={"부서"}/>
              <Table.HeaderCell content={"교육이수시간"}/>
              <Table.HeaderCell content={"종류"}/>
              <Table.HeaderCell content={"부여일"}/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {businessLearningCredits.map((o, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                    {o.id}
                  </Table.Cell>
                  <Table.Cell>
                    {o.user?.businessMember?.companyIdNumber}
                  </Table.Cell>
                  <Table.Cell>
                    {o.user?.name}
                  </Table.Cell>
                  <Table.Cell>
                    {o.user?.businessDepartment?.title}
                  </Table.Cell>
                  <Table.Cell>
                    {o.amount}
                  </Table.Cell>
                  <Table.Cell>
                    {o.businessLearningCreditType?.text}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(o.createdAt).format("YYYY. M. D(ddd) HH:mm")}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessLearningCreditListContainer);
