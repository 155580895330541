import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router";
import Network from '../../library/Network';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {Breadcrumb, Card, Container, Grid, Header, Image, List, Segment, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import removeSubTitle from '../../library/removeSubTitle';
import moment from 'moment';
import numeral from 'numeral';
import OrderComponent from '../../components/order/OrderComponent';

const BusinessBookRequestDetailContainer = (props) => {

  const {businessBookRequestId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [businessBookRequest, setBusinessBookRequest] = useState({});
  const [businessBookRequestsPrev, setBusinessBookRequestsPrev] = useState([]);
  const [businessBookRequestsLater, setBusinessBookRequestsLater] = useState([]);


  const _load = async () => {
    const res = await Network
      .businessBookRequestGET({businessBookRequestId})
    if (!res.err) {
      setIsLoaded(true);
      setBusinessBookRequest(res.businessBookRequest)
      setBusinessBookRequestsPrev(res.businessBookRequestsPrev)
      setBusinessBookRequestsLater(res.businessBookRequestsLater)
    }
  }

  useEffect(() => {
    setIsLoaded(false);
    _load();
  }, [businessBookRequestId]);

  if (!isLoaded) return <LoaderComponent/>;


  const _renderLaterPrevRequestList = (bbrList) => {
    return bbrList.map((o, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell width={2}>
            <Link to={`/business-book-request/${o.id}`}>
              {o.id}
            </Link>
          </Table.Cell>
          <Table.Cell width={2}>
            {o.user?.name}
          </Table.Cell>
          <Table.Cell>
            {removeSubTitle({book: o.book})}
          </Table.Cell>
          <Table.Cell width={5}>
            {moment(o.createdAt).format('YYYY.MM.DD(ddd)HH:mm')}
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  return (
    <>
      <Container as={Segment} vertical>
        <Breadcrumb>
          <Breadcrumb.Section
            as={Link}
            to={`/`}
          >
            홈
          </Breadcrumb.Section>
          <Breadcrumb.Divider/>
          <Breadcrumb.Section
            as={Link}
            to={`/business-book-request`}
          >
            도서 신청
          </Breadcrumb.Section>
          <Breadcrumb.Divider/>
          <Breadcrumb.Section
            as={Link}
            to={`/business-book-request/list`}
          >
            리스트
          </Breadcrumb.Section>
          <Breadcrumb.Divider/>
          <Breadcrumb.Section active>
            {businessBookRequestId}
          </Breadcrumb.Section>
        </Breadcrumb>
      </Container>
      <Container as={Segment} vertical>
        <Grid>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Card
                fluid
                as={Link}
                to={`/book/${businessBookRequest.bookId}`}
              >
                <Card.Content textAlign={'center'}>
                  <Image src={businessBookRequest?.book?.coverImageUrl} size={'tiny'}/>
                </Card.Content>
                <Card.Content>
                  <Card.Header>
                    책
                  </Card.Header>
                  <p>
                    {removeSubTitle({book: businessBookRequest.book})}
                  </p>
                  <Card.Meta>
                    <List bulleted>
                      <List.Item>
                        bookId : {businessBookRequest.bookId}
                      </List.Item>
                      <List.Item>
                        isbn : {businessBookRequest?.book?.isbn}
                      </List.Item>
                      <List.Item>
                        isbn13 : {businessBookRequest.book?.isbn13}
                      </List.Item>
                      <List.Item>
                        priceStandard : {numeral(businessBookRequest.book?.priceStandard).format('0,0')}
                      </List.Item>
                      <List.Item>
                        priceSales : {numeral(businessBookRequest.book?.priceSales).format('0,0')}
                      </List.Item>
                    </List>

                  </Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card
                fluid
                as={Link}
                to={`/book/${businessBookRequest.bookId}`}
              >
                <Card.Content textAlign={'center'}>
                  <Image src={businessBookRequest?.book?.bookBooxen?.coverImageUrl} size={'tiny'}/>
                </Card.Content>
                <Card.Content>
                  <Card.Header>
                    booxen
                  </Card.Header>
                  <p>
                    {businessBookRequest.book?.bookBooxen?.title}
                  </p>
                  <Card.Meta>
                    <List>
                      <List.Item>
                        거래상태 {businessBookRequest.book?.bookBooxen?.status}
                      </List.Item>
                      <List.Item>
                        정가 {numeral(businessBookRequest.book?.bookBooxen?.price).format('0,0')}
                      </List.Item>
                      <List.Item>
                        판매가 {numeral(businessBookRequest.book?.bookBooxen?.priceSale).format('0,0')}
                      </List.Item>
                      <List.Item>
                        공급률 {businessBookRequest.book?.bookBooxen?.rateCost}%
                      </List.Item>
                      <List.Item>
                        재고 {businessBookRequest.book?.bookBooxen?.quantity}
                      </List.Item>
                    </List>
                  </Card.Meta>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </Container>
      <Container as={Segment} vertical>
        <Header>
          신청인 정보
        </Header>
        <Table>
          <Table.Body>
            <Table.Row textAlign={'center'}>
              <Table.Cell>
                userId
              </Table.Cell>
              <Table.Cell>
                사번
              </Table.Cell>
              <Table.Cell>
                이름
              </Table.Cell>
              <Table.Cell>
                부서
              </Table.Cell>

            </Table.Row>
            <Table.Row textAlign={'center'}>
              <Table.Cell>
                <Link to={`/user/${businessBookRequest.userId}`}>
                  {businessBookRequest.userId}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {businessBookRequest.user?.businessMember?.companyIdNumber}
              </Table.Cell>
              <Table.Cell>
                <Link to={`/user/${businessBookRequest.userId}`}>
                  {businessBookRequest.user?.name}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Link to={`/business-deparment/${businessBookRequest.user?.businessDepartment?.id}`}>
                  {businessBookRequest.user?.businessDepartment?.title}
                </Link>
              </Table.Cell>

            </Table.Row>
          </Table.Body>
        </Table>
      </Container>
      <Container as={Segment} vertical>
        <Header>
          Order
        </Header>
        <OrderComponent order={businessBookRequest?.order}/>
      </Container>
      <Container as={Segment} vertical>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Header>
                이전 신청 내역
              </Header>
              <Table>
                <Table.Header>
                  <Table.Row></Table.Row>
                </Table.Header>
                <Table.Body>
                  {_renderLaterPrevRequestList(businessBookRequestsPrev)}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
              <Header>
                이후 신청 내역
              </Header>
              <Table>
                <Table.Header></Table.Header>
                <Table.Body>
                  {_renderLaterPrevRequestList(businessBookRequestsLater)}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessBookRequestDetailContainer);
