import MyPageContainer from "../container/my-page/MyPageContainer";
import BusinessDepartmentMainContainer from "../container/business-department/BusinessDepartmentMainContainer";
import BusinessBookForumMainContainer from "../container/business-book-forum/BusinessBookForumMainContainer";
import BusinessBookRequestMainContainer from "../container/business-book-request/BusinessBookRequestMainContainer";
import InquiryMainContainer from "../container/inquiry/InquiryMainContainer";
import BusinessBookRecommendationCollectionMainContainer from "../container/business-book-recommendation-collection/BusinessBookRecommendationCollectionMainContainer";
import BusinessBookVoucherMainContainer from "../container/business-book-voucher/BusinessBookVoucherMainContainer";
import BusinessLearningCreditMainContainer from "../container/business-learning-credit/BusinessLearningCreditMainContainer";
import BusinessNoticeMainContainer from "../container/business-notice/BusinessNoticeMainContainer";
import BusinessPointMainContainer from "../container/business-point/BusinessPointMainContainer";
import HomeContainer from "../container/home/HomeContainer";
import BusinessBookRequestListContainer from "../container/business-book-request/BusinessBookRequestListContainer";
import BusinessBookRequestDetailContainer from "../container/business-book-request/BusinessBookRequestDetailContainer";
import BookMainContainer from "../container/book/BookMainContainer";
import BookDetailContainer from "../container/book/BookDetailContainer";
import DashboardMainContainer from "../container/dashboard/DashboardMainContainer";
import BusinessBookRecommendationCollectionDetailContainer from "../container/business-book-recommendation-collection/BusinessBookRecommendationCollectionDetailContainer";
import BusinessBookRequestCalculateContainer from "../container/business-book-request/BusinessBookRequestCalculateContainer";
import BusinessLearningCreditListContainer from "../container/business-learning-credit/BusinessLearningCreditListContainer";
import BusinessContentGroupMainContainer from "../container/business-content-group/BusinessContentGroupMainContainer";
import InquiryDetailContainer from "../container/inquiry/InquiryDetailContainer";
import InquiryListContainer from "../container/inquiry/InquiryListContainer";
import BusinessNoticeListContainer from "../container/business-notice/BusinessNoticeListContainer";
import BusinessNoticeDetailContainer from "../container/business-notice/BusinessNoticeDetailContainer";
import SearchMainContainer from "../container/search/SearchMainContainer";
import EventMainContainer from "../container/event/EventMainContainer";
import EventTempMonthlyHashTagMainContainer from "../container/event/EventTempMonthlyHashTagMainContainer";
import EventTempMonthlyHashTagDetailContainer from "../container/event/EventTempMonthlyHashTagDetailContainer";
import userContainers from "../container/user/containers";
import businessBookCategoryContainers from "../container/business-book-category/containers";
import businessBookDisabledContainers from '../container/business-book-disabled/containers';
import businessContentContainers from '../container/business-content/containers';
import businessContentCommentContainers from '../container/business-content-comment/containers';
import businessMemberContainers from '../container/business-member/containers';
import bookCategoryContainers from "../container/book-category/containers";
import businessBannerContainers from '../container/business-banner/containers';
import businessBookClubContainers from "../container/business-book-club/containers";


export const privateRouterList = [
  {path: "/book", component: BookMainContainer},
  {path: "/book/:bookId", component: BookDetailContainer},

  ...bookCategoryContainers,
  ...businessBannerContainers,
  ...businessBookCategoryContainers,
  ...businessBookClubContainers,
  ...businessBookDisabledContainers,
  ...businessContentContainers,
  ...businessContentCommentContainers,
  ...businessMemberContainers,

  {path: "/business-book-forum", component: BusinessBookForumMainContainer},

  {path: "/business-book-recommendation-collection", component: BusinessBookRecommendationCollectionMainContainer},
  {path: "/business-book-recommendation-collection/make", component: BusinessBookRecommendationCollectionDetailContainer},
  {path: "/business-book-recommendation-collection/:businessBookRecommendationCollectionId", component: BusinessBookRecommendationCollectionDetailContainer},

  {path: "/business-book-request", component: BusinessBookRequestMainContainer},
  {path: "/business-book-request/list", component: BusinessBookRequestListContainer},
  {path: "/business-book-request/for-calculate", component: BusinessBookRequestCalculateContainer},
  {path: "/business-book-request/:businessBookRequestId", component: BusinessBookRequestDetailContainer},
  {path: "/business-book-voucher", component: BusinessBookVoucherMainContainer},

  {path: "/business-content-group", component: BusinessContentGroupMainContainer},

  {path: "/business-department", component: BusinessDepartmentMainContainer},

  {path: "/business-learning-credit", component: BusinessLearningCreditMainContainer},
  {path: "/business-learning-credit/list", component: BusinessLearningCreditListContainer},


  {path: "/business-notice", component: BusinessNoticeMainContainer},
  {path: "/business-notice/list", component: BusinessNoticeListContainer},
  {path: "/business-notice/:businessNoticeId", component: BusinessNoticeDetailContainer},

  {path: "/business-point", component: BusinessPointMainContainer},
  {path: "/dashboard", component: DashboardMainContainer},

  {path: "/event", component: EventMainContainer},
  {path: "/event/temp/monthly-hash-tag", component: EventTempMonthlyHashTagMainContainer},
  {path: "/event/temp/monthly-hash-tag/:hashTagId", component: EventTempMonthlyHashTagDetailContainer},

  {path: "/home", component: HomeContainer},

  {path: "/inquiry", component: InquiryMainContainer},
  {path: "/inquiry/list", component: InquiryListContainer},
  {path: "/inquiry/:inquiryId", component: InquiryDetailContainer},

  {path: "/my-page", component: MyPageContainer},

  {path: "/search", component: SearchMainContainer},

  ...userContainers,

];
