import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {useHistory, useParams} from 'react-router';
import Network from '../../library/Network';
import DraftEditorComponent from '../../components/draft/DraftEditorComponent';
import {Form} from 'semantic-ui-react';
import moment from 'moment';

const BusinessContentDetailContainer = (props) => {

  const {businessContentId} = useParams()
  const isNew = !businessContentId;
  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [businessContent, setBusinessContent] = useState({})
  const [businessContentTypes, setBusinessContentTypes] = useState([]);

  const _load = async () => {
    if (isNew) {
      const res = await Network
        .businessContentTypeListGET({});

      if (!res.err) {
        setBusinessContentTypes(res.businessContentTypes);
        setIsLoaded(true);
      }

    } else {
      const res = await Network
        .businessContentGET({businessContentId})
      if (!res.err) {
        setBusinessContent(res.businessContent);
        setBusinessContentTypes(res.businessContentTypes);
        setIsLoaded(true);
      }
    }
  }

  const _handleSave = async ({content}) => {
    setUploading(true);
    const d = {
      ...content,
      businessContentTypeId: businessContent.businessContentTypeId,
      publishedAt: businessContent.publishedAt,
      videoUrl: businessContent.videoUrl,
    }

    if (isNew) {
      const res = await Network
        .businessContentPOST({data: d})

      if (!res.err) {
        setIsLoaded(false);
        history.replace(`/business-content/${res.businessContent?.id}`);
      }
    } else {
      const res = await Network.businessContentPUT(
        {
          businessContentId,
          data: d,
        },
      );

      if (!res.err) {
        // setIsLoaded(false);
        await _load();
      }
    }
    setUploading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])

  if (!isLoaded) return <LoaderComponent/>

  return (
    <>
      <DraftEditorComponent
        content={businessContent}
        handleSave={_handleSave}
        uploading={uploading}
      >
        <Form>
          <Form.Dropdown
            label={'storyCategory'}
            selection
            value={businessContent.businessContentTypeId}
            disabled={!isNew}
            // clearable
            options={businessContentTypes?.map(o => ({key: o.id, value: o.id, text: o.text}))}
            onChange={(e, {value}) => {
              setBusinessContent(prev => ({...prev, businessContentTypeId: value}));
            }}
          />
          <Form.Input
            label={'publishedAt'}
            type={'date'}
            value={moment(businessContent.publishedAt).format('YYYY-MM-DD')}
            onChange={(e, {value}) => {
              setBusinessContent(prev => ({...prev, publishedAt: moment(value).toISOString()}));
            }}
          />
          <Form.Input
            label={'video Url'}
            value={businessContent.videoUrl}
            onChange={(e, {value}) => {
              setBusinessContent(prev => ({...prev, videoUrl: value}));
            }}
          />
        </Form>
      </DraftEditorComponent>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessContentDetailContainer);
