import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Button, Container, Divider, Form, Grid, Header, Image, Modal, Segment, Table} from "semantic-ui-react";
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import Truncate from "react-truncate";
import _ from "lodash";
import {moveItemInArrayFromIndexToIndex} from "../../library/arrayHandler";
import Network from '../../library/Network';

const BusinessBookRecommendationCollectionDetailContainer = (props) => {

  const history = useHistory();
  const {businessBookRecommendationCollectionId} = useParams();
  const isNew = !businessBookRecommendationCollectionId;
  const [isLoaded, setIsLoaded] = useState(false);
  const [businessBookRecommendationCollection, setBusinessBookRecommendationCollection] = useState({});

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchedList, setSearchedList] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [uploading, setUploading] = useState(false);


  const _load = async () => {
    if (isNew) {
      setIsLoaded(true);
      setBusinessBookRecommendationCollection({
        publishedAt: moment().toISOString(),
        expiredAt: moment("9999-12-31").toISOString(),
        businessBookRecommendations: [],
      });
    } else {
      const res = await Network.businessBookRecommendationCollectionGET(
        {businessBookRecommendationCollectionId},
      );
      if (!res.err) {
        setIsLoaded(true);
        setBusinessBookRecommendationCollection(res.businessBookRecommendationCollection);
      }
    }
  };
  const _upload = async () => {

    if (!businessBookRecommendationCollection.title ||
      businessBookRecommendationCollection.title.trim() === "" ||
      !businessBookRecommendationCollection.description ||
      businessBookRecommendationCollection.description.trim() === ""
    ) {
      return window.alert("제목과 설명을 추가해 주세요.");
    }
    if (!businessBookRecommendationCollection.businessBookRecommendations || businessBookRecommendationCollection.businessBookRecommendations.length < 1) {
      return window.alert("도서 리스트를 추가해 주세요.");
    }

    setUploading(true);

    if (isNew) {
      //
      const res = await Network.businessBookRecommendationCollectionPOST(
        {
          data: businessBookRecommendationCollection,
        },
      );
      if (!res.err) {
        setIsLoaded(false);
        history.push(`/business-book-recommendation-collection/${res.businessBookRecommendationCollection?.id}`);
      }
    } else {
      const res = await Network.businessBookRecommendationCollectionPUT(
        {
          businessBookRecommendationCollectionId,
          data: businessBookRecommendationCollection,
        },
      );
      if (!res) {
        setIsLoaded(false);
        _load();
      }
    }
    setUploading(false);
  };
  const _delete = async () => {
    if (isNew) return;
    const a = window.confirm("삭제하시겠습니까?");
    if (!a) return;

    setUploading(true);
    const res = await Network.businessBookRecommendationCollectionDELETE(
      {
        businessBookRecommendationCollectionId,
      },
    );
    if (!res.err) {
      history.replace(`/business-book-recommendation-collection`);
    }
    setUploading(false);
  };

  const _searchBook = async () => {
    if (!keyword || keyword === "") return window.alert("검색어를 입력해 주세요");
    setSearchedList([]);
    setSearching(true);
    const res = await Network.searchGET({
      itemType: "BOOK",
      keyword,
      offset: 0,
      limit: 100,
      method: "es",
    });
    setSearching(false);
    if (!res.err) {
      setSearchedList(res.searchedList);
    }
  };
  const _handleAddBook = (book) => {
    setBusinessBookRecommendationCollection(
      prev => ({
        ...prev,
        businessBookRecommendations: [
          ...prev.businessBookRecommendations,
          {
            id: null,
            order: prev.businessBookRecommendations.length + 1,
            book: book,
            bookId: book.id,
          },
        ],
      }),
    );
  };

  const _handleCalculateOrder = (businessBookRecommendations) => {
    for (let i = 0; i < businessBookRecommendations.length; i++) {
      businessBookRecommendations[i].order = i + 1;
    }
  };

  useEffect(() => {
    setIsLoaded(false);
    _load();
  }, [businessBookRecommendationCollectionId]);


  if (!isLoaded) return <LoaderComponent/>;


  const _handleOpenAddModal = () => {
    setAddModalOpen(true);
  };
  const _handleCloseAddModal = () => {
    setAddModalOpen(false);
  };
  const _renderAddModal = () => {
    return (
      <Modal open={addModalOpen}>
        <Modal.Header>
          검색
          <Divider/>
          <Form>
            <Form.Input
              value={keyword}
              onChange={(e, {value}) => setKeyword(value)}
              action>
              <input/>
              <Button
                onClick={e => {
                  e.preventDefault();
                  _searchBook();
                }}
                loading={searching}
                content={"검색"} icon={"search"}/>
            </Form.Input>
          </Form>
        </Modal.Header>
        <Modal.Content scrolling style={{height: "500px"}}>
          <Table singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell content={"bookId"}/>
                <Table.HeaderCell content={"image"}/>
                <Table.HeaderCell content={"title"}/>
                <Table.HeaderCell content={"-"}/>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                searchedList?.map((b, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell width={1}>
                        {b.id}
                      </Table.Cell>
                      <Table.Cell width={1}>
                        <Image src={b?.coverImageUrl} size={"mini"}/>
                      </Table.Cell>
                      <Table.Cell style={{maxWidth: "100px"}}>
                        <Truncate lines={1}>
                          {b.title}
                        </Truncate>
                      </Table.Cell>
                      <Table.Cell width={1}>
                        <Button
                          content={"추가"}
                          icon={"plus"}
                          onClick={() => _handleAddBook(b)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              }
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={_handleCloseAddModal}
            content={"닫기"}/>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <>
      {_renderAddModal()}
      <Container as={Segment} vertical>
        <Header content={"기본정보"}/>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form>
                <Form.Input
                  label={"title"}
                  value={businessBookRecommendationCollection.title}
                  onChange={(e, {value}) => {
                    setBusinessBookRecommendationCollection(prev => ({...prev, title: value}));
                  }}
                />
                <Form.TextArea
                  label={"설명"}
                  value={businessBookRecommendationCollection.description}
                  onChange={(e, {value}) => {
                    setBusinessBookRecommendationCollection(prev => ({...prev, description: value}));
                  }}
                />
                <Form.Group>
                  <SemanticDatepicker locale={"ko-KR"}
                                      label={"공개일"}
                                      clearable={false}
                                      value={moment(businessBookRecommendationCollection.publishedAt).toDate()}
                                      onChange={(e, {value}) => {
                                        if (!value) return;
                                        setBusinessBookRecommendationCollection(prev => ({
                                          ...prev,
                                          publishedAt: moment(value).toISOString(),
                                        }));
                                      }}/>
                  <SemanticDatepicker locale={"ko-KR"}
                                      label={"만료일"}
                                      clearable={false}
                                      value={moment(businessBookRecommendationCollection.expiredAt).toDate()}
                                      onChange={(e, {value}) => {
                                        if (!value) return;
                                        setBusinessBookRecommendationCollection(prev => ({
                                          ...prev,
                                          expiredAt: moment(value).toISOString(),
                                        }));
                                      }}/>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Button
          content={"저장하기"}
          loading={uploading}
          onClick={_upload}
          primary
        />
        <Button
          content={"삭제하기"}
          loading={uploading}
          onClick={_delete}
          negative
        />
      </Container>
      <Container as={Segment} vertical>
        <Button
          onClick={_handleOpenAddModal}
          icon={"plus"}
          content={"도서 추가 하기"}/>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={"id"}/>
              <Table.HeaderCell content={"bookId"}/>
              <Table.HeaderCell content={"image"}/>
              <Table.HeaderCell content={"title"}/>
              <Table.HeaderCell content={"순서"}/>
              <Table.HeaderCell content={"-"}/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              businessBookRecommendationCollection.businessBookRecommendations?.map((o, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{o.id || "신규"}</Table.Cell>
                    <Table.Cell>{o.book?.id}</Table.Cell>
                    <Table.Cell>
                      <Image src={o.book?.coverImageUrl} size={"mini"}/>
                    </Table.Cell>
                    <Table.Cell>{o.book?.title}</Table.Cell>
                    <Table.Cell>
                      {o.order}
                    </Table.Cell>
                    <Table.Cell>
                      <Button.Group size={"small"}>
                        <Button
                          icon={"arrow up"}
                          disabled={i === 0}
                          onClick={() => {
                            const businessBookRecommendationsNew = moveItemInArrayFromIndexToIndex(
                              businessBookRecommendationCollection.businessBookRecommendations,
                              i,
                              i - 1,
                            );
                            _handleCalculateOrder(businessBookRecommendationsNew);
                            setBusinessBookRecommendationCollection(
                              prev => ({
                                ...prev,
                                businessBookRecommendations: businessBookRecommendationsNew,
                              }),
                            );
                          }}
                        />
                        <Button
                          icon={"arrow down"}
                          disabled={i === businessBookRecommendationCollection.businessBookRecommendations?.length - 1}
                          onClick={() => {
                            const businessBookRecommendationsNew = moveItemInArrayFromIndexToIndex(
                              businessBookRecommendationCollection.businessBookRecommendations,
                              i,
                              i + 1,
                            );
                            _handleCalculateOrder(businessBookRecommendationsNew);
                            setBusinessBookRecommendationCollection(
                              prev => ({
                                ...prev,
                                businessBookRecommendations: businessBookRecommendationsNew,
                              }),
                            );
                          }}
                        />
                        <Button
                          icon={"delete"}
                          onClick={() => {
                            const businessBookRecommendationsNew = _.cloneDeep(businessBookRecommendationCollection.businessBookRecommendations);
                            businessBookRecommendationsNew.splice(i, 1);
                            _handleCalculateOrder(businessBookRecommendationsNew);
                            setBusinessBookRecommendationCollection(
                              prev => ({
                                ...prev,
                                businessBookRecommendations: businessBookRecommendationsNew,
                              }),
                            );
                          }}
                        />
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessBookRecommendationCollectionDetailContainer);
