import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, Container, Divider, Grid, Header, Icon, Loader, Search, Segment, Statistic } from "semantic-ui-react";
import Colors from "../../constants/Colors";
import { Link } from "react-router-dom";
import LoaderComponent from "../../components/shared/LoaderComponent";
import numeral from "numeral";
import Network from '../../library/Network';

const BusinessMemberMainContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [stat, setStat] = useState({});

  const _load = async () => {
    const res = await Network.businessMemberMainGET();

    if (!res.err) {
      setStat(res.stat);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    _load();
  }, []);

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Segment vertical inverted style={{ backgroundColor: "lightgrey" }}>
        <Container style={{ paddingTop: "100px", paddingBottom: "50px" }}>
          <Header inverted size={"large"} style={{ fontWeight: "black" }}>
            구성원 관리
          </Header>
          <p>
            구성원들을 추가하고 검색할 수 있습니다.
          </p>
          <Button
            basic
            content={"구성원 추가"}
            as={Link}
            to={"/business-member/add"}
          />
          <Button
            basic
            content={"구성원 삭제"}
            as={Link}
            to={"/business-member/remove"}
          />
          <Button
            basic
            content={"조직 관리"}
            as={Link}
            to={"/business-department"}
          />
        </Container>
      </Segment>
      <Container as={Segment} vertical basic style={{ textAlign: "right" }}>
        <Search
          noResultsMessage={"검색 결과가 없습니다."}
          aligned={"right"}
          style={{ alignSelf: "flex-end" }}/>
      </Container>
      <Container as={Segment} vertical basic>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"구성원"}/>
                  <Divider hidden/>
                  <Statistic.Group widths='3' size={"small"}>
                    <Statistic>
                      <Statistic.Value>
                        {numeral(stat?.statOfBusinessMembers?.total).format("0,0")}
                      </Statistic.Value>
                      <Statistic.Label>
                        전체 유저
                      </Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value>
                        {numeral(stat?.statOfBusinessMembers?.isUsed).format("0,0")}
                      </Statistic.Value>
                      <Statistic.Label>
                        활성
                      </Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value>
                        {numeral(stat?.statOfBusinessMembers?.isClosed).format("0,0")}
                      </Statistic.Value>
                      <Statistic.Label>
                        비활성
                      </Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                  <Divider hidden/>
                  <Button
                    as={Link}
                    floated={"right"}
                    content={"더보기"}
                    basic
                    primary/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"조직"}/>
                  <Divider hidden/>
                  <Statistic.Group widths='1' size={"small"}>
                    <Statistic>
                      <Statistic.Value>
                        {stat.numberOfBusinessDepartment}
                      </Statistic.Value>
                      <Statistic.Label>
                        조직수
                      </Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                  <Divider hidden/>
                  <Button
                    as={Link}
                    floated={"right"}
                    content={"더보기"}
                    basic
                    primary/>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessMemberMainContainer);
