import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {useQuery} from "../../library/useQuery";
import moment from "moment";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Button, Container, Form, Header, Segment, Table} from "semantic-ui-react";
import {useHistory} from "react-router";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import Network from '../../library/Network';
import {Link} from 'react-router-dom';

const BusinessNoticeListContainer = (props) => {

  const history = useHistory();
  let {startDate, endDate, perPage, activePage} = useQuery();
  startDate = !!startDate ? moment(startDate).toISOString() : moment().startOf("month").toISOString();
  endDate = !!endDate ? moment(endDate).toISOString() : moment().endOf("month").toISOString();
  perPage = !!perPage ? Number(perPage) : 50;
  activePage = !!activePage ? Number(activePage) : 1;
  const [isLoaded, setIsLoaded] = useState(false)
  const [businessNotices, setBusinessNotices] = useState([])

  const _load = async () => {
    const res = await Network.businessNoticeListGET(
      {
        offset: (activePage - 1) * perPage,
        limit: perPage,
        startDate,
        endDate,
      },
    )
    if (!res.err) {
      setIsLoaded(true);
      setBusinessNotices(res.businessNotices || [])
    }
  }

  useEffect(() => {
    _load();
  }, [])

  const _handleChangeRoute = (
    {
      starDate = startDate,
      endDate = endDate,
      perPage = perPage,
      activePage = activePage,
    }) => {
    history.push(`/business-notice/list?startDate=${moment(starDate).toISOString()}&endDate=${moment(endDate).toISOString()}&perPage=${perPage}&activePage=${activePage}`)
  }

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical>
        <Header content={"공지 리스트"}/>
        <Form>
          <Form.Group style={{alignItems: "flex-end"}}>
            <Form.Dropdown
              label={"표시개수"}
              selection
              value={perPage}
              onChange={(e, {value}) => {
                _handleChangeRoute({startDate, endDate, activePage: 1, perPage: value});
              }}
              options={[
                {key: 0, text: "20", value: 20},
                {key: 1, text: "50", value: 50},
                {key: 2, text: "100", value: 100},
                {key: 3, text: "500", value: 500},
                {key: 4, text: "5000", value: 5000},
              ]}
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, {value}) => {
                                  if (!value) return;
                                  _handleChangeRoute(
                                    {
                                      startDate: value, endDate, activePage: 1, perPage,
                                    },
                                  );
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, {value}) => {
                                  if (!value) return;
                                  _handleChangeRoute(
                                    {
                                      startDate, endDate: value, activePage: 1, perPage,
                                    },
                                  );
                                }}/>

          </Form.Group>
        </Form>
        <Button
          positive
          content={"만들기"}
        />
      </Container>
      <Container as={Segment} vertical>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell/>
              <Table.HeaderCell/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {businessNotices?.map((o, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                    {o.id}
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={`/business-notice/${o.id}`}>
                      {o.title}
                    </Link>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessNoticeListContainer);
