import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Button, Container, Header, Image, Segment, Table} from "semantic-ui-react";
import Network from "../../library/Network";
import numeral from 'numeral';
import {withLineEnter} from '../../library/TextHandler';

const BookDetailContainer = (props) => {

  const {bookId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false);
  const [book, setBook] = useState({});

  const _load = async () => {
    const res = await Network.bookGET({bookId});
    if (!res.err) {
      setIsLoaded(true);
      setBook(res.book);
    }
  };

  const _handleToggleDisabled = async () => {
    if (!!book?.businessBookDisabled?.id) {
      const a = window.confirm('신청 불가 책에서 삭제하겠습니까?');
      if (!a) return;
      const res = await Network.businessBookDisabledDELETE(
        {
          businessBookDisabledId: book.businessBookDisabled?.id
        }
      )
      if (!res.err) {
        window.alert('삭제되었습니다.')
      }
      _load();

    } else {
      const a = window.confirm('신청 불가 책에 등록하시겠습니까?');
      if (!a) return;
      const res = await Network.businessBookDisabledPOST(
        {
          businessBookDisabled: {
            bookId,
          }
        }
      )
      if (!res.err) {
        window.alert('등록되었습니다.')
      }
      _load();
    }


  }

  const _handleToggleAddRemoveBusinessBookCategoryException = async () => {
    if (!!book?.businessBookCategoryException?.id) {
      const a = window.confirm('신청 불가 예외 책에서 삭제하겠습니까?');
      if (!a) return;
      const res = await Network
        .businessBookCategoryExceptionDELETE({bookId})
      await _load()
    } else {
      const a = window.confirm('신청 불가 예외 책에 등록하시겠습니까?');
      if (!a) return;
      const res = await Network
        .businessBookCategoryExceptionPOST({bookId})
      await _load()
    }
  }

  useEffect(() => {
    _load();
  }, []);

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        <Header>책데이터</Header>
        <Image src={book.coverImageUrl}/>
        <od>
          <li>
            저자: {book.author}
          </li>
          <li>
            출판사: {book.publisher}
          </li>
          <li>
            설명: {withLineEnter(book.description)}
          </li>
          <li>
            판매가격: {numeral(book.priceSales).format('0,0')}원
          </li>
          <li>
            categoryNameAladin: {book.categoryNameAladin}
          </li>
          <li>
            categoryIdAladin: {book.categoryIdAladin}
          </li>
        </od>

        <Header>
          카테고리
        </Header>
        <od>
          {
            book?.relationBookBookCategories?.map((rbbc, i) => {
              return (
                <li key={i}
                    style={{
                      color: !!rbbc.bookCategory.businessBookCategoryDisabled ? 'red' : ''
                    }}
                >
                  {rbbc.bookCategory?.aladinCategoryName} {!!rbbc.bookCategory.businessBookCategoryDisabled && '불가'}
                </li>
              )
            })
          }
        </od>
        <Button
          disabled={book.businessBookCategoriesDisabled?.length === 0}
          content={!!book.businessBookCategoryException?.id ? '신청불가 예외처리 삭제' : '신청불가 예외처리'}
          onClick={_handleToggleAddRemoveBusinessBookCategoryException}
        />
      </Container>

      <Container as={Segment} vertical basic>
        <Header>신청 불가 도서 관리</Header>
        <Button
          onClick={_handleToggleDisabled}
          content={!!book.businessBookDisabled?.id ? '신청 불가 해제' : '신청 불가 등록'}/>


      </Container>
      <Container as={Segment} vertical basic>
        <Header>북센데이터</Header>
        <od>
          <li>
            bookBooxenId :{book.bookBooxen?.id}
          </li>
          <li>
            bookId: {bookId}
          </li>
          <li>
            북센 거래 상태{book.bookBooxen?.status}
          </li>
          <li>
            북센 거래 상태{numeral(book.bookBooxen?.price).format('0,0')}
          </li>
          <li>
            북센 거래 상태{book.bookBooxen?.quantity}
          </li>
        </od>

      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BookDetailContainer);
