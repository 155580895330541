import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {useQuery} from '../../library/useQuery';
import Network from '../../library/Network';
import {Card, Container, Form, Image, Label, Pagination, Radio, Segment, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const BusinessBannerMainContainer = (props) => {

  let {perPage, activePage} = useQuery();
  perPage = !!perPage ? Number(perPage) : 20;
  activePage = !!activePage ? Number(activePage) : 1;

  const [isLoaded, setIsLoaded] = useState(false)
  const [businessBanners, setBusinessBanners] = useState([])
  const [meta, setMeta] = useState({})

  const _load = async () => {
    const res = await Network
      .businessBannerListGET({
        offset: (activePage - 1) * perPage,
        limit: perPage,
      })

    if (!res.err) {
      setBusinessBanners(res.businessBanners);
      setIsLoaded(true);
      setMeta(res.meta || {});
    }
  }


  const _handleRoute = (
    {
      newPerPage = perPage,
      newActivePage = activePage
    }
  ) => {
    let url = `/business-banner?`
    url = url + `perPage=${newPerPage}&`
    activePage = url + `activePerPage=${newActivePage}&`
    props.history.push(url);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])

  if (!isLoaded) return <LoaderComponent/>

  return (
    <>
      <Container vertical as={Segment}>
        <Form>
          <Form.Group>
            <Form.Button icon={'refresh'}/>

          </Form.Group>
        </Form>
      </Container>
      <Container vertical as={Segment} textAlign={'center'}>
        <Pagination
          totalPages={meta?.totalPage}
          activePage={activePage}
          onPageChange={(e, {activePage}) => {
            _handleRoute({
              newActivePage: activePage,
            })
          }}
        />
      </Container>
      <Container vertical as={Segment}>
        <Card.Group itemsPerRow={3}>
          {
            businessBanners?.map((bb, i) => {
              return (
                <Card
                  as={Link}
                  to={`/business-banner/${bb.id}`}
                  key={i}>
                  <Image
                    src={bb.imageUrl}
                  />
                  <Label
                    attached={'top right'}
                    color={bb.isPublic ? 'red' : ''}
                    content={bb.isPublic ? '공개' : '비공개'}/>
                  <Card.Content>
                    {bb.id}
                  </Card.Content>
                </Card>
              )
            })
          }
        </Card.Group>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessBannerMainContainer);
