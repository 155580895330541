import React, {Component, useState, useCallback, useEffect} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {uploadImageToFirebase} from '../../library/imageUploadToFirebase';
import {Loader} from "semantic-ui-react";
import "./DragAndDrop.css";
import {withLineEnter} from '../../library/TextHandler';

const DragAndDropFileUploaderComponent = (props) => {

  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);

  const dragRef = React.useRef(null);

  const getImageSize = (file) => {
    const reader = new FileReader();

    const promise = new Promise(((resolve, reject) => {
      reader.onload = (e) => {
        let img = new Image();
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
            ratio: img.height / img.width
          });
          resolve({});
        };

        img.src = e.target.result;
      };
      reader.onerror = reject;
    }));
    reader.readAsDataURL(file);

    return promise;
  };

  const onChangeFiles = useCallback(
    async (e) => {
      let selectFiles = [];
      let urls = [];
      let data = [];
      let tempFiles = files;

      if (e.type === "drop") {
        selectFiles = e.dataTransfer.files;
      } else {
        selectFiles = e.target.files;
      }
      setUploading(true);
      props.onStart?.();
      // selectFiles = props.multipleEnabled ? selectFiles : selectFiles.slice(0, 1)
      // for (const file of selectFiles) {
      for (let i = 0; i < (props.multipleEnabled ? selectFiles.length : 1); i++) {
        const file = selectFiles[i];
        if (file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/png") return;
        const size = await getImageSize(file);
        const url = await uploadImageToFirebase({file, storageRef: props.storageRef || null});
        if (!url) return window.alert('이미지 업로드 중 에러');
        props.handleCompleteEach?.({url, size});
        urls.push(url);
        data.push({url, size});
        tempFiles = [
          ...tempFiles,
          {
            id: file.name + moment().format("HHmmss"),
            object: file,
          },
        ];
      }
      setUploading(false);

      props.handleCompleteAll?.({urls, data});
    },
    [files],
  );


  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer?.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();

      await onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles],
  );

  const initDragEvents = useCallback(() => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener("dragenter", handleDragIn);
      dragRef.current.addEventListener("dragleave", handleDragOut);
      dragRef.current.addEventListener("dragover", handleDragOver);
      dragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback(() => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener("dragenter", handleDragIn);
      dragRef.current.removeEventListener("dragleave", handleDragOut);
      dragRef.current.removeEventListener("dragover", handleDragOver);
      dragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();

    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  return (
    <div className={"DragDrop"} style={{...props.style}}>
      {
        uploading && (
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.7)',
          }}>
            <Loader/>
          </div>
        )
      }

      <input
        type="file"
        disabled={uploading}
        id="fileUpload"
        style={{display: "none"}}
        multiple={props.multipleEnabled || false}
        onChange={onChangeFiles}
        accept="image/png, image/jpeg"
      />
      <label
        style={isDragging ? {} : {}}
        className={isDragging ? "DragDrop-File-Dragging" : "DragDrop-File"}
        htmlFor="fileUpload"
        ref={dragRef}
      >
        <div style={{textAlign: "center"}}>
          {
            withLineEnter(props.text || '여기를 눌러서 파일을 선택하거나,\n 드래그앤드랍으로 파일을 올려주세요.')
          }
        </div>
      </label>
    </div>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(DragAndDropFileUploaderComponent);
