import axios from "axios";
import moment from "moment";
import {auth} from "../constants/firebase";
import {currentBuildNo} from "../constants/buildNo";

const defaultFirebaseUid = "";
const defaultAuthorization = "";


let devMode;
devMode = true;
let API;
API = "https://asia-northeast1-readers-prod.cloudfunctions.net/apiBizAdmin/v1"; // PROD
// API = "http://localhost:5000/readers-prod/asia-northeast1/apiBizAdmin/v1";

// API = "https://asia-northeast1-readers-stage.cloudfunctions.net/apiBizAdmin/v1"; // STAGE
// API = "http://localhost:5000/readers-stage/asia-northeast1/apiBizAdmin/v1";

// API = "https://asia-northeast1-readers-dev.cloudfunctions.net/apiBizAdmin/v1"; // DEV
// API = "http://localhost:5000/readers-dev/asia-northeast1/apiBizAdmin/v1";


class Network {

  constructor() {/**/
    this.headers = {
      firebaseUid: auth?.currentUser?.uid || "",//defaultFirebaseUid,
      Authorization: defaultAuthorization,
      currentBuildNo: currentBuildNo,
      timezone: "Asia/Seoul",
      businessId: 1,
    };


  }

  setFirebaseUid(uid) {
    this.headers["firebaseUid"] = uid || defaultFirebaseUid;
    // console.log('firebaseUid :', this.headers['firebaseUid']);
  }

  getFirebaseUid(uid) {
    return this.headers["firebaseUid"];
  }

  onError(e, url, method, data) {
    console.log(e?.message);
    let errorMessage = "오류가 발생하였습니다.\n고객센터에 문의해주세요";
    if (e.response) {
      devMode && console.log(`[🔴 ERROR] ${method.toUpperCase()} ${url} ${e.response?.status}`, e.response.data);
      if (e.response?.data?.userMessage) {
        errorMessage = e.response.data.userMessage;
      }
    } else {
      devMode && console.log(`[🔴 ERROR] ${method.toUpperCase()} ${url} ${e.response?.status}`, e.message);
    }

    window.alert(errorMessage);
    return {
      err: true,
      errorMessage,
    };
  }

  _sendRequest(url, params, method, headers) {
    const a = moment();
    devMode && console.log("[🟡 REQ]", method.toUpperCase(), url, params);

    return axios[method](API + url, {headers: headers || this.headers, params})
      .then(response => {
        devMode && console.log(`[🟢 RES] ${moment().diff(a, "millisecond").toString()}`, method.toUpperCase(), url, response.data);
        return response.data;
      })
      .catch(e => this.onError(e, url, method, params));
  }

  // post, put
  _sendRequestForData(url, data, method, headers) {
    const a = moment();
    devMode && console.log("[🟡 REQ]", method.toUpperCase(), url, data);
    return axios[method](API + url, data, {headers: {...this.headers, ...headers}})
      .then(response => {
        devMode && console.log(`[🟢 RES] ${moment().diff(a, "millisecond").toString()}`, method.toUpperCase(), url, response.data);
        return response.data;
      })
      .catch(e => this.onError(e, url, method, data));
  }

  _get = (url, params, headers) => this._sendRequest(url, params, "get", headers);
  _post = (url, data) => this._sendRequestForData(url, data, "post");

  _delete = (url, params) => this._sendRequest(url, params, "delete");
  _put = (url, data, headers) => this._sendRequestForData(url, data, "put", headers);

  // 🔴️ A 🔴
  // AUTH
  signInWithSignInIdAndPassword = (signInId, password, businessId) => this._post("/auth/sign-in", {signInId, password, businessId});

  // 🔴️ B 🔴
  // BOOK
  bookGET = ({bookId}) => this._get('/book', {bookId});

  // BOOK CATEGORY
  bookCategoryGET = ({bookCategoryId}) =>
    this._get('/book-category', {bookCategoryId})
  bookCategoryListGET = ({offset, limit}) =>
    this._get('/book-category/list', {offset, limit})
  bookCategorySearchGET = ({keyword, offset, limit}) =>
    this._get('/book-category/search', {keyword, offset, limit})


  // BUSINESS_ BANNER
  businessBannerGET = ({businessBannerId}) =>
    this._get('/business-banner', {businessBannerId})
  businessBannerListGET = ({offset, limit}) =>
    this._get('/business-banner/list', {offset, limit})

  // BUSINESS BOOK CLUB
  businessBookClubListGET = ({offset, limit}) =>
    this._get('/business-book-club/list', {offset, limit})
  businessBookClubPOST = ({title, description, startDate, isbn13, isPublic}) =>
    this._post(`/business-book-club`, {title, description, startDate, isbn13, isPublic})
  businessBookClubGET = ({businessBookClubId}) =>
    this._get(`/business-book-club/${businessBookClubId}`)
  businessBookClubPUT = ({businessBookClubId, title, description, startDate, isbn13, isPublic}) =>
    this._put(`/business-book-club/${businessBookClubId}`, {title, description, startDate, isbn13, isPublic})
  businessBookClubUsersGET = ({businessBookClubId, offset, limit}) =>
    this._get(`/business-book-club/${businessBookClubId}/users`, {offset, limit})
  businessBookClubScrapsGET = ({businessBookClubId, offset, limit}) =>
    this._get(`/business-book-club/${businessBookClubId}/scraps`, {offset, limit})

  businessBookClubMissionsGET = ({businessBookClubId, offset, limit}) =>
    this._get(`/business-book-club/${businessBookClubId}/missions`, {offset, limit})
  businessBookClubMissionPOST = ({businessBookClubId, businessBookClubMissionTypeId, title, description, startDate, endDate, businessPointInTime, businessPointLate, order}) =>
    this._post(`/business-book-club/${businessBookClubId}/mission`, {businessBookClubMissionTypeId, title, description, startDate, endDate, businessPointInTime, businessPointLate, order})
  businessBookClubMissionPUT = ({businessBookClubId, businessBookClubMissionId, businessBookClubMissionTypeId, title, description, startDate, endDate, businessPointInTime, businessPointLate, order}) =>
    this._put(`/business-book-club/${businessBookClubId}/mission/${businessBookClubMissionId}`, {businessBookClubMissionTypeId, title, description, startDate, endDate, businessPointInTime, businessPointLate, order})
  businessBookClubMissionDELETE = ({businessBookClubId, businessBookClubMissionId}) =>
    this._delete(`/business-book-club/${businessBookClubId}/mission/${businessBookClubMissionId}`)


  // BUSINESS_BOOK CATEGORY DISABLED
  businessBookCategoryDisabledPOST = ({bookCategoryId}) =>
    this._post('/business-book-category-disabled', {bookCategoryId})
  businessBookCategoryDisabledGET = ({businessBookCategoryDisabledId}) =>
    this._get('/business-book-category-disabled', {businessBookCategoryDisabledId})
  businessBookCategoryDisabledPUT = ({businessBookCategoryDisabledId, businessBookCategoryDisabled}) =>
    this._put('/business-book-category-disabled', {
      businessBookCategoryDisabledId,
      businessBookCategoryDisabled,
    })
  businessBookCategoryDisabledDELETE = ({bookCategoryId}) =>
    this._delete('/business-book-category-disabled', {bookCategoryId})
  businessBookCategoryDisabledListGET = ({offset, limit}) => this._get('/business-book-category-disabled/list', {offset, limit})

  businessBookCategoryDisabledSearchGET = ({keyword, offset, limit}) => this._get('/business-book-category-disabled/search', {keyword, offset, limit})


  // BUSINESS BOOK CATEGORY EXCEPTION
  businessBookCategoryExceptionPOST = ({bookId}) =>
    this._post('/business-book-category-exception', {bookId})
  businessBookCategoryExceptionDELETE = ({bookId}) =>
    this._delete('/business-book-category-exception', {bookId})

  businessBookCategoryExceptionListGET = ({offset, limit}) =>
    this._get('/business-book-category-exception/list', {offset, limit})
  businessBookCategoryExceptionSearchGET = ({keyword, offset, limit}) =>
    this._get('/business-book-category-exception/search', {keyword, offset, limit})


  // BUSINESS BOOK DISABLED
  businessBookDisabledPOST = ({businessBookDisabled}) =>
    this._post('/business-book-disabled', {businessBookDisabled})
  businessBookDisabledGET = ({businessBookDisabledId}) =>
    this._get('/business-book-disabled', {businessBookDisabledId})
  businessBookDisabledPUT = ({businessBookDisabledId, businessBookDisabled}) =>
    this._put('/business-book-disabled', {businessBookDisabledId, businessBookDisabled})
  businessBookDisabledDELETE = ({businessBookDisabledId}) =>
    this._delete('/business-book-disabled', {businessBookDisabledId})

  businessBookDisabledListGET = ({offset, limit}) =>
    this._get('/business-book-disabled/list', {offset, limit})
  businessBookDisabledSearchGET = ({keyword, offset, limit}) =>
    this._get('/business-book-disabled/search', {keyword, offset, limit})

  // BUSINESS-BOOK-FORUM
  businessBookForumListGET = ({offset, limit}) => this._get("/business-book-forum/list", {offset, limit});

  // BUSINESS-BOOK-RECOMMENDATION-COLLECTION
  businessBookRecommendationCollectionListGET = ({offset, limit}) =>
    this._get("/business-book-recommendation-collection/list", {offset, limit});
  businessBookRecommendationCollectionGET = ({businessBookRecommendationCollectionId}) =>
    this._get("/business-book-recommendation-collection", {businessBookRecommendationCollectionId});
  businessBookRecommendationCollectionPUT = ({businessBookRecommendationCollectionId, data}) =>
    this._put("/business-book-recommendation-collection", {businessBookRecommendationCollectionId, data});
  businessBookRecommendationCollectionPOST = ({data}) =>
    this._post("/business-book-recommendation-collection", {data});
  businessBookRecommendationCollectionDELETE = ({businessBookRecommendationCollectionId}) =>
    this._delete("/business-book-recommendation-collection", {businessBookRecommendationCollectionId});

  // BUSINESS-BOOK-REQUEST
  businessBookRequestGET = ({businessBookRequestId}) =>
    this._get("/business-book-request", {businessBookRequestId});
  businessBookRequestListGET = ({offset, limit, startDate, endDate}) =>
    this._get("/business-book-request/list", {offset, limit, startDate, endDate});
  businessBookRequestListUserGET = ({userId, offset, limit, startDate, endDate}) =>
    this._get("/business-book-request/list/user", {userId, offset, limit, startDate, endDate});
  businessBookRequestListForSalesGET = ({offset, limit, startDate, endDate}) =>
    this._get("/business-book-request/list/for-sales", {offset, limit, startDate, endDate});
  businessBookRequestMainGET = ({startDate, endDate, unit}) =>
    this._get("/business-book-request/main", {startDate, endDate, unit});

  // BUSINESS-CONTENT
  businessContentGET = ({businessContentId}) =>
    this._get("/business-content", {businessContentId});
  businessContentDELETE = ({businessContentId}) =>
    this._delete("/business-content", {businessContentId});
  businessContentPOST = ({data}) =>
    this._post("/business-content", {data});
  businessContentPUT = ({businessContentId, data}) =>
    this._put("/business-content", {businessContentId, data});
  businessContentMainGET = ({}) =>
    this._get("/business-content/main", {});
  businessContentListGET = ({offset, limit, businessContentTypeId}) =>
    this._get("/business-content/list", {offset, limit, businessContentTypeId});

  // BUSINESS-CONTENT-COMMENT
  businessContentCommentListGET = ({offset, limit, startDate, endDate, businessContentId}) =>
    this._get('/business-content-comment/list', {offset, limit, startDate, endDate, businessContentId})

  // BUSINESS CONTENT TYPE
  businessContentTypeListGET = ({}) =>
    this._get('/business-content-type/list', {})

  // BUSINESS LEARNING CREDIT
  businessLearningCreditMainGET = ({startDate, endDate, unit}) =>
    this._get("/business-learning-credit/main", {startDate, endDate, unit});
  businessLearningCreditListGET = ({type, offset, limit, startDate, endDate}) =>
    this._get("/business-learning-credit/list", {type, offset, limit, startDate, endDate});

  // BUSINESS MEMBER
  businessMemberMainGET = () => this._get("/business-member/main");
  businessMemberMakeManyPOST = ({data}) =>
    this._post('/business-member/make-many', {data})
  businessMemberDisableManyPUT = ({userIds}) =>
    this._put('/business-member/disable-many', {userIds})
  businessMemberListCompanyIdNumberGET = ({companyIdNumbers}) =>
    this._get('/business-member/list/company-id-number', {companyIdNumbers})
  businessMemberListCompanyIdNumberPOST = ({companyIdNumbers}) =>
    this._post('/business-member/list/company-id-number', {companyIdNumbers})

  // BUSINESS NOTICE
  businessNoticeGET = ({businessNoticeId}) =>
    this._get("/business-notice", {businessNoticeId});
  businessNoticeListGET = ({offset, limit, startDate, endDate}) =>
    this._get("/business-notice/list", {offset, limit, startDate, endDate});
  businessNoticeMainGET = ({}) =>
    this._get("/business-notice/main", {});

  // 🔴️ C
  // 🔴️ D
  // DASH BOARD
  dashboardGET = ({startDate, endDate, unit}) =>
    this._get('/dashboard', {startDate, endDate, unit})


  // 🔴️ E
  eventTempMonthlyHashTagGET = ({hashTag}) => this._get('/event-temp/monthly-hash-tag', {hashTag})
  // 🔴️ F
  // 🔴️ G
  // 🔴️ H
  // 🔴️ I
  // INQUIRY
  inquiryMainGET = ({startDate, endDate, unit}) =>
    this._get("/inquiry/main", {startDate, endDate, unit});
  inquiryListGET = ({offset, limit, filter, startDate, endDate}) =>
    this._get("/inquiry/list", {offset, limit, filter, startDate, endDate});
  inquiryReplyPOST = ({inquiryId, answer, inquiryCategoryId}) =>
    this._post("/inquiry/reply", {inquiryId, answer, inquiryCategoryId});
  inquirySendPOST = ({userId, answer}) =>
    this._post("/inquiry/send", {userId, answer});
  inquiryGET = ({inquiryId}) =>
    this._get("/inquiry", {inquiryId});
  inquiryDELETE = ({inquiryId}) =>
    this._delete("/inquiry", {inquiryId});
  inquiryPUT = ({inquiryId, answer, inquiryCategoryId}) =>
    this._put("/inquiry", {inquiryId, answer, inquiryCategoryId});

  // 🔴️ J
  // 🔴️ K
  // 🔴️ L
  // 🔴️ M
  // 🔴️ N
  // 🔴️ O
  // 🔴️ P
  // 🔴️ Q
  // 🔴️ R
  // 🔴️ S 🔴
  searchGET = ({itemType, keyword, offset, limit, aladinCategoryId, method}) => this._get("/search", {itemType, keyword, offset, limit, aladinCategoryId, method});
  smsPOST = ({phoneNumbers, message, title, callback}) => this._post('/sms', {phoneNumbers, message, title, callback})
  // 🔴️ T
  // 🔴️ U
  userGET = ({userId}) => this._get('/user/', {userId})
  userMyGET = ({}) => this._get('/user/my', {})
  userPasswordPUT = ({userId, password}) => this._put('/user/password', {userId, password})
  // 🔴️ V
  // 🔴️ W
  // 🔴️ X
  // 🔴️ Y
  // 🔴️ Z

}

export default new Network();
