import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Container} from "semantic-ui-react";

const HomeContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  return (
    <div>
      <Container>
        <div style={{textAlign:'right'}}>
          asdlgjasd
        </div>
      </Container>
      HomeContainer
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(HomeContainer);
