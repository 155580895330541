import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {moveItemInArrayFromIndexToIndex} from '../../library/arrayHandler';
import {Button, Card, Image, Input, Modal, Table} from 'semantic-ui-react';
import Network from '../../library/Network';
import Truncate from 'react-truncate';
import getTitleWithoutSubTitle from '../../library/removeSubTitle';

const DraftAddUserComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [customComponentData, setCustomComponentData] = useState({type: '', items: []});
  const [keyword, setKeyword] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchedList, setSearchedList] = useState([]);

  const _addItem = (item) => {
    const {items} = customComponentData;
    items.push(item);
    setCustomComponentData(prev => ({...prev, items}));
  };

  const _handleMove = (from, to) => {
    const {items} = customComponentData;
    const newItems = moveItemInArrayFromIndexToIndex(items, from, to);
    setCustomComponentData(prev => ({...prev, items: newItems}));
  };

  const _removeItem = (index) => {
    const {items} = customComponentData;
    items.splice(index, 1);
    setCustomComponentData(prev => ({...prev, items}));
  };

  const _handleComplete = () => {
    props.handleComplete(customComponentData);
  };
  const _handleCancel = () => {
    props.handleCancel();
  };

  const _handleSearch = async () => {
    if (!keyword || keyword.trim() === '') return window.alert('검색어를 입력해 주세요');
    setSearching(true);
    setSearchedList([]);
    const res = await Network.searchGET(
      {
        keyword,
        offset: 0,
        limit: 50,
        itemType: 'USER',
        // method: 'es',
      },
    );
    if (!res.err) {
      setSearchedList(res.searchedList);
    }
    setSearching(false);
  };

  const _onInputKeyDown = (e) => {
    if (e.which === 13) {
      _handleSearch();
    }
  };

  return (
    <Modal
      open={props.open}
      // open
      size={'large'}
    >
      <Modal.Header>
        유저 추가하기
      </Modal.Header>
      <Modal.Content style={{maxHeight: '20vh', minHeight: '20vh'}} scrolling>
        <Table compact={'very'} size={'small'} singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>-</Table.HeaderCell>

              <Table.HeaderCell>-</Table.HeaderCell>

            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              customComponentData?.items?.map((o, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {o.id}
                    </Table.Cell>
                    <Table.Cell>
                      <Image src={o.coverImageUrl} inline size={'mini'}/>
                    </Table.Cell>
                    <Table.Cell>
                      <Button.Group size={'mini'}>
                        <Button
                          disabled={i === 0}
                          onClick={() => _handleMove(i, i - 1)}
                          icon={'chevron up'}/>
                        <Button
                          disabled={i === customComponentData.items?.length - 1}
                          onClick={() => _handleMove(i, i + 1)}
                          icon={'chevron down'}/>
                        <Button
                          onClick={() => _removeItem(i)}
                          icon={'remove'}/>
                      </Button.Group>

                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Content>
        <Input
          onKeyDown={_onInputKeyDown}
          onChange={(e, {value}) => {
            setKeyword(value);
          }}
          fluid
          action
        >
          <input/>
          <Button
            loading={searching}
            disabled={searching}
            onClick={_handleSearch}
            icon={'search'}/>
        </Input>
      </Modal.Content>
      <Modal.Content style={{maxHeight: '50vh', minHeight: '50vh'}} scrolling>
        <Table compact={'very'} size={'small'} singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>-</Table.HeaderCell>

              <Table.HeaderCell>-</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {searchedList?.map((o, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                    {o.id}
                  </Table.Cell>

                  <Table.Cell>
                    <Button
                      onClick={() => _addItem(o)}
                      size={'mini'}
                      icon={'add'}/>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button content={'취소'} onClick={_handleCancel}/>
        <Button content={'추가'} onClick={_handleComplete}/>
      </Modal.Actions>
    </Modal>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(DraftAddUserComponent);
