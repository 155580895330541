import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {useParams} from "react-router";
import {Card, Container, Grid, Segment, Form, TextArea, Table, Message, Header, Divider, Button} from "semantic-ui-react";
import UserComponent from "../../components/user/UserComponent";
import moment from "moment";
import {Link} from "react-router-dom";
import {withLineEnter} from "../../library/TextHandler";
import Network from '../../library/Network';

const InquiryDetailContainer = (props) => {

  const {inquiryId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [inquiry, setInquiry] = useState({});
  const [inquiries, setInquiries] = useState([]);
  const [inquiryTemplates, setInquiryTemplates] = useState([]);
  const [inquiryCategories, setInquiryCategories] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedInquiryCategoryId, setSelectedInquiryCategoryId] = useState(null);

  const _load = async () => {
    const res = await Network.inquiryGET({
      inquiryId,
    });
    if (!res.err) {
      setIsLoaded(true);
      setUploading(false);
      setInquiry(res.inquiry);
      setInquiries(res.inquiries);
      setInquiryTemplates(res.inquiryTemplates);
      setInquiryCategories(res.inquiryCategories);
      setIsEditMode(!res.inquiry?.isAnswered);
      setSelectedInquiryCategoryId(res.inquiry?.inquiryCategoryId);
    }
  };

  const _update = async () => {
    const a = window.confirm("수정하시겠습니까?");
    if (!a) return;
    setUploading(true);
    const res = await Network.inquiryPUT(
      {
        inquiryId,
        answer: inquiry.answer,
        inquiryCategoryId: selectedInquiryCategoryId,
      },
    );
    setUploading(false);
    if (!res.err) {
      window.alert("수정되었습니다");
      _load();
    }
  };

  const _handleReply = async () => {
    if (inquiry.answer === "" || !inquiry.answer) return window.alert("답변을 적어주세요");
    const a = window.confirm("답변하시겠습니까?");
    if (!a) return;
    setUploading(true);
    const res = await Network.inquiryReplyPOST(
      {
        inquiryId,
        answer: inquiry.answer,
        inquiryCategoryId: selectedInquiryCategoryId,
      },
    );
    if (!res.err) {
      window.alert("답변이 완료 되었습니다.");
      _load();
    } else {
      setUploading(true);
    }
  };

  useEffect(() => {
    setIsLoaded(false);
    setInquiries([]);
    setInquiry({});
    setInquiryTemplates([]);
    _load();
  }, [inquiryId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    _load();
  }, []);

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        <Header content={`1:1 문의 상세 내역 (${inquiryId})`}/>
        <Message>
          <UserComponent
            type={"HORIZONTAL"}
            user={inquiry.user}/>
          <Divider/>
          <Message.Header content={"질문"}/>
          <Message.Content>
            {withLineEnter(inquiry.question)}
          </Message.Content>
        </Message>
        <Form>
          <Form.TextArea
            // disabled={!isEditMode}
            label={"답변"}
            rows={15}
            value={inquiry.answer}
            onChange={(e, {value}) => {
              if (!isEditMode) return;
              setInquiry(prev => ({...prev, answer: value}));
            }}
          />
          <Form.Group>
            <Form.Dropdown
              selection
              clearable
              search
              value={selectedInquiryCategoryId}
              onChange={(e, {value}) => {
                setSelectedInquiryCategoryId(value);
              }}
              options={inquiryCategories.map(o => {
                return {
                  key: o.id,
                  value: o.id,
                  text: o.title,
                };
              })}
            />
          </Form.Group>
        </Form>
        {
          inquiry?.isAnswered ?
            (
              <>
                <Message warning>
                  <Message.Content>
                    답변이 완료된 문의입니다. <br/>
                    수정하시려면, 수정버튼을 눌러주세요.
                  </Message.Content>
                </Message>
                <div>
                  <Button
                    loading={uploading}
                    basic={isEditMode}
                    disabled={uploading}
                    onClick={() => setIsEditMode(!isEditMode)}
                    content={isEditMode ? `수정중` : `수정모드`}/>
                  <Button
                    primary
                    loading={uploading}
                    disabled={uploading || !isEditMode}
                    onClick={_update}
                    content={"반영하기"}
                  />
                  <Button
                    negative
                    loading={uploading}
                    disabled={uploading || !isEditMode}
                    onClick={() => {
                      setIsEditMode(false);
                      _load();
                    }}
                    content={"취소"}
                  />
                </div>
              </>
            ) :
            (
              <>
                <Message info>
                  <Message.Content>
                    답변을 달아주세요. <br/>
                    답변은 친절하게 부탁드려요.
                  </Message.Content>
                </Message>
                <Button
                  content={"답변하기"}
                  onClick={_handleReply}
                />
              </>
            )
        }
      </Container>
      <Container as={Segment} vertical basic>
        <Grid>

        </Grid>
      </Container>
      <Container as={Segment} vertical basic>
        <Header content={"유저의 다른 문의 내역"}/>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} content={"no"}/>
              <Table.HeaderCell width={1} content={"id"}/>
              <Table.HeaderCell width={4} content={"질문"}/>
              <Table.HeaderCell width={4} content={"답변"}/>
              <Table.HeaderCell width={2} content={"질문일"}/>
              <Table.HeaderCell width={2} content={"답변일"}/>
              <Table.HeaderCell width={2} content={"소요시간"}/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              inquiries?.map((o, i) => {
                return (
                  <Table.Row
                    style={{verticalAlign: "top"}}
                    key={i}>
                    <Table.Cell>
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/inquiry/${o.id}`}>
                        {o.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {o.question}
                    </Table.Cell>
                    <Table.Cell>
                      {o.answer}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.createdAt).format("MM.DD(ddd) HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {o.isAnswered ? `${moment(o.answeredAt).format("MM.DD(ddd) HH:mm")}` : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {o.isAnswered ? `${moment(o.answeredAt).diff(o.createdAt, "hour")}시간` : "-"}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(InquiryDetailContainer);
