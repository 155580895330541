import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";
import { Button, Card, Container, Grid, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Network from '../../library/Network';

const BusinessContentMainContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [businessContents, setBusinessContents] = useState([]);

  const _load = async () => {
    const res = await Network.businessContentMainGET({});
    if (!res.err) {
      setIsLoaded(true);
      setBusinessContents(res.businessContents);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    _load();
  }, []);

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"컨텐츠 카테고리"}/>
                  <Button
                    primary
                    basic
                    as={Link}
                    to={`/business-content-group`}
                    content={"더보기"}
                    floated={"right"}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"최근 컨텐츠"}/>
                  <Button
                    primary
                    basic
                    as={Link}
                    to={`/business-content/list`}
                    content={"더보기"}
                    floated={"right"}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"컨텐츠 댓글 "}/>
                  <Button
                    primary
                    basic
                    as={Link}
                    to={`/business-content-comment/list`}
                    content={"더보기"}
                    floated={"right"}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"최근 컨텐츠"}/>
                  <Button
                    primary
                    basic
                    as={Link}
                    to={`/business-content/list`}
                    content={"더보기"}
                    floated={"right"}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container as={Segment} vertical basic>

      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessContentMainContainer);
