import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from "../../library/useQuery";
import { useHistory, useParams } from "react-router";
import Network from "../../library/Network";
import { Breadcrumb, Button, Container, Divider, Header, List, Message, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

const BookCategoryDetailContainer = (props) => {

  const { bookCategoryId } = useParams();

  const history = useHistory();

  const [isLoaded, setIsLoaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [bookCategory, setBookCategory] = useState({})

  const _load = async () => {
    const res = await Network
      .bookCategoryGET({ bookCategoryId })
    if(!res.err) {
      setBookCategory(res.bookCategory)
      setIsLoaded(true);
    }
  }

  const _handleAddBusinessBookCategoryDisabled = async () => {
    setUploading(true);
    const res = await Network
      .businessBookCategoryDisabledPOST({
        bookCategoryId,
      })

    if(!res.err) {
      await _load()
    }
    setUploading(false);
  }
  const _handleRemoveBusinessBookCategoryDisabled = async () => {
    setUploading(true);
    const res = await Network
      .businessBookCategoryDisabledDELETE(
        {
          bookCategoryId,
        },
      )
    if(!res.err) {
      await _load();
    }
    setUploading(false);
  }

  useEffect(() => {
    _load();
  }, [])

  return (
    <>
      <Container as={Segment} vertical basic>
        <Breadcrumb>
          <Breadcrumb.Section
            as={Link}
            to={`/book-category/list`}
          >
            책 카테고리
          </Breadcrumb.Section>
          <Breadcrumb.Divider/>
          <Breadcrumb.Section
            as={Link}
            to={`/book-category/list`}
          >
            리스트
          </Breadcrumb.Section>
          <Breadcrumb.Divider/>
          <Breadcrumb.Section active>
            {bookCategoryId}
          </Breadcrumb.Section>
        </Breadcrumb>
      </Container>
      <Container as={Segment} vertical basic>
        <Header>
          {bookCategory.aladinCategoryName}
        </Header>
        <List bullet>
          <List.Item>
            DEPTH 1 {bookCategory.depth_1}
          </List.Item>
          <List.Item>
            DEPTH 2 {bookCategory.depth_2}
          </List.Item>
          <List.Item>
            DEPTH 3 {bookCategory.depth_3}
          </List.Item>
        </List>
        {
          !!bookCategory.businessBookCategoryDisabled ? (
            <Message>
              신청 불가 카테고리 입니다.
              <Divider hidden/>
              <Button
                disabled={uploading}
                loading={uploading}
                negative
                onClick={_handleRemoveBusinessBookCategoryDisabled}>
                신청 불가 카테고리 해제
              </Button>
            </Message>
          ) : (
            <>
              <Button
                disabled={uploading}
                loading={uploading}
                positive
                onClick={_handleAddBusinessBookCategoryDisabled}>
                신청 불가 카테고리 등록
              </Button>
            </>
          )
        }
      </Container>
    </>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BookCategoryDetailContainer);
