import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyBTaIOttirBazP0Wpv87ULOoZ5iLlxsn-Y",
  authDomain: "readers-prod.firebaseapp.com",
  databaseURL: "https://readers-prod.firebaseio.com",
  projectId: "readers-prod",
  storageBucket: "readers-prod.appspot.com",
  messagingSenderId: "491256222034",
  appId: "1:491256222034:web:beeb1bc13320155a"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();


export {
  firebase,
  db,
  auth,
  storage
};
