import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Container, Segment, Tab} from "semantic-ui-react";
import {useQuery} from "../../library/useQuery";
import {useHistory, useLocation, useParams} from "react-router";
import BusinessBookClubDetailUserTab from "./BusinessBookClubDetailUserTab";
import BusinessBookClubDetailInfoTab from "./BusinessBookClubDetailInfoTab";
import BusinessBookClubDetailMissionTab from "./BusinessBookClubDetailMissionTab";

const BusinessBookClubDetailContainer = (props) => {

  const history = useHistory();
  const location = useLocation();
  const {businessBookClubId} = useParams();
  let {tab, activePage, perPage, keyword} = useQuery();
  tab = !!tab ? Number(tab) : 0;
  activePage = !!activePage ? Number(activePage) : 1;

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();
  }, [])

  const panes = [
    {menuItem: '신청자', render: () => <Tab.Pane><BusinessBookClubDetailUserTab/></Tab.Pane>},
    {menuItem: '미션', render: () => <Tab.Pane><BusinessBookClubDetailMissionTab/></Tab.Pane>},
    {menuItem: '정보', render: () => <Tab.Pane><BusinessBookClubDetailInfoTab/></Tab.Pane>},
  ]

  return (
    <div>
      <Container as={Segment} vertical>
        <Tab
          menu={{secondary: true}}
          activeIndex={tab}
          panes={panes}
          onTabChange={(e, {activeIndex}) => history.replace(`${location.pathname}?tab=${activeIndex}`)}
        />
      </Container>
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessBookClubDetailContainer);
