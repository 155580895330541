import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {useQuery} from '../../library/useQuery';
import moment from 'moment';
import Network from '../../library/Network';
import {Button, Container, Form, Pagination, Segment, Table} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import {useHistory} from 'react-router';

const BusinessContentCommentListContainer = (props) => {

  const history = useHistory();
  let {startDate, endDate, perPage, activePage, businessContentId} = useQuery();
  startDate = !!startDate ? startDate : moment().startOf('month').toISOString();
  endDate = !!endDate ? endDate : moment().endOf('month').toISOString();
  perPage = !!perPage ? Number(perPage) : 50;
  activePage = !!activePage ? Number(activePage) : 1;
  businessContentId = !!businessContentId ? Number(businessContentId) : undefined;
  const [isLoaded, setIsLoaded] = useState(false)
  const [businessContentComments, setBusinessContentComments] = useState([])
  const [meta, setMeta] = useState({})

  const _load = async () => {
    const res = await Network.businessContentCommentListGET(
      {
        offset: (activePage - 1) * perPage,
        limit: perPage,
        startDate,
        endDate,
        businessContentId
      }
    )
    if (!res.err) {
      setIsLoaded(true);
      setBusinessContentComments(res.businessContentComments);
      setMeta(res.meta);
    }
  }

  useEffect(() => {
    setBusinessContentComments([]);
    window.scrollTo(0, 0)
    _load();
  }, [startDate, endDate, activePage, perPage, businessContentId])

  if (!isLoaded) return <LoaderComponent/>

  const _handleRoute = (
    {
      newStartDate = startDate,
      newEndDate = endDate,
      newActivePage = activePage,
      newPerPage = perPage,
    }
  ) => {
    let path = `/business-content-comment/list?startDate=${moment(newStartDate).toISOString()}&endDate=${moment(newEndDate).toISOString()}&activePage=${newActivePage}&perPage=${newPerPage}`;
    if (!!businessContentId) {
      path = path + `&businessContentId=${businessContentId}`
    }
    history.push(path);
  };

  return (
    <>
      <Container as={Segment} vertical>
        <Form>
          <Form.Group style={{alignItems: "flex-end"}}>
            <Form.Dropdown
              label={"표시개수"}
              selection
              value={perPage}
              onChange={(e, {value}) => {
                console.log({value});
                _handleRoute(
                  {
                    newPerPage: value
                  }
                );
              }}
              options={[
                {key: 0, text: "20", value: 20},
                {key: 1, text: "50", value: 50},
                {key: 2, text: "100", value: 100},
                {key: 3, text: "500", value: 500},
                {key: 4, text: "5000", value: 5000},
              ]}
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, {value}) => {
                                  if (!value) return;
                                  _handleRoute(
                                    {
                                      newStartDate: value
                                    }
                                  );
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, {value}) => {
                                  if (!value) return;
                                  _handleRoute(
                                    {
                                      newEndDate: value
                                    }
                                  );
                                }}/>
            <Button.Group>
              <Button
                onClick={() => {
                  _handleRoute({
                      newStartDate: moment(startDate).subtract(1, "month").startOf("month").toISOString(),
                      newEndDate: moment(startDate).subtract(1, "month").endOf("month").toISOString(),
                    }
                  );
                }}
                icon={"chevron left"}/>
              <Button
                onClick={() => {
                  _handleRoute(
                    {
                      newStartDate: moment().startOf("month").toISOString(),
                      newEndDate: moment().endOf("month").toISOString(),
                    }
                  );
                }}
                content={"이번달"}/>
              <Button
                onClick={() => {
                  _handleRoute(
                    {
                      newStartDate: moment(startDate).add(1, "month").startOf("month").toISOString(),
                      newEndDate: moment(startDate).add(1, "month").endOf("month").toISOString(),
                    }
                  );
                }}
                icon={"chevron right"}/>
            </Button.Group>
          </Form.Group>
        </Form>
      </Container>
      <Container as={Segment} vertical>
        <Pagination totalPages={meta?.totalPage}
                    defaultActivePage={1}
                    activePage={activePage}
                    onPageChange={(e, {activePage}) => {
                      _handleRoute({newActivePage: activePage});
                    }}/>
        <Table>
          <Table.Header>
          </Table.Header>
          <Table.Body>
            {
              businessContentComments?.map((o, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell width={1}>
                      {o.userId}
                    </Table.Cell>
                    <Table.Cell width={1}>
                      {o.user?.name}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      {o.user?.businessDepartment?.title}
                    </Table.Cell>
                    <Table.Cell>
                      {o.comment}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      {moment(o.createdAt).format('YYYY.MM.DD(ddd)HH:mm')}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessContentCommentListContainer);
