import React, {Component, useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {Button, Container, Divider, Header, Input, Message, Segment, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";
import readXlsxFile from 'read-excel-file'
import Network from '../../library/Network';
import _ from 'lodash';
import ReactExport from 'react-export-excel';
import moment from 'moment';

const BusinessMemberAddContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [data, setData] = useState([])
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(1)
  const inputRef = React.useRef();

  useEffect(() => {

  }, [])

  const onChangeText = useCallback((index, name, value) => {
    data[index][name] = value;
    console.log(data)
    setData(prevState => ([...data]))
  }, [data])

  const _handleUpload = async () => {
    if (data.length < 1) return;

    const b = data
    console.log(b);

    const a = window.confirm(`${b.length}개를 올리시겠습니까?`);
    if (!a) return;

    setUploading(true);
    const res = await Network.businessMemberMakeManyPOST({data: b});
    setUploading(false);
    if (!res.err) {
      window.alert('업로드 완료');
    }

    setStart(end + 1)
    setEnd(end + 20);
    // const c = window.confirm('다음 이어서 올리시겠습니까?');
    // if (!c) return;
    // _handleUpload()
  }

  const _loadBusinessMemberData = async () => {
    if (data.length < 1) return;
    const companyIdNumbers = [...new Set(data.map(o => o.companyIdNumber).filter(Boolean))];
    setLoading(true);

    console.log(companyIdNumbers.length);
    const res = await Network
      .businessMemberListCompanyIdNumberPOST(
        {
          companyIdNumbers: companyIdNumbers,
        }
      )

    if (!res.err) {
      const users = res.users;
      data.forEach((row, i) => {
        row.user = _.find(users, u => row.companyIdNumber === u.companyIdNumber);
      })

      setData(prev => ([...data]))
    }
    setLoading(false);
  }


  const _handleExcelUpload = async (e) => {
    const files = e.target.files;
    const f = files[0];
    if (!f) return;
    let rows = await readXlsxFile(f).then(row => {
      return row
    }).catch(e => {
      console.log(e)
      window.alert(e.message);
      return false;
    })

    if (!Array.isArray(rows)) return;
    if (rows.length < 1) return;

    // console.log(rows);
    rows.splice(0, 1)


    let a = rows.map(o => ({
      companyIdNumber: o[0],
      name: o[1],
      businessDepartmentTitle: o[2],
    }))

    a = a.filter(o => !!o.name && !!o.companyIdNumber && !!o.businessDepartmentTitle)
    console.log(a);
    setData(a);
  }

  const _renderDownload = () => {
    return (
      <ReactExport.ExcelFile
        element={<Button icon={"download"} content={"엑셀 파일 다운로드"}/>}
        filename={`인원추가_createdAt${moment().format("YYMMDD_HHmmss")}`}>
        <ReactExport.ExcelFile.ExcelSheet data={[{name: '홍길동', companyIdNumber: '099999999', businessDepartmentTitle: '인력개발팀'}]} name={"data"}>
          <ReactExport.ExcelFile.ExcelColumn label={"사번"} value={"companyIdNumber"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"이름"} value={"name"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"부서명"} value={"businessDepartmentTitle"}/>
        </ReactExport.ExcelFile.ExcelSheet>
      </ReactExport.ExcelFile>
    );
  };

  return (
    <>
      <Container as={Segment} vertical>
        <Header content={'구성원 추가'}/>
      </Container>
      <Container as={Segment} vertical>
        <Message>
          <Message.Content>
            <Message.Header>
              STEP 1.
            </Message.Header>
            양식을 다운로드 받습니다.
          </Message.Content>
          <Divider/>
          {_renderDownload()}
        </Message>
        <Message>
          <Message.Content>
            <Message.Header>
              STEP 2.
            </Message.Header>
            파일을 업로드 합니다.
          </Message.Content>
          <Divider/>
          <Button
            onClick={() => {
              inputRef.current.click?.();
            }}
            content={'파일 업로드'}/>
          <input type={'file'} hidden ref={inputRef} onChange={_handleExcelUpload}/>

          <Button
            disabled={data.length < 1}
            loading={loading}
            onClick={_loadBusinessMemberData}
            content={'기존 유저 확인'}/>
        </Message>
        <Message>
          <Message.Content>
            <Message.Header>
              STEP 3.
            </Message.Header>
            서버에 업로드 합니다.
          </Message.Content>
          <Divider/>
          <Button
            disabled={data.length < 1}
            loading={loading}
            onClick={_handleUpload}
            content={'추가하기'}/>
        </Message>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={'-'} width={1}/>
              <Table.HeaderCell content={'사번'} width={2}/>
              <Table.HeaderCell content={'이름'} width={2}/>
              <Table.HeaderCell content={'부서'} width={2}/>
              <Table.HeaderCell content={'-'}/>
              <Table.HeaderCell content={'-'}/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              data.map((newMember, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell content={i} width={1}/>
                    <Table.Cell>
                      {newMember.companyIdNumber}
                    </Table.Cell>
                    <Table.Cell>
                      {newMember.name}
                    </Table.Cell>
                    <Table.Cell>
                      {newMember.businessDepartmentTitle}
                    </Table.Cell>

                    <Table.Cell>
                      <Link to={`/user/${newMember?.user?.id}`}>
                        {newMember?.user?.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell style={{
                      color: newMember?.user?.isClosed ? 'red': ''
                    }}>
                      {newMember?.user?.isClosed ? '정지' : '정상'}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>

      </Container>
      <Container>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessMemberAddContainer);
