import BusinessBannerMainContainer from './BusinessBannerMainContainer';
import BusinessBannerDetailContainer from './BusinessBannerDetailContainer';

const businessBannerContainers = [
  {path: "/business-banner", component: BusinessBannerMainContainer},
  {path: "/business-banner/make", component: BusinessBannerDetailContainer},
  {path: "/business-banner/:businessBannerId", component: BusinessBannerDetailContainer},
]

export default businessBannerContainers
