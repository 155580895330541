import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import Network from '../../library/Network';
import {Button, Container, Divider, Header, Message, Segment, Table} from 'semantic-ui-react';
import ReactExport from "react-export-excel";
import readXlsxFile from 'read-excel-file'
import moment from 'moment';
import _ from 'lodash';
import {Link} from 'react-router-dom';

const BusinessMemberRemoveContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({})
  const [data, setData] = useState([])
  const inputRef = React.useRef();

  const _load = async () => {
    setIsLoaded(true);
  }

  const _uploadToServer = async () => {
    const userIds = [...new Set(data.map(o => o.user?.id).filter(Boolean))];
    if (userIds.length < 1) return;

    setLoading(true);
    const res = await Network.businessMemberDisableManyPUT(
      {
        userIds: userIds
      }
    )
    await _loadBusinessMemberData();
    setLoading(false);
  }

  const _loadBusinessMemberData = async () => {
    if (data.length < 1) return;
    const companyIdNumbers = [...new Set(data.map(o => o.companyIdNumber).filter(Boolean))];
    setLoading(true);

    console.log(companyIdNumbers.length);
    const res = await Network
      .businessMemberListCompanyIdNumberGET(
        {
          companyIdNumbers: companyIdNumbers,
        }
      )

    if (!res.err) {
      const users = res.users;
      data.forEach((row, i) => {
        row.user = _.find(users, u => row.companyIdNumber === u.companyIdNumber);
      })

      setData(prev => ([...data]))
    }
    setLoading(false);
  }

  const _handleChangeFile = async (e) => {
    const files = e.target.files;
    const f = files[0];
    console.log({f})
    if (!f) return;
    const rows = await readXlsxFile(f).then(row => {
      return row
    }).catch(e => {
      console.log(e)
      window.alert(e.message);
      return false;
    })

    console.log({rows});

    if (!rows) return;
    rows.splice(0, 1);
    const a = rows.map((o, i) => (
        {
          name: o[0],
          companyIdNumber: o[1],
        }
      )
    )
    setData(a);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])

  const _renderDownload = () => {
    return (
      <ReactExport.ExcelFile
        element={<Button icon={"download"} content={"엑셀 파일 다운로드"}/>}
        filename={`비활성화인원추가_createdAt${moment().format("YYMMDD_HHmmss")}`}>
        <ReactExport.ExcelFile.ExcelSheet data={[{name: '홍길동', companyIdNumber: '099999999'}]} name={"data"}>
          <ReactExport.ExcelFile.ExcelColumn label={"이름"} value={"name"}/>
          <ReactExport.ExcelFile.ExcelColumn label={"사번"} value={"companyIdNumber"}/>
        </ReactExport.ExcelFile.ExcelSheet>
      </ReactExport.ExcelFile>
    );
  };

  if (!isLoaded) return <LoaderComponent/>

  return (
    <>
      <Container as={Segment} vertical>
        <Header content={'구성원 비활성화'}/>
      </Container>
      <Container as={Segment} vertical>
        <Message>
          <Message.Content>
            <Message.Header>
              STEP 1.
            </Message.Header>
            양식을 다운로드 받습니다.
          </Message.Content>
          <Divider/>
          {_renderDownload()}
        </Message>
        <Message>
          <Message.Content>
            <Message.Header>
              STEP 2.
            </Message.Header>
            파일을 업로드 합니다.
          </Message.Content>
          <Divider/>
          <Button
            onClick={() => {
              inputRef.current.click?.();
            }}
            content={'파일 업로드'}/>
          <input type={'file'} hidden ref={inputRef} onChange={_handleChangeFile}/>

          <Button
            disabled={data.length < 1}
            loading={loading}
            onClick={_loadBusinessMemberData}
            content={'회원 정보 가져오기'}/>
        </Message>
        <Message>
          <Message.Content>
            <Message.Header>
              STEP 3.
            </Message.Header>
            비활성화를 요청합니다.
          </Message.Content>
          <Divider/>
          <Button
            disabled={data.length < 1}
            loading={loading}
            onClick={_uploadToServer}
            content={'비활성화 하기'}/>
        </Message>
      </Container>
      <Container as={Segment} vertical>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>No.</Table.HeaderCell>
              <Table.HeaderCell width={2}>이름</Table.HeaderCell>
              <Table.HeaderCell width={2}>사번</Table.HeaderCell>
              <Table.HeaderCell> userId </Table.HeaderCell>
              <Table.HeaderCell> 활성상태 </Table.HeaderCell>
              <Table.HeaderCell width={2}> </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              data.map((row, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell>
                      {row.name}
                    </Table.Cell>
                    <Table.Cell>
                      {row.companyIdNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/user/${row?.user?.id}`}>
                        {row?.user?.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {row?.user?.isClosed ? '비활성화' : '활성'}
                    </Table.Cell>
                    <Table.Cell>
                      {/*<Button
                       compact
                       size={'small'}
                       content={'제외'}/>*/}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessMemberRemoveContainer);
