import BusinessBookClubMainContainer from "./BusinessBookClubMainContainer";
import BusinessBookClubDetailContainer from "./BusinessBookClubDetailContainer";
import BusinessBookClubMakeOrModifyContainer from "./BusinessBookClubMakeOrModifyContainer";


const businessBookClubContainers = [
  // { path: "/business-book-category", component: BusinessBookCategoryMainContainer },
   { path: "/business-book-club", component: BusinessBookClubMainContainer },
   { path: "/business-book-club/make", component: BusinessBookClubMakeOrModifyContainer },
   { path: "/business-book-club/:businessBookClubId", component: BusinessBookClubDetailContainer },
   { path: "/business-book-club/:businessBookClubId/edit", component: BusinessBookClubMakeOrModifyContainer },
]

export default businessBookClubContainers
