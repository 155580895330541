import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import Network from '../../library/Network';
import {useParams} from 'react-router';
import {Button, Container, Header, Message, Segment, Tab, Table} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import moment from 'moment';

const UserBusinessBookRequestListContainer = (props) => {

  const {userId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false)
  const [meta, setMeta] = useState({})
  const [businessBookRequests, setBusinessBookRequests] = useState([])

  const _load = async () => {
    const res = await Network
      .businessBookRequestListUserGET(
        {
          userId,
          offset: 0,
          limit: 100,
        }
      )
    if (!res.err) {
      setIsLoaded(true);
      setBusinessBookRequests(res.businessBookRequests);
      setMeta(res.meta || {});
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])

  if (!isLoaded) return <LoaderComponent/>

  return (
    <>
      <Container as={Segment} vertical>
        <Header></Header>
        <Button
          onClick={_load}
          icon={'refresh'}
        />
        <Message>
          <Message.Content>
            {meta.total}건의 도서 신청 내역이 있습니다.
          </Message.Content>
        </Message>
      </Container>
      <Container as={Segment} vertical>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>상태</Table.HeaderCell>
              <Table.HeaderCell>책</Table.HeaderCell>
              <Table.HeaderCell>시간</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              businessBookRequests?.map((bbr, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {i + 1}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/business-book-request/${bbr.id}`}>
                        {bbr.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {bbr.businessBookRequestStatus?.text}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/book/${bbr.book?.id}`}>
                        {bbr.book?.title}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {moment(bbr.createdAt).format('YYYY.MM.DD(ddd)HH:mm')}
                    </Table.Cell>
                  </Table.Row>
                )
              })
              }
              </Table.Body>
              </Table>
              </Container>
              </>
              )
            }

            const enhance = connect(
            state => ({
            ...state
          }),
            {}
            );

            export default enhance(UserBusinessBookRequestListContainer);
