import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {Button, Card, Container, Form, Grid, Header, Segment, Table} from "semantic-ui-react";
import {Bar} from "react-chartjs-2";
import {useQuery} from "../../library/useQuery";
import moment from "moment";
import numeral from "numeral";
import _ from "lodash";
import LoaderComponent from "../../components/shared/LoaderComponent";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {useHistory} from "react-router";
import Truncate from "react-truncate";
import {Link} from "react-router-dom";
import Colors from "../../constants/Colors";
import Network from '../../library/Network';

const BusinessBookRequestMainScreen = (props) => {

  const history = useHistory();
  let {startDate, endDate, unit} = useQuery();
  unit = !!unit ? unit : "day";
  startDate = !!startDate ? startDate : moment().startOf("month").toISOString();
  endDate = !!endDate ? endDate : moment().endOf("month").toISOString();

  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [numberOfBookRequestsGroupByDate, setNumberOfBookRequestsGroupByDate] = useState([]);
  const [sumOfBookRequestsSalesGroupByDate, setSumOfBookRequestsSalesGroupByDate] = useState([]);
  const [booksPopular, setBookPopular] = useState([]);
  const [businessBookRequestsRecent, setBusinessBookRequestsRecent] = useState([]);

  const _load = async () => {
    const res = await Network.businessBookRequestMainGET({
      startDate: startDate,
      endDate: endDate,
      unit,
    });
    if (!res.err) {
      setIsLoaded(true);
      setNumberOfBookRequestsGroupByDate(res.numberOfBookRequestsGroupByDate);
      setSumOfBookRequestsSalesGroupByDate(res.sumOfBookRequestsSalesGroupByDate);
      setBookPopular(res.booksPopular);
      setBusinessBookRequestsRecent(res.businessBookRequestsRecent);
    }
  };

  const _onRefresh = async () => {
    setRefreshing(true);
    await _load();
    setRefreshing(false);


  }


  const _handleChangeDateAndUnit = (startDate, endDate, unit) => {
    history.push(`/business-book-request?startDate=${moment(startDate).toISOString()}&endDate=${moment(endDate).toISOString()}&unit=${unit}`);
  };

  useEffect(() => {
    setNumberOfBookRequestsGroupByDate([]);
    setSumOfBookRequestsSalesGroupByDate([]);
    _load();
  }, [unit, startDate, endDate]);

  // if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        <Header content={"도서 신청 관리"}/>
        <Button
          content={'새로고침'}
          icon={'refresh'}
          disabled={refreshing}
          loading={refreshing}
          onClick={_onRefresh}
        />
        <Form>
          <Form.Group style={{alignItems: "flex-end"}}>
            <Form.Dropdown
              label={"단위"}
              value={unit}
              onChange={(e, {value}) => {
                _handleChangeDateAndUnit(startDate, endDate, value);

              }}
              options={[
                {text: "일", value: "day", key: 0},
                {text: "주", value: "week", key: 1},
                {text: "월", value: "month", key: 2},
              ]}
              selection
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, {value}) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(value, endDate, unit);
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, {value}) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(startDate, value, unit);
                                }}/>
            <Button.Group>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).subtract(1, "month").startOf("month").toISOString(),
                    moment(startDate).subtract(1, "month").endOf("month").toISOString(),
                    unit,
                  );
                }}
                icon={"chevron left"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment().startOf("month").toISOString(),
                    moment().endOf("month").toISOString(),
                    unit,
                  );
                }}
                content={"이번달"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).add(1, "month").startOf("month").toISOString(),
                    moment(startDate).add(1, "month").endOf("month").toISOString(),
                    unit,
                  );
                }}
                icon={"chevron right"}/>
            </Button.Group>
          </Form.Group>
        </Form>
      </Container>
      <Container as={Segment} vertical basic>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"신청건수"}/>
                  <Card.Header content={`${numeral(_.sumBy(numberOfBookRequestsGroupByDate, o => o.count)).format("0,0")} 건`}
                               style={{textAlign: "right"}}/>
                  <Card.Description
                    style={{textAlign: "right"}}
                  >
                    평균 {(_.sumBy(numberOfBookRequestsGroupByDate, o => o.count) / numberOfBookRequestsGroupByDate.length).toFixed(1) || "0"}건
                  </Card.Description>
                  <div style={{}}>
                    <Bar
                      // width={100}
                      height={300}
                      data={{
                        labels: numberOfBookRequestsGroupByDate.map(o => moment(o.day).format("MM.DD (ddd)")),//_.map(numberOfBookRequestsGroupByDate, o => moment(o.day).format('YYYY-MM-DD')),
                        datasets: [{
                          label: `도서 신청 건수`,
                          data: _.map(numberOfBookRequestsGroupByDate, o => o.count),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        scales: {
                          yAxes: [{
                            stacked: true,
                          }],
                          xAxes: [{
                            // stacked: true,
                            type: "time",
                            time: {
                              unit: unit,
                              displayFormats: {
                                day: "MM/DD(ddd)",
                                week: "MM/DD",
                                month: "YYYY-MM",
                              },
                            },
                          }],
                        },
                      }}
                    />
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"신청 금액"}/>
                  <Card.Header content={`${numeral(_.sumBy(sumOfBookRequestsSalesGroupByDate, o => o.sum)).format("0,0")} 원`}
                               style={{textAlign: "right"}}/>
                  <Card.Description
                    style={{textAlign: "right"}}
                  >
                    평균 {numeral(_.sumBy(sumOfBookRequestsSalesGroupByDate, o => o.sum) / sumOfBookRequestsSalesGroupByDate.length).format("0,0") || "0"}원
                  </Card.Description>
                  <div>
                    <Bar
                      data={{
                        labels: _.map(sumOfBookRequestsSalesGroupByDate, o => moment(o.day).format("MM.DD(ddd)")),
                        datasets: [{
                          label: `도서 신청 건수`,
                          data: _.map(sumOfBookRequestsSalesGroupByDate, o => o.sum),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                      }}
                      // width={100}
                      height={300}
                      options={{maintainAspectRatio: false}}
                    />
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"인기 도서"}/>
                  {
                    booksPopular?.map((o, i) => {
                      return (
                        <div key={i} style={{marginTop: "1rem", display: "flex"}}>
                          <div style={{
                            width: "40px",
                            height: "60px",
                            backgroundImage: `url(${o.coverImageUrl})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "40px 60px",
                          }}/>
                          <div style={{flex: 1, marginLeft: "1rem"}}>
                            <Truncate lines={1}>
                              {o.title}
                            </Truncate>
                            <div>
                              <b>{o.count}</b> 건
                            </div>
                            <div style={{marginTop: "0.3rem", fontSize: "0.9rem"}}>
                              <Link to={`/book/${o.id}`}>
                                자세히보기
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column width={10}>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"최근 주문 내역"}/>
                  <Table singleLine style={{width: "100%"}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell content={"id"} width={1}/>
                        <Table.HeaderCell content={"이름"} width={1}/>
                        <Table.HeaderCell content={"책"} width={10}/>
                        <Table.HeaderCell content={"일시"} width={4}/>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        businessBookRequestsRecent?.map((o, i) => {
                          return (
                            <Table.Row key={o.id}>
                              <Table.Cell>
                                <Link as={Link} to={`/business-book-request/${o.id}`}>
                                  {o.id}
                                </Link>
                              </Table.Cell>
                              <Table.Cell>
                                <Link as={Link} to={`/user/${o.user.id}`}>
                                  {o.user?.name}
                                </Link>
                              </Table.Cell>
                              <Table.Cell
                                onClick={() => {
                                  history.push(`/book/${o.book?.id}`);
                                }}
                                style={{maxWidth: "50px", cursor: "pointer", color: "#1F6FBF"}}>
                                <Truncate
                                  lines={1}
                                >
                                  {o.book?.title}
                                </Truncate>
                              </Table.Cell>
                              <Table.Cell>
                                {moment(o.createdAt).format("MM.DD(ddd) HH:mm")}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      }
                    </Table.Body>
                  </Table>
                  <Button basic
                          primary
                          as={Link}
                          to={`/business-book-request/list`}
                          floated={"right"}
                          content={"더보기"}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessBookRequestMainScreen);
