import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Container, Pagination, Segment, Table } from "semantic-ui-react";
import { useQuery } from "../../library/useQuery";
import moment from "moment";
import Truncate from "react-truncate";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Network from '../../library/Network';

const BusinessBookRecommendationCollectionMainContainer = (props) => {

  const history = useHistory();
  let { activePage } = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;

  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [perPage] = useState(50);
  const [businessBookRecommendationCollections, setBusinessBookRecommendationCollections] = useState([]);

  const _load = async () => {
    const res = await Network.businessBookRecommendationCollectionListGET(
      {
        offset: ( activePage - 1 ) * perPage,
        limit: perPage,
      },
    );
    if (!res.err) {
      setIsLoaded(true);
      setBusinessBookRecommendationCollections(res.businessBookRecommendationCollections);
      setTotalPage(res.meta?.totalPage);
      setTotal(res.meta?.total);
    }
  };

  useEffect(() => {
    setBusinessBookRecommendationCollections([]);
    _load();
  }, [activePage]);

  const _handleChangePage = (activePage) => {
    history.push(`/business-book-recommendation-collection?activePage=${activePage}`);
  };

  return (
    <>
      <Container as={Segment} vertical>
        <Button
          as={Link}
          to={`/business-book-recommendation-collection/make`}
          icon={"plus"}
          content={"추천 도서 리스트 만들기"}
        />
      </Container>
      <Container as={Segment} vertical>
        <Pagination totalPages={totalPage}
                    onPageChange={(e, { activePage }) => {
                      _handleChangePage(activePage);
                    }}/>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={"id"}/>
              <Table.HeaderCell content={"title"}/>
              <Table.HeaderCell content={"발행일"}/>
              <Table.HeaderCell content={"만료일"}/>
              <Table.HeaderCell content={"생성일"}/>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              businessBookRecommendationCollections?.map((o, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {o.id}
                    </Table.Cell>
                    <Table.Cell
                      onClick={() => {
                        history.push(`/business-book-recommendation-collection/${o.id}`);
                      }}
                      style={{
                        maxWidth: "100px",
                        cursor: "pointer",
                        color: "#1F6FBF",
                      }}>
                      <Truncate lines={1}>
                        {o.title}
                      </Truncate>
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.publishedAt).format("YYYY.MM.DD(ddd)HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.expiredAt).format("YYYY.MM.DD(ddd)HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.createdAt).format("YYYY.MM.DD(ddd)HH:mm")}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessBookRecommendationCollectionMainContainer);
