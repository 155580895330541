import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {moveItemInArrayFromIndexToIndex} from '../../library/arrayHandler';
import {Button, Card, Form, Grid, Image, Input, Modal, Table} from 'semantic-ui-react';
import Network from '../../library/Network';
import ScrapComponent from '../scrap/ScrapComponent';

const DraftAddScrapComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [customComponentData, setCustomComponentData] = useState({type: '', items: []});
  const [searching, setSearching] = useState(false);
  const [searchedList, setSearchedList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [meta, setMeta] = useState({});
  const [activePage, setActivePage] = useState(1);

  const _addItem = (item) => {
    const {items} = customComponentData;
    items.push(item);
    setCustomComponentData(prev => ({...prev, items}));
  };

  const _handleMove = (from, to) => {
    const {items} = customComponentData;
    const newItems = moveItemInArrayFromIndexToIndex(items, from, to);
    setCustomComponentData(prev => ({...prev, items: newItems}));
  };

  const _removeItem = (index) => {
    const {items} = customComponentData;
    items.splice(index, 1);
    setCustomComponentData(prev => ({...prev, items}));
  };

  const _handleSearch = async () => {
    if (!keyword || keyword.trim() === '') return window.alert('검색어를 입력해 주세요');
    setSearching(true);
    setSearchedList([]);
    const res = await Network.searchGET(
      {
        keyword,
        offset: 0,
        limit: 50,
        itemType: 'SCRAP',
        // method: 'es',
      },
    );
    if (!res.err) {
      setSearchedList(res.searchedList);
    }
    setSearching(false);
  };

  const _handleComplete = () => {
    props.handleComplete(customComponentData);
  };

  const _handleCancel = () => {
    props.handleCancel();
  };

  const _onInputKeyDown = (e) => {
    if (e.which === 13) {
      _handleSearch();
    }
  };

  return (
    <Modal
      open={props.open}
      size={'large'}
    >
      <Modal.Header>
        스크랩 추가
      </Modal.Header>
      <Modal.Content style={{maxHeight: '20vh', minHeight: '20vh'}} scrolling>
        <Card.Group itemsPerRow={4}>
          {
            customComponentData?.items?.map((o, i) => {
              return (
                <Card key={i}>
                  {
                    o.images?.length > 0 && (
                      <Image src={o.images[0].url}/>
                    )
                  }
                  <Card.Content>
                    <Button.Group size={'mini'}>
                      <Button
                        disabled={i === 0}
                        onClick={() => _handleMove(i, i - 1)}
                        icon={'chevron up'}/>
                      <Button
                        disabled={i === customComponentData.items?.length - 1}
                        onClick={() => _handleMove(i, i + 1)}
                        icon={'chevron down'}/>
                      <Button
                        onClick={() => _removeItem(i)}
                        icon={'remove'}/>
                    </Button.Group>
                  </Card.Content>
                </Card>
              );
            })
          }
        </Card.Group>
      </Modal.Content>
      <Modal.Content
      >
        <Input
          onKeyDown={_onInputKeyDown}
          onChange={(e, {value}) => {
            setKeyword(value);
          }}
          fluid
          action
        >
          <input/>
          <Button
            loading={searching}
            disabled={searching}
            onClick={_handleSearch}
            icon={'search'}/>
        </Input>
      </Modal.Content>
      <Modal.Content style={{minHeight: '50vh'}} scrolling>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <Card.Group itemsPerRow={3}>
                {searchedList?.map((o, i) => {
                  return (
                    <ScrapComponent
                      scrap={o}>
                      <Card.Content>
                        <Button
                          onClick={() => _addItem(o)}
                          size={'mini'}
                          icon={'add'}/>
                      </Card.Content>
                    </ScrapComponent>
                  );
                })}
              </Card.Group>

            </Grid.Column>
            <Grid.Column width={4}>

            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button content={'취소'} onClick={_handleCancel}/>
        <Button content={'추가'} onClick={_handleComplete}/>
      </Modal.Actions>
    </Modal>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(DraftAddScrapComponent);
