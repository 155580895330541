import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import { Button, Card, Image, Modal } from 'semantic-ui-react';
import DragAndDropFileUploaderComponent from '../shared/DragAndDropFileUploaderComponent';
import { moveItemInArrayFromIndexToIndex } from '../../library/arrayHandler';

const DraftAddPhotoComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [customComponentData, setCustomComponentData] = useState({ type: '', items: [] });

  useEffect(() => {
  }, []);

  const _addItem = (item) => {
    const { items } = customComponentData;
    items.push(item);
    setCustomComponentData(prev => ( { ...prev, items } ));
  };

  const _handleMove = (from, to) => {
    const { items } = customComponentData;
    const newItems = moveItemInArrayFromIndexToIndex(items, from, to);
    setCustomComponentData(prev => ( { ...prev, items: newItems } ));
  };

  const _removeItem = (index) => {
    const { items } = customComponentData;
    items.splice(index, 1);
    setCustomComponentData(prev => ( { ...prev, items } ));
  };

  const _handleComplete = () => {
    props.handleComplete(customComponentData);
    setCustomComponentData({ type: '', items: [] });
  };
  const _handleCancel = () => {
    props.handleCancel();
    setCustomComponentData({ type: '', items: [] });
  };

  return (
    <Modal
      open={props.open}
    >
      {/*<Modal.Header>
       이미지 추가하기
       </Modal.Header>*/}
      <Modal.Content style={{ minHeight: '50vh' }} scrolling>
        <Card.Group itemsPerRow={5}>
          {
            customComponentData.items?.map(( (o, index) => {
              return (
                <Card
                  key={index}
                >
                  <Image src={o.url}/>
                  <Button.Group fluid attached={'bottom'} size={'mini'}>
                    <Button
                      onClick={() => _handleMove(index, index - 1)}
                      disabled={index === 0}
                      icon={'chevron left'}/>
                    <Button
                      onClick={() => _handleMove(index, index + 1)}
                      disabled={index === customComponentData.items?.length - 1}
                      icon={'chevron right'}/>
                    <Button
                      onClick={() => _removeItem(index)}
                      icon={'remove'}
                      negative/>
                  </Button.Group>
                </Card>
              );
            } ))
          }
          <Card style={{ minHeight: '200px' }}>
            <DragAndDropFileUploaderComponent
              text={'Drag&Drop / Click'}
              multipleEnabled={true}
              onStart={() => {
                console.log('시작');
              }}
              handleCompleteEach={({ url, size }) => {
                console.log(url);
                // setBookClubCategory(prev => ( { ...prev, imageUrl: url } ))
                _addItem({ url: url, ...size });
              }}
            />
          </Card>
        </Card.Group>

      </Modal.Content>
      <Modal.Actions>
        <Button content={'취소'} onClick={_handleCancel}/>
        <Button content={'추가'} onClick={_handleComplete}/>
      </Modal.Actions>
    </Modal>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(DraftAddPhotoComponent);
