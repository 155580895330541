import BusinessMemberMainContainer from './BusinessMemberMainContainer';
import BusinessMemberAddContainer from './BusinessMemberAddContainer';
import BusinessMemberRemoveContainer from './BusinessMemeberRemoveContainer';


const businessMemberContainers = [
  {path: "/business-member", component: BusinessMemberMainContainer},
  {path: "/business-member/add", component: BusinessMemberAddContainer},
  {path: "/business-member/remove", component: BusinessMemberRemoveContainer},
]


export default businessMemberContainers;
