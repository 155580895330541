import UserDetailContainer from "./UserDetailContainer";
import UserSmsHistoryListContainer from "./UserSmsHistoryListContainer";
import UserInquiryListContainer from "./UserInquiryListContainer";
import UserBusinessBookRequestListContainer from './UserBusinessBookRequestListContainer';
import UserBusinessLearningCreditContainer from './UserBusinessLearningCreditContainer';

const userContainers = [
  {path: "/user/:userId", component: UserDetailContainer},
  {path: "/user/:userId/sms-history", component: UserSmsHistoryListContainer},
  {path: "/user/:userId/inquiry", component: UserInquiryListContainer},
  {path: "/user/:userId/business-book-request", component: UserBusinessBookRequestListContainer},
  {path: "/user/:userId/business-learning-credit", component: UserBusinessLearningCreditContainer},
]

export default userContainers
