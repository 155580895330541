import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Network from "../../library/Network";
import {useQuery} from "../../library/useQuery";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Button, Container, Form, Header, Message, Pagination, Segment, Table} from "semantic-ui-react";
import {useHistory} from "react-router";

const BusinessBookCategoryDisabledListContainer = (props) => {

  let {activePage, perPage, keyword} = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;
  keyword = !!keyword ? keyword : '';

  const history = useHistory();
  const [searchValue, setSearchValue] = useState(keyword);
  const [isLoaded, setIsLoaded] = useState(false)
  const [meta, setMeta] = useState({})
  const [businessBookCategoriesDisabled, setBusinessBookCategoriesDisabled] = useState([])

  const _load = async () => {
    if (!!keyword) {

      const res = await Network.businessBookCategoryDisabledSearchGET(
        {
          keyword,
          offset: (activePage - 1) * perPage,
          limit: perPage,
        }
      )
      if (!res.err) {
        setBusinessBookCategoriesDisabled(res.businessBookCategoriesDisabled);
        setMeta(res.meta || {})
        setIsLoaded(true);
      }


    } else {
      const res = await Network.businessBookCategoryDisabledListGET(
        {
          offset: (activePage - 1) * perPage,
          limit: perPage,
        },
      )
      if (!res.err) {
        setBusinessBookCategoriesDisabled(res.businessBookCategoriesDisabled);
        setMeta(res.meta || {})
        setIsLoaded(true);
      }

    }
  }

  const _handleChangeQuery = (
    {
      newActivePage = activePage,
      newPerPage = perPage,
      newKeyword = keyword,
    },
  ) => {
    history.push(`/business-book-category/list/disabled?activePage=${newActivePage}&perPage=${newPerPage}&keyword=${newKeyword}`)
  }

  useEffect(() => {
    _load();
  }, [activePage, perPage, keyword])


  if (!isLoaded) return <LoaderComponent/>;
  return (
    <>
      <Container as={Segment} vertical>
        <Header content={'신청불가 카테고리 관리'}/>
        <Message>
          <Message.Content>
            {
              !!keyword ?
                `${keyword}로 ${meta?.total}개의 결과가 있습니다.`
                :
                `총 ${meta.total}개가 있습니다. `
            }

          </Message.Content>
        </Message>
        <Form>
          <Form.Input
            action
            value={searchValue}
            onChange={(e, {value}) => {
              setSearchValue(value);
            }}
          >
            <input/>
            <Button
              onClick={(event => {
                _handleChangeQuery({newKeyword: searchValue})
              })}
              icon={'search'}/>
          </Form.Input>
        </Form>
      </Container>
      <Container as={Segment} vertical>
        <Pagination
          activePage={activePage}
          totalPages={meta?.totalPage}
          onPageChange={(e, {activePage}) => {
            _handleChangeQuery({newActivePage: activePage});
          }}
        />
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>bookCategoryId</Table.HeaderCell>
              <Table.HeaderCell>mall</Table.HeaderCell>
              <Table.HeaderCell>카테고리명</Table.HeaderCell>
              <Table.HeaderCell>Depth1</Table.HeaderCell>
              <Table.HeaderCell>Depth2</Table.HeaderCell>
              <Table.HeaderCell>Depth3</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {businessBookCategoriesDisabled.map((o, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>{(activePage - 1) * perPage + i + 1}</Table.Cell>
                  <Table.Cell>{o.id}</Table.Cell>
                  <Table.Cell>{o.bookCategoryId}</Table.Cell>
                  <Table.Cell>{o.bookCategory?.mall}</Table.Cell>
                  <Table.Cell>{o.bookCategory?.aladinCategoryName}</Table.Cell>
                  <Table.Cell>{o.bookCategory?.depth_1}</Table.Cell>
                  <Table.Cell>{o.bookCategory?.depth_2}</Table.Cell>
                  <Table.Cell>{o.bookCategory?.depth_3}</Table.Cell>
                </Table.Row>
              )
            })}

          </Table.Body>
        </Table>
      </Container>

    </>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessBookCategoryDisabledListContainer);
