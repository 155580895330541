import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {useHistory, useParams} from "react-router";
import {useQuery} from "../../library/useQuery";
import moment from "moment";
import Network from '../../library/Network';

const BusinessNoticeDetailContainer = (props) => {

  const history = useHistory();
  const {businessNoticeId} = useParams();

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {
    const res = await Network.businessNoticeGET(
      {
        businessNoticeId
      }
    )
  }

  useEffect(() => {
    _load();
  }, [])

  return (
    <div>
      BusinessNoticeDetailContainer
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessNoticeDetailContainer);
