const getTitleWithoutSubTitle = ({ title, subTitle, book }) => {
  if(!!book){
    if (!book.subTitle || book.subTitle === '') return book.title;
    return book.title.substring(book.title, book.title.indexOf(` - ${book.subTitle}`)).trim();
  } else if (!!title && !!subTitle){
    if (!subTitle || subTitle === '') return title;
    return title.substring(title, title.indexOf(` - ${subTitle}`)).trim();
  } else {
    return title;
  }

};

export default getTitleWithoutSubTitle;
