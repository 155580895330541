import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Container, Segment} from "semantic-ui-react";
import Colors from "../../constants/Colors";
import {authActionCreators} from "../../redux/auth/authStore";

const MyPageContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  async function signOut() {
    const a = window.confirm('로그아웃 하시겠습니까?')
    if (!a) return;

    props.signOut()
  }

  return (
    <>
      <Segment inverted vertical style={{backgroundColor: Colors.mainColor}}>

      </Segment>
      <Segment vertical>
        <Container>
          <Button content={'로그아웃'} onClick={signOut}/>
        </Container>
      </Segment>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {
    ...authActionCreators
  }
);

export default enhance(MyPageContainer);
