import BookCategoryDetailContainer from "./BookCategoryDetailContainer";
import BookCategoryListContainer from "./BookCategoryListContainer";


const bookCategoryContainers = [
  { path: "/book-category/list", component: BookCategoryListContainer },
  { path: '/book-category/:bookCategoryId', component: BookCategoryDetailContainer },
]

export default bookCategoryContainers;
