import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from "react-router";
import {useQuery} from "../../library/useQuery";
import {Button} from "semantic-ui-react";
import {Link} from "react-router-dom";

const BusinessBookClubDetailInfoTab = (props) => {

  const {businessBookClubId} = useParams();
  let {activePage, perPage, keyword} = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 10;


  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  const _load = async () => {

  }

  useEffect(() => {
    _load();
  },[])

  return (
    <div>
      BusinessBookClubDetailInfoTab {businessBookClubId}
      <Button
      as={Link}
      to={`/business-book-club/${businessBookClubId}/edit`}
      />
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessBookClubDetailInfoTab);
