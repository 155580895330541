import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Card, Container, Form, Grid, Header, Segment } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import { useHistory } from "react-router";
import { useQuery } from "../../library/useQuery";
import { Bar, Line } from "react-chartjs-2";
import _ from "lodash";
import numeral from "numeral";
import LoaderComponent from "../../components/shared/LoaderComponent";
import Network from '../../library/Network';

const DashboardMainContainer = (props) => {

  const history = useHistory();
  let { startDate, endDate, unit } = useQuery();
  unit = !!unit ? unit : "day";
  startDate = !!startDate ? startDate : moment().subtract(20, "day").startOf("day").toISOString();
  endDate = !!endDate ? endDate : moment().endOf("day").toISOString();

  const [isLoaded, setIsLoaded] = useState(false);
  const [numberOfScrapsGroupByDate, setNumberOfScrapsGroupByDate] = useState([]);
  const [numberOfRelationUserScrapLikesGroupByDate, setNumberOfRelationUserScrapLikesGroupByDate] = useState([]);
  const [numberOfUserActiveGroupByDate, setNumberOfUserActiveGroupByDate] = useState([]);
  const [numberOfScrapCommentsGroupByDate, setNumberOfScrapCommentsGroupByDate] = useState([]);
  const [numberOfBookRequestsGroupByDate, setNumberOfBookRequestsGroupByDate] = useState([]);


  const _handleChangeDateAndUnit = (startDate, endDate, unit) => {
    history.push(`/dashboard?startDate=${moment(startDate).toISOString()}&endDate=${moment(endDate).toISOString()}&unit=${unit}`);
  };

  const _load = async () => {
    const res = await Network.dashboardGET(
      { startDate, endDate, unit },
    );
    if (!res.err) {
      setIsLoaded(true);
      setNumberOfScrapsGroupByDate(res.numberOfScrapsGroupByDate);
      setNumberOfUserActiveGroupByDate(res.numberOfUserActiveGroupByDate);
      setNumberOfRelationUserScrapLikesGroupByDate(res.numberOfRelationUserScrapLikesGroupByDate);
      setNumberOfScrapCommentsGroupByDate(res.numberOfScrapCommentsGroupByDate);
      setNumberOfBookRequestsGroupByDate(res.numberOfBookRequestsGroupByDate);
    }
  };

  useEffect(() => {
    _load();
  }, [startDate, endDate, unit]);

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        <Header content={"이용 통계"}/>
        <Form>
          <Form.Group style={{ alignItems: "flex-end" }}>
            <Form.Dropdown
              label={"단위"}
              value={unit}
              onChange={(e, { value }) => {
                _handleChangeDateAndUnit(startDate, endDate, value);

              }}
              options={[
                { text: "일", value: "day", key: 0 },
                { text: "주", value: "week", key: 1 },
                { text: "월", value: "month", key: 2 },
              ]}
              selection
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(value, endDate, unit);
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(startDate, value, unit);
                                }}/>
            <Button.Group>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).subtract(1, "month").startOf("month").toISOString(),
                    moment(startDate).subtract(1, "month").endOf("month").toISOString(),
                    unit
                  );
                }}
                icon={"chevron left"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment().startOf("month").toISOString(),
                    moment().endOf("month").toISOString(),
                    unit
                  );
                }}
                content={"이번달"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).add(1, "month").startOf("month").toISOString(),
                    moment(startDate).add(1, "month").endOf("month").toISOString(),
                    unit
                  );
                }}
                icon={"chevron right"}/>
            </Button.Group>
          </Form.Group>
        </Form>
      </Container>
      <Container as={Segment} vertical basic>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid color={"green"}>
                <Card.Content>
                  <Card.Header content={"활성유저"}/>
                  <Card.Header
                    content={`${numeral(_.sumBy(numberOfUserActiveGroupByDate, o => o.count)).format("0,0")}`}
                    textAlign={"right"}
                    style={{ color: "green" }}
                  />
                  <Card.Description
                    style={{ textAlign: "right" }}
                  >
                    {numeral(( _.sumBy(numberOfUserActiveGroupByDate, o => o.count) ) / ( numberOfUserActiveGroupByDate?.length )).format("0,0")} 명/{unit}
                  </Card.Description>
                  <div>
                    <Bar
                      height={300}
                      data={{
                        labels: numberOfUserActiveGroupByDate.map(o => moment(o.day).format("MM.DD")),
                        datasets: [{
                          label: `활성유저`,
                          data: numberOfUserActiveGroupByDate.map(o => o.count),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                            labels: {
                              color: "green",
                            },
                          },
                        },
                        scales: {
                          y: {
                            stacked: true,
                            suggestedMin: 0,
                          },
                          xAxes: [{
                            // stacked: true,
                            type: "time",
                            time: {
                              unit: unit,
                              displayFormats: {
                                day: "MM/DD(ddd)",
                                week: "MM/DD",
                                month: "YYYY-MM",
                              },
                            },
                          }],
                        },
                      }}
                    />
                  </div>

                </Card.Content>
                <Card.Content extra>
                  <Button content={"더보기"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"스크랩"}/>
                  <Card.Header
                    content={`${numeral(_.sumBy(numberOfScrapsGroupByDate, o => o.count)).format("0,0")}`}
                    textAlign={"right"}
                    style={{ color: "green" }}
                  />
                  <Card.Description
                    style={{ textAlign: "right" }}
                  >
                    {numeral(( _.sumBy(numberOfScrapsGroupByDate, o => o.count) ) / ( numberOfScrapsGroupByDate?.length )).format("0,0")} 개/{unit}
                  </Card.Description>
                  <div>
                    <Bar
                      height={300}
                      data={{
                        labels: numberOfScrapsGroupByDate.map(o => moment(o.day).format("MM.DD")),
                        datasets: [{
                          label: `스크랩`,
                          data: numberOfScrapsGroupByDate.map(o => o.count),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                            labels: {
                              color: "green",
                            },
                          },
                        },
                        scales: {
                          y: {
                            stacked: true,
                            suggestedMin: 0,
                          },
                          xAxes: [{
                            // stacked: true,
                            type: "time",
                            time: {
                              unit: unit,
                              displayFormats: {
                                day: "MM/DD(ddd)",
                                week: "MM/DD",
                                month: "YYYY-MM",
                              },
                            },
                          }],
                        },
                      }}
                    />
                  </div>

                </Card.Content>
                <Card.Content extra>
                  <Button content={"더보기"}/>
                </Card.Content>
              </Card>
            </Grid.Column>

          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"스크랩 좋아요"}/>
                  <Card.Header
                    content={`${numeral(_.sumBy(numberOfRelationUserScrapLikesGroupByDate, o => o.count)).format("0,0")}`}
                    textAlign={"right"}
                    style={{ color: "green" }}
                  />
                  <Card.Description
                    style={{ textAlign: "right" }}
                  >
                    {numeral(( _.sumBy(numberOfRelationUserScrapLikesGroupByDate, o => o.count) ) / ( numberOfRelationUserScrapLikesGroupByDate?.length )).format("0,0")} 개/{unit}
                  </Card.Description>
                  <div>
                    <Bar
                      height={300}
                      data={{
                        labels: numberOfRelationUserScrapLikesGroupByDate.map(o => moment(o.day).format("MM.DD")),
                        datasets: [{
                          label: `스크랩`,
                          data: numberOfRelationUserScrapLikesGroupByDate.map(o => o.count),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                            labels: {
                              color: "green",
                            },
                          },
                        },
                        scales: {
                          y: {
                            stacked: true,
                            suggestedMin: 0,
                          },
                          xAxes: [{
                            // stacked: true,
                            type: "time",
                            time: {
                              unit: unit,
                              displayFormats: {
                                day: "MM/DD(ddd)",
                                week: "MM/DD",
                                month: "YYYY-MM",
                              },
                            },
                          }],
                        },
                      }}
                    />
                  </div>

                </Card.Content>
                <Card.Content extra>
                  <Button content={"더보기"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"스크랩 댓글"}/>
                  <Card.Header
                    content={`${numeral(_.sumBy(numberOfScrapCommentsGroupByDate, o => o.count)).format("0,0")}`}
                    textAlign={"right"}
                    style={{ color: "green" }}
                  />
                  <Card.Description
                    style={{ textAlign: "right" }}
                  >
                    {numeral(( _.sumBy(numberOfScrapCommentsGroupByDate, o => o.count) ) / ( numberOfScrapCommentsGroupByDate?.length )).format("0,0")} 개/{unit}
                  </Card.Description>
                  <div>
                    <Bar
                      height={300}
                      data={{
                        labels: numberOfScrapCommentsGroupByDate.map(o => moment(o.day).format("MM.DD")),
                        datasets: [{
                          label: `스크랩 댓글`,
                          data: numberOfScrapCommentsGroupByDate.map(o => o.count),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                            labels: {
                              color: "green",
                            },
                          },
                        },
                        scales: {
                          y: {
                            stacked: true,
                            suggestedMin: 0,
                          },
                          xAxes: [{
                            // stacked: true,
                            type: "time",
                            time: {
                              unit: unit,
                              displayFormats: {
                                day: "MM/DD(ddd)",
                                week: "MM/DD",
                                month: "YYYY-MM",
                              },
                            },
                          }],
                        },
                      }}
                    />
                  </div>

                </Card.Content>
                <Card.Content extra>
                  <Button content={"더보기"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"도서 신청"}/>
                  <Card.Header
                    content={`${numeral(_.sumBy(numberOfBookRequestsGroupByDate, o => o.count)).format("0,0")}`}
                    textAlign={"right"}
                    style={{ color: "green" }}
                  />
                  <Card.Description
                    style={{ textAlign: "right" }}
                  >
                    {numeral(( _.sumBy(numberOfBookRequestsGroupByDate, o => o.count) ) / ( numberOfBookRequestsGroupByDate?.length )).format("0,0")} 개/{unit}
                  </Card.Description>
                  <div>
                    <Bar
                      height={300}
                      data={{
                        labels: numberOfBookRequestsGroupByDate.map(o => moment(o.day).format("MM.DD")),
                        datasets: [{
                          label: `스크랩 댓글`,
                          data: numberOfBookRequestsGroupByDate.map(o => o.count),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                            labels: {
                              color: "green",
                            },
                          },
                        },
                        scales: {
                          y: {
                            stacked: true,
                            suggestedMin: 0,
                          },
                          xAxes: [{
                            // stacked: true,
                            type: "time",
                            time: {
                              unit: unit,
                              displayFormats: {
                                day: "MM/DD(ddd)",
                                week: "MM/DD",
                                month: "YYYY-MM",
                              },
                            },
                          }],
                        },
                      }}
                    />
                  </div>

                </Card.Content>
                <Card.Content extra>
                  <Button content={"더보기"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"독서토론회 개설"}/>
                  <Card.Header content={`1,305`} textAlign={"right"} style={{ color: "green" }}/>
                  <div>
                    <Line
                      height={300}
                      data={{
                        labels: ["1", "2", "3", "4"],
                        datasets: [{
                          label: `도서 신청 건수`,
                          data: [1, 2, 3, 4],
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                            labels: {
                              color: "green",
                            },
                          },
                        },
                        scales: {
                          yAxes: [{
                            stacked: true,
                          }],
                          xAxes: [{
                            // stacked: true,
                            type: "time",
                            time: {
                              unit: unit,
                              displayFormats: {
                                day: "MM/DD(ddd)",
                                week: "MM/DD",
                                month: "YYYY-MM",
                              },
                            },
                          }],
                        },
                      }}
                    />
                  </div>
                </Card.Content>
                <Card.Content extra>
                  <Button content={"더보기"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container as={Segment} vertical>

      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(DashboardMainContainer);
