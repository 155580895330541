import BusinessContentMainContainer from './BusinessContentMainContainer';
import BusinessContentListContainer from './BusinessContentListContainer';
import BusinessContentDetailContainer from './BusinessContentDetailContainer';

const businessContentContainers = [
  {path: "/business-content", component: BusinessContentMainContainer},
  {path: "/business-content/list", component: BusinessContentListContainer},
  {path: "/business-content/write", component: BusinessContentDetailContainer},
  {path: "/business-content/:businessContentId", component: BusinessContentDetailContainer},
]

export default businessContentContainers
