import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button,Divider, Container, Form, Modal, Segment, Card, Image, Input, Table} from "semantic-ui-react";
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {useHistory, useParams} from "react-router";
import Network from "../../library/Network";
import LoaderComponent from "../../components/shared/LoaderComponent";
import removeSubTitle from "../../library/removeSubTitle";

const BusinessBookClubMakeOrModifyContainer = (props) => {

  const {businessBookClubId} = useParams();
  const history = useHistory();
  const isNew = !businessBookClubId;
  const [isLoaded, setIsLoaded] = useState(false)
  const [bookSearchModalOpen, setBookSearchModalOpen] = useState(false);
  const [searching, setSearching] = useState(false)
  const [searchedList, setSearchedList] = useState([])
  const [uploading, setUploading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [businessBookClub, setBusinessBookClub] = useState({
    title: '',
    description: '',
    startDate: moment().add(1, 'week').startOf('week').toISOString(),
    isbn13: '',
    isPublic: false,
  })

  const _load = async () => {
    if (!isNew) {
      const res = await Network
        .businessBookClubGET({businessBookClubId})
      if (!res.err) {
        setBusinessBookClub(res.businessBookClub);
        setIsLoaded(true);
      }
    }else {
      setIsLoaded(true);
    }
  }
  const _upload = async () => {
    if (uploading) return;
    if (!window.confirm('업로드하시게습니까?')) return;
    setUploading(true);
    if (isNew) {
      const res = await Network
        .businessBookClubPOST({
          ...businessBookClub,
        })
      setUploading(false);
      if (!res.err) {
        history.replace(`/business-book-club/${res.businessBookClub?.id}`)
      }
    } else {
      const res = await Network
        .businessBookClubPUT({
          businessBookClubId,
          ...businessBookClub,
        })
      setUploading(false);
    }
  }

  const _handleSearchBook = async () => {
    if (searching) return;
    if (!keyword) return window.alert('검색어를 입력해 주세요')
    setSearching(true);
    const res = await Network.searchGET(
      {
        itemType: "BOOK",
        keyword,
        offset: 0,
        limit: 50,
        // aladinCategoryId: null,
        // method: "es",
      },
    )
    setSearching(false);
    if (!res.err) {
      setIsLoaded(true);
      setSearchedList(res.searchedList);
    }
  }


  useEffect(() => {
    _load();
  }, [])

  if (!isLoaded) return <LoaderComponent/>;

  const _renderSearchModal = () => {
    return (
      <Modal open={bookSearchModalOpen}>
        <Modal.Header>
          책 검색
        </Modal.Header>
        <Modal.Content>
          <Input
            disabled={searching}
            onKeyDown={(e) => {
              if (e.which === 13) {
                _handleSearchBook();
              }
            }}
            value={keyword}
            action
            fluid
            onChange={(e, {value}) => {
              setKeyword(value)
            }}
          >
            <input/>
            <Button
              loading={searching}
              onClick={_handleSearchBook}
              icon={'search'}
            />
          </Input>
        </Modal.Content>
        <Modal.Content style={{minHeight: '50vh'}} scrolling>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>image</Table.HeaderCell>
                <Table.HeaderCell>title</Table.HeaderCell>
                <Table.HeaderCell>author</Table.HeaderCell>
                <Table.HeaderCell>isbn13</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                searchedList.map(( o => {
                  return (
                    <Table.Row key={o.id}>
                      <Table.Cell>{o.id}</Table.Cell>
                      <Table.Cell>
                        <Image size={'mini'} src={o.coverImageUrl}/>
                      </Table.Cell>
                      <Table.Cell>{removeSubTitle({book: o})}</Table.Cell>
                      <Table.Cell>{o.author}</Table.Cell>
                      <Table.Cell>{o.isbn13}</Table.Cell>
                      <Table.Cell>
                        <Button
                          onClick={() => {
                            setBusinessBookClub(prev => ( {...prev, isbn13: o.isbn13, book: o} ))
                            setBookSearchModalOpen(false);
                            setKeyword('');
                            setSearching(false);
                            setSearchedList([]);
                          }}
                          size={'mini'}
                          content={'선택'}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                } ))
              }
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => setBookSearchModalOpen(false)}
            content={'취소'}/>
          <Button content={'반영'}/>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <div>
      {_renderSearchModal()}
      <Container as={Segment} vertical>
        <Form>
          <Form.Input
            name={"title"}
            label={"title"}
            value={businessBookClub.title}
            onChange={(e, {value, name}) => {
              setBusinessBookClub(prev => ( {...prev, [name]: value} ));
            }}
          />
          <Form.TextArea
            name={'description'}
            rows={10}
            label={"description"}
            value={businessBookClub.description}
            onChange={(e, {value, name}) => {
              setBusinessBookClub(prev => ( {...prev, [name]: value} ));
            }}
          />
          <SemanticDatepicker locale={"ko-KR"}
                              label={"시작일"}
                              fluid
                              clearable={false}
                              value={moment(businessBookClub.startDate).toDate()}
                              onChange={(e, {value}) => {
                                if (!value) return;
                                setBusinessBookClub(prev => ( {...prev, startDate: moment(value).startOf('day').toISOString()} ))
                              }}/>
          <Form.Radio
            toggle
            label={'공개'}
            checked={businessBookClub.isPublic}
            onChange={(e, {checked}) => {
              setBusinessBookClub(prev => ( {...prev, isPublic: checked} ))
            }}
          />
          {
            !!businessBookClub.book && (
              <Card>
                <Card.Content textAlign={'center'}>
                  <Image
                    size={'tiny'}
                    src={businessBookClub.book?.coverImageUrl}
                  />
                </Card.Content>
                <Card.Content extra>
                  <Card.Header
                    content={businessBookClub.book?.title}
                  />
                </Card.Content>
              </Card>
            )
          }
          <Button
            onClick={() => setBookSearchModalOpen(true)}
            content={'변경'}
          />
        </Form>
        <Divider/>
        <Button
          positive
          loading={uploading}
          disabled={uploading}
          onClick={_upload}
          content={isNew ? '만들기' : '수정'}
        />
      </Container>
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessBookClubMakeOrModifyContainer);
