import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import {useParams} from 'react-router';
import Network from '../../library/Network';
import {Button, Container, Form, Header, Image, Segment} from 'semantic-ui-react';
import removeSubTitle from '../../library/removeSubTitle';
import {Link} from 'react-router-dom';

const BusinessBookDisabledDetailContainer = (props) => {

  const {businessBookDisabledId} = useParams();
  const [isLoaded, setIsLoaded] = useState(false)
  const [businessBookDisabled, setBusinessBookDisabled] = useState({})

  const _load = async () => {
    const res = await Network.businessBookDisabledGET(
      {
        businessBookDisabledId,
      }
    )
    if (!res.err) {
      setBusinessBookDisabled(res.businessBookDisabled || {})
      setIsLoaded(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    _load();
  }, [])

  if (!isLoaded) return <LoaderComponent/>

  return (
    <>
      <Container as={Segment} vertical>
        <Image
          style={{border: '1px solid black', marginTop: '1rem'}}
          src={businessBookDisabled.book?.coverImageUrl}
          size={'small'}/>
        <Header>
          {removeSubTitle({book: businessBookDisabled.book})}
          <Header.Subheader>
            <Link to={`/book/${businessBookDisabled.bookId}`}>
              책 상세정보 보기
            </Link>
          </Header.Subheader>
        </Header>
        <Form>
          <Form.TextArea
            rows={7}
            label={'코멘트'}
            onChange={(e, {value}) => {
              setBusinessBookDisabled(prev => ({...prev, comment: value}))
            }}
          />
          <Form.Button
            content={'수정하기'}
          />
        </Form>
      </Container>
      <Container as={Segment} vertical>


      </Container>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessBookDisabledDetailContainer);
