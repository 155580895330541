import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { DRAFT_ENTITY_TYPE_BOOKS, DRAFT_ENTITY_TYPE_BUSINESS_BOOK_FORUMS, DRAFT_ENTITY_TYPE_PHOTOS, DRAFT_ENTITY_TYPE_SCRAPS, DRAFT_ENTITY_TYPE_USERS } from "../../constants/draftEntityType";
import { Card, Image } from "semantic-ui-react";
import Truncate from "react-truncate";

const DraftMediaComponent = (props) => {

  const { block, contentState } = props;
  const { foo } = props.blockProps;
  const entity = contentState?.getEntity(block?.getEntityAt?.(0));
  const data = entity.getData();
  const type = entity.getType();


  useEffect(() => {

  });

  switch (type) {
    case DRAFT_ENTITY_TYPE_USERS:
      return (
        <div style={{
          borderRadius: "0.5rem",
          // border: "1px solid black",
          padding: "1rem",
          backgroundColor: "lightgrey",
        }}>
          {data?.items?.map((o, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  marginTop: i === 0 ? 0 : "0.5rem",
                }}
                key={i}>
                <div style={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "35px",
                  backgroundSize: "70px 70px",
                  backgroundImage: `url(${o.photoUrl})`,
                }}/>
                <div style={{ flex: 1, paddingLeft: "1rem" }}>
                  <strong>
                    {o.name}
                  </strong>
                  {" | "} {o.businessDepartment?.title}
                  <p>
                    {o.id}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      );
    case DRAFT_ENTITY_TYPE_BOOKS:
      return (
        <div style={{
          borderRadius: "0.5rem",
          // border: "1px solid black",
          padding: "1rem",
          backgroundColor: "lightgrey",
        }}>
          {data?.items?.map((o, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  marginTop: i === 0 ? 0 : "0.5rem",
                }}
                key={i}>
                <div style={{
                  width: "50px",
                  height: "70px",
                  backgroundSize: "50px 70px",
                  backgroundImage: `url(${o.coverImageUrl})`,
                }}/>
                <div style={{ flex: 1, paddingLeft: "1rem" }}>
                  {o.id} {o.title}
                </div>
              </div>
            );
          })}
        </div>
      );
    case DRAFT_ENTITY_TYPE_PHOTOS:
      return (
        <div>
          <Image.Group size={"small"}>
            {data?.items?.map((o, i) => {
              return (
                <Image
                  key={i}
                  src={o.url}
                />
              );
            })}
          </Image.Group>
        </div>
      );
    case DRAFT_ENTITY_TYPE_BUSINESS_BOOK_FORUMS:
      return (
        <div>
          {data?.items?.map((o, i) => {
            return (
              <div key={i}>
                <div>
                  {o.id} {o.title}
                </div>
              </div>
            );
          })}
        </div>
      );
    case DRAFT_ENTITY_TYPE_SCRAPS:
      return (
        <Card.Group itemsPerRow={4}>
          {
            data?.items?.map((o, i) => {
              return (
                <Card key={o.id}>
                  <Card.Content style={{ fontSize: "0.8rem" }}>
                    <div style={{ display: "flex" }}>
                      <Image avatar src={o.user?.photoUrl}/>
                      <div style={{ paddingLeft: "0.3rem" }}>
                        {o.user.name} <br/>
                        <Truncate lines={1}>
                        <span style={{ color: "grey" }}>
                          {o.user.businessDepartment?.title}
                          </span>
                        </Truncate>
                      </div>
                    </div>
                  </Card.Content>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "100%",
                      backgroundColor: "lightgrey",
                      backgroundImage: `url(${o?.images?.[0]?.url})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100%",
                      position: "relative",
                      // borderRadius: "0.5rem",
                    }}>
                    <div style={{
                      position: "absolute",
                      bottom: "1rem",
                      left: "1rem",
                      width: "15%",
                      paddingTop: "11%",
                    }}>
                      <Image size={"small"} src={o.book?.coverImageUrl}/>
                    </div>
                  </div>
                  <Card.Content style={{}}>
                    <div style={{
                      width: "100%",
                      paddingHorizontal: "0px",
                      fontSize: "0.8rem",
                    }}>
                      <Truncate lines={1}>
                        {o.content}
                      </Truncate>
                    </div>

                  </Card.Content>
                </Card>
              );
            })
          }
        </Card.Group>
      );
    default:
      return (
        <div style={{ userSelect: "none" }}>
          <p>
            <strong>지원되지 않는 타입: </strong> {type}
          </p>
        </div>
      );
  }

};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(DraftMediaComponent);
