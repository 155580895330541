import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import LoaderComponent from "../shared/LoaderComponent";
import {Card, Image} from "semantic-ui-react";

const ScrapComponent = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});

  const {scrap} = props;
  if (!scrap) return null;


  return (
    <Card>
      {
        scrap.images?.length > 0 && (
          <Image src={scrap.images[0].url}/>
        )
      }
      {props.children}
    </Card>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(ScrapComponent);
