export const authActions = {
  APP_INITIALIZE: 'APP_INITIALIZE',
  SIGN_IN: "SIGN_IN",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",

  SIGN_OUT: "SIGN_OUT",

  FETCH_MY_INFO: "FETCH_MY_INFO",
  FETCH_MY_INFO_SUCCESS: "FETCH_MY_INFO_SUCCESS",
};

export const authActionCreators = {
  initialize: (cb) => ({type: authActions.APP_INITIALIZE, cb}),
  signIn: (signInId, password, businessId, cb) => ({type: authActions.SIGN_IN, signInId, password, businessId, cb}),
  signInSuccess: (user, cb) => ({type: authActions.SIGN_IN_SUCCESS, user, cb}),
  signInFailure: (userMessage) => ({type: authActions.SIGN_IN_FAILURE, userMessage}),

  signOut: (cb) => ({type: authActions.SIGN_OUT, cb}),

  fetchMyInfo: (cb) => ({type: authActions.FETCH_MY_INFO, cb}),
  fetchMyInfoSuccess: (user) => ({type: authActions.FETCH_MY_INFO_SUCCESS, user}),
};

const initialState = {
  isLoggedIn: false,
  user: {},
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.user || {},
        isLoggedIn: true,
      };
    case authActions.FETCH_MY_INFO_SUCCESS:
      return {
        ...state,
        user: action.user || {},
        isLoggedIn: true,
      };

    case authActions.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
