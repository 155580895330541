import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {useQuery} from "../../library/useQuery";
import moment from "moment";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Button, Container, Form, Radio, Segment, Table} from "semantic-ui-react";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import Network from '../../library/Network';
import _ from 'lodash';

const BusinessContentListContainer = (props) => {

  const history = useHistory();
  let {activePage, perPage, businessContentTypeId} = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;
  businessContentTypeId = !!businessContentTypeId ? Number(businessContentTypeId) : '';
  const [isLoaded, setIsLoaded] = useState(false);
  const [businessContents, setBusinessContents] = useState([]);
  const [businessContentTypes, setBusinessContentTypes] = useState([]);

  async function _load() {
    const res = await Network.businessContentListGET(
      {
        offset: (activePage - 1) * perPage,
        limit: perPage,
        businessContentTypeId
      },
    );
    if (!res.err) {
      setIsLoaded(true);
      setBusinessContents(res.businessContents || []);
      setBusinessContentTypes(res.businessContentTypes || []);
    }
  }

  useEffect(() => {
    setBusinessContents([]);
    _load();
  }, [activePage, perPage, businessContentTypeId]);

  const _handleChangeDateAndUnit = (
    {
      newActivePage = activePage,
      newPerPage = perPage,
      newBusinessContentTypeId = businessContentTypeId
    }) => {
    history.push(`/business-content/list?activePage=${newActivePage}&perPage=${newPerPage}&businessContentTypeId=${newBusinessContentTypeId}`);
  };

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        <Form>
          <Form.Group style={{alignItems: "flex-end"}}>
            <Form.Dropdown
              label={"타입"}
              selection
              value={businessContentTypeId}
              onChange={(e, {value}) => {
                _handleChangeDateAndUnit(
                  {
                    newActivePage: 1,
                    // newPerPage: value,
                    newBusinessContentTypeId: value,
                  });
              }}
              options={_.map(businessContentTypes, bct => ({key: bct.id, text: bct.text, value: bct.id}))}
            />

            <Form.Dropdown
              label={"표시개수"}
              selection
              value={perPage}
              onChange={(e, {value}) => {
                _handleChangeDateAndUnit({
                  newActivePage: 1,
                  newPerPage: value
                });
              }}
              options={[
                {key: 0, text: "20", value: 20},
                {key: 1, text: "50", value: 50},
                {key: 2, text: "100", value: 100},
                {key: 3, text: "500", value: 500},
                {key: 4, text: "5000", value: 5000},
              ]}
            />
          </Form.Group>
        </Form>
        <Button
          basic
          primary
          as={Link}
          to={"/business-content/write"}
          content={"만들기"}/>

      </Container>
      <Container as={Segment} vertical basic>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>id</Table.HeaderCell>
              <Table.HeaderCell>title</Table.HeaderCell>
              <Table.HeaderCell width={1}>public</Table.HeaderCell>
              <Table.HeaderCell>businessContentType</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              businessContents?.map((o, i) => {
                return (
                  <Table.Row key={o.id}>
                    <Table.Cell>
                      <Link to={`/business-content/${o.id}`}>
                        {o.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/business-content/${o.id}`}>
                        {o.title}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Radio
                        checked={o.isPublic}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {o.businessContentType?.text}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(BusinessContentListContainer);
