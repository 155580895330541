import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from "react-router";
import {useQuery} from "../../library/useQuery";
import Network from "../../library/Network";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Button, Table, Modal, Form} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import numeral from "numeral";

const initialBookClubMission = {
  title: '',
}

const BusinessBookClubDetailMissionTab = (props) => {

  const {businessBookClubId} = useParams();
  let {activePage, perPage, keyword} = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 10;
  const [isLoaded, setIsLoaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [newMissionModalOpen, setNewMissionModalOpen] = useState(false)
  const [meta, setMeta] = useState({total: 0});
  const [businessBookClubMissions, setBusinessBookClubMissions] = useState([])
  const [businessBookClubMissionTypes, setBusinessBookClubMissionTypes] = useState([])
  const [newBookClubMission, setNewBookClubMission] = useState({})

  const _load = async () => {
    const res = await Network
      .businessBookClubMissionsGET({
        businessBookClubId,
        offset: ( activePage - 1 ) * perPage,
        limit: perPage,
      })
    if (!res.err) {
      setMeta(res.meta);
      setIsLoaded(true);
      setBusinessBookClubMissions(res.businessBookClubMissions)
      setBusinessBookClubMissionTypes(res.businessBookClubMissionTypes);
    }
  }
  const _upload = async () => {
    if (uploading) return;
    setUploading(true);
    if (!!newBookClubMission.id) {
      const res = await Network
        .businessBookClubMissionPUT({
          businessBookClubId, businessBookClubMissionId: newBookClubMission.id,
          ...newBookClubMission,
        })
      if (!res.err) {
        _handleCloseModal();
      }
    } else {
      const res = await Network
        .businessBookClubMissionPOST({
          businessBookClubId,
          ...newBookClubMission,
        })
      if (!res.err) {
        _handleCloseModal();
      }
    }
    await _load();
    setUploading(false);

  }
  const _onRefresh = async () => {
    setRefreshing(true);
    await _load();
    setRefreshing(false);
  }

  useEffect(() => {
    _load();
  }, [])
  const _handleOpenModal = (newBookClubMission) => {
    setNewBookClubMission(newBookClubMission || {})
    setNewMissionModalOpen(true);
  }
  const _handleCloseModal = () => {
    setNewMissionModalOpen(false);
    setNewBookClubMission({});

  }

  if (!isLoaded) return <LoaderComponent/>;
  const _renderNewModal = () => {
    return (
      <Modal open={newMissionModalOpen}>
        <Modal.Header>
          {!!newBookClubMission?.id ? '미션 수정' : '새로운 미션 추가'}
        </Modal.Header>
        <Modal.Content scrolling style={{minHeight: '50vh'}}>
          <Form>
            <Form.Dropdown
              label={'미션타입'}
              selection
              value={newBookClubMission.businessBookClubMissionTypeId}
              options={businessBookClubMissionTypes.map(o => ( {key: o.id, text: o.title, value: o.id} ))}
              onChange={(e, {value}) => {
                setNewBookClubMission(prev => ( {...prev, businessBookClubMissionTypeId: value} ))
              }}
            />
            <Form.Input
              label={'title'}
              value={newBookClubMission.title}
              onChange={(e, {value}) => setNewBookClubMission(prev => ( {...prev, title: value} ))}
            />
            <Form.Group widths={'equal'}>
              <SemanticDatepicker locale={"ko-KR"}
                                  label={"미션시작일"}
                                  fluid
                                  clearable={false}
                                  value={moment(newBookClubMission.startDate).toDate()}
                                  onChange={(e, {value}) => {
                                    if (!value) return;
                                    setNewBookClubMission(prev => ( {...prev, startDate: moment(value).startOf('day').toISOString()} ))
                                  }}/>
              <SemanticDatepicker locale={"ko-KR"}
                                  label={"종료일"}
                                  fluid
                                  clearable={false}
                                  value={moment(newBookClubMission.endDate).toDate()}
                                  onChange={(e, {value}) => {
                                    if (!value) return;
                                    setNewBookClubMission(prev => ( {...prev, endDate: moment(value).endOf('day').toISOString()} ))
                                  }}/>
            </Form.Group>
            <Form.Input
              label={'order'}
              type={'number'}
              value={newBookClubMission.order}
              onChange={(e, {value}) => setNewBookClubMission(prev => ( {...prev, order: value} ))}
            />
            <Form.TextArea
              rows={10}
              label={'description'}
              onChange={(e, {value}) => setNewBookClubMission(prev => ( {...prev, description: value} ))}
              value={newBookClubMission.description}
            />
            <Form.Group widths={'equal'}>
              <Form.Input
                label={'기한내'}
                type={'number'}
                value={newBookClubMission.businessPointInTime}
                onChange={(e, {value}) => setNewBookClubMission(prev => ( {...prev, businessPointInTime: value} ))}
              />
              <Form.Input
                label={'지각'}
                type={'number'}
                value={newBookClubMission.businessPointLate}
                onChange={(e, {value}) => setNewBookClubMission(prev => ( {...prev, businessPointLate: value} ))}
              />
            </Form.Group>

          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={uploading}
            disabled={uploading}
            onClick={_handleCloseModal}
            content={'취소'}
          />
          <Button
            loading={uploading}
            disabled={uploading}
            content={newBookClubMission?.id ? '수정' : '추가'}
            onClick={_upload}
          />
        </Modal.Actions>
      </Modal>
    )
  }
  return (
    <div>
      {_renderNewModal()}
      <Button
        onClick={() => _handleOpenModal({})}
        positive
        icon={'plus'}
        content={'추가'}
      />
      <Button
        loading={refreshing}
        disabled={refreshing}
        onClick={_onRefresh}
        icon={'refresh'}
        content={'새로고침'}
      />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.HeaderCell>order</Table.HeaderCell>
            <Table.HeaderCell>title</Table.HeaderCell>
            <Table.HeaderCell>시작일</Table.HeaderCell>
            <Table.HeaderCell>종료일</Table.HeaderCell>
            <Table.HeaderCell>기한내</Table.HeaderCell>
            <Table.HeaderCell>기한넘어</Table.HeaderCell>
            <Table.HeaderCell>-</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            businessBookClubMissions.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.id}
                  </Table.Cell>
                  <Table.Cell>
                    {item.order}
                  </Table.Cell>
                  <Table.Cell>
                    {item.title}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.startDate).format('YYYY.MM.DD(ddd)HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.endDate).format('YYYY.MM.DD(ddd)HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(item.businessPointInTime).format('0,0')}
                  </Table.Cell>
                  <Table.Cell>
                    {numeral(item.businessPointLate).format('0,0')}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      size={'mini'}
                      content={'수정'}
                      onClick={() => _handleOpenModal(item)}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })
          }

        </Table.Body>
      </Table>
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessBookClubDetailMissionTab);
