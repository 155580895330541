import {combineReducers} from "redux"
import {authReducer} from "./auth/authStore";

const reducers = combineReducers({
  authReducer: authReducer
})

const rootReducer = (state, action) => {
  console.log(action.type);
  return reducers(state, action);
};

export default rootReducer;
