import BusinessBookCategoryMainContainer from "./BusinessBookCategoryMainContainer";
import BusinessBookCategoryDisabledListContainer from "./BusinessBookCategoryDisabledListContainer";
import BusinessBookCategoryExceptionListContainer from "./BusinessBookCategoryExceptionListContainer";


const businessBookCategoryContainers = [
  { path: "/business-book-category", component: BusinessBookCategoryMainContainer },
  { path: "/business-book-category/list/disabled", component: BusinessBookCategoryDisabledListContainer },
  { path: "/business-book-category/book/list/exception", component: BusinessBookCategoryExceptionListContainer },
]

export default businessBookCategoryContainers
