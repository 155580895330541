import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';

const EventMainContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  return (
    <div>
      EventMainContainer
    </div>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(EventMainContainer);
