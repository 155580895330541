import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import LoaderComponent from '../../components/shared/LoaderComponent';
import "../../rich.css";
import "draft-js/dist/Draft.css";

import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  DefaultDraftBlockRenderMap,
  DefaultDraftInlineStyle,
  CharacterMetadata,
  Modifier,
  getDefaultKeyBinding,
  KeyBindingUtil,
  AtomicBlockUtils,
  CompositeDecorator,
} from "draft-js";

import {Prompt, useHistory, useParams} from "react-router";
import DraftStyleButtonComponent from './DraftStyleButtonComponent';
import DraftMediaComponent from './DraftMediaComponent';
import moment from 'moment';
import {Button, Container, Icon, Modal, Segment} from 'semantic-ui-react';
import {uploadImageToFirebase} from '../../library/imageUploadToFirebase';
import Immutable from 'immutable';
import _ from 'lodash';
import {
  DRAFT_ENTITY_TYPE_PHOTOS,
  DRAFT_ENTITY_TYPE_BOOKS,
  DRAFT_ENTITY_TYPE_SCRAPS,
  DRAFT_ENTITY_TYPE_USERS,
} from '../../constants/draftEntityType';
import DraftAddBookComponent from './DraftAddBookComponent';
import DraftAddUserComponent from './DraftAddUserComponent';
import DraftAddPhotoComponent from './DraftAddPhotoComponent';
import DraftAddScrapComponent from './DraftAddScrapComponent';

const {hasCommandModifier, isCtrlKeyCommand, isOptionKeyCommand} = KeyBindingUtil;


const DraftEditorComponent = (props) => {

  const history = useHistory();
  const { uploading } = props;
  const isNew = !props.content?.id;

  const compositeDecorator = new CompositeDecorator([
    {
      strategy: findLinkStrategy,
      component: Link,
    },
    {
      strategy: handleMentionStrategy,
      component: handleMentionSpan,
    },
    {
      strategy: hashtagStrategy,
      component: HashtagSpan,
    },
  ]);

  const [editorState, setEditorState] = React.useState(EditorState.createEmpty(compositeDecorator));
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNOTSaved, setIsNOTSaved] = useState(false);
  const [content, setContent] = useState(props.content);


  const draftEditorRef = React.useRef(null);
  const coverImageUrlInputRef = React.useRef(null);

  const _load = async () => {
    if (!!props.content?.id && !!props.content?.draftRaw) {
      const initialContentState = convertFromRaw(props.content?.draftRaw);
      setEditorState(EditorState.createWithContent(initialContentState, compositeDecorator));
      setIsLoaded(true);
    } else {
      setIsLoaded(true);
      setEditorState(EditorState.createEmpty(compositeDecorator));
    }
  };

  useEffect(() => {
    _load();
  }, [props.content]);

  const _handleUploadBackgroundImage = async (e) => {
    console.log(e.target.files);
    if (e.target.files.length < 1) return;
    const url = await uploadImageToFirebase(
      {
        file: e.target.files[0],
        storageRef: `updateNote/${moment().format('YYYY-MM')}/`,
      });
    console.log({ url });
    setContent(prev => ( { ...prev, coverImageUrl: url } ));
  };

  const _handleUpload = async () => {
    const raw = convertToRaw(editorState.getCurrentContent());
    const planeText = editorState.getCurrentContent().getPlainText?.();
    setIsNOTSaved(false);

    props.handleSave?.(
      {
        content: {
          ...content,
          draftRaw: raw,
          text: planeText,
        },
      },
    );
  };
  const _handleEditorStateChange = editorState => {
    setEditorState(editorState);
    setIsNOTSaved(true);
  };
  const _focusOnDraftEditor = () => {
    draftEditorRef.current?.focus?.();
  };

  const _blockStyleFn = (contentBlock) => {

    switch (contentBlock.getType()) {
      case "blockquote":
        // return "superFancyBlockquote";
        return "RichEditor-blockquote";
      case "box-quote":
        return 'RichEditor-box-quote';
      case 'center':
      case 'header-one-center':
      case 'header-two-center':
        return 'align-center';
      case 'right':
      case 'header-one-right':
      case 'header-two-right':
        return 'align-right';
      default:
        return null;
    }
  };
  const _blockRendererFn = (contentBlock) => {
    if (contentBlock.getType() === "atomic") {
      return {
        component: DraftMediaComponent,
        editable: false,
        props: {
          foo: "bar",
        },
      };
    }
    return null;
  };
  const _keyBindingFn = (e) => {
    // console.log(e.keyCode);
    if (e.keyCode === 83 && hasCommandModifier(e)) {
      //  S
      return "my-editor-save";
    }
    if (e.keyCode === 49 && hasCommandModifier(e) && isCtrlKeyCommand(e)) {
      // 1 + Ctrl + Command
      return "header-one";
    }

    if (e.keyCode === 50 && hasCommandModifier(e) && isCtrlKeyCommand(e)) {
      // 2 + Ctrl + Command
      return "header-two";
    }
    if (e.keyCode === 51 && hasCommandModifier(e) && isCtrlKeyCommand(e)) {
      // 3 + Ctrl + Command
      return "header-three";
    }
    if (e.keyCode === 52 && hasCommandModifier(e) && isCtrlKeyCommand(e)) {
      // 4 + Ctrl + Command
      return "header-four";
    }
    if (e.keyCode === 53 && hasCommandModifier(e) && isCtrlKeyCommand(e)) {

      // 5 + Ctrl + Command
      return "header-five";
    }
    if (e.keyCode === 54 && hasCommandModifier(e) && isCtrlKeyCommand(e)) {
      // 5 + Ctrl + Command
      return "header-six";
    }

    if (e.keyCode === 76 && hasCommandModifier(e) && isCtrlKeyCommand(e)) {
      // 5 + Ctrl + Command
      return "unordered-list-item";
    }

    if (e.keyCode === 67 && hasCommandModifier(e) && isCtrlKeyCommand(e)) {
      // C + Ctrl + Command
      return "add-custom-component-photo";
    }

    if (e.keyCode === 66 && hasCommandModifier(e)) {
      return;
    }

    if (e.keyCode === 9) {
      // TAB
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== editorState) {
        _handleEditorStateChange(newEditorState);
      }
      return;
    }

    return getDefaultKeyBinding(e);
  };
  const _handleKeyCommand = (command, editorState) => {
    // console.log(command);
    switch (command) {
      // case "italic":
      // case "bold":
      //   return "not-handled";
      case "header-one":
      case "header-two":
      case "header-three":
      case "header-four":
      case "header-five":
      case "header-six":
      case "unordered-list-item":
        _toggleBlockType(command);
        return "handled";
      case "my-editor-save":
        _handleUpload();
        return "handled";
      case "add-custom-component-photo":
        // _handleOpenCustomComponentModal();
        _handleOpenAddPhotoModal();
        return "handled";
      default:
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
          _handleEditorStateChange(newState);
          // setEditorState(newState);
          return "handled";
        }
        return "not-handled";
    }
  };

  const _toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };
  const _toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };
  const _toggleColor = (toggledColor) => {
    const selection = editorState.getSelection();

    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(colorStyleMap).reduce(
      (contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color);
      },
      editorState.getCurrentContent(),
    );
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style",
    );
    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledColor,
      );
    }
    _handleEditorStateChange(nextEditorState);
  };
  const _toggleFontFamily = (fontFamily) => {
    const selection = editorState.getSelection();
    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(fontFamilyStyleMap).reduce(
      (contentState, ff) => {
        return Modifier.removeInlineStyle(contentState, selection, ff);
      },
      editorState.getCurrentContent(),
    );
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style",
    );
    const currentStyle = editorState.getCurrentInlineStyle();
    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }
    // If the color is being toggled on, apply it.
    if (!currentStyle.has(fontFamily)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        fontFamily,
      );
    }
    _handleEditorStateChange(nextEditorState);
  };
  const _handlePastedText = (text, styles, editorState) => {
    console.log("_handlePastedText", { text, styles, editorState });
  };


  const [addPhotoModalOpen, setAddPhotoModalOpen] = useState(false);
  const _handleOpenAddPhotoModal = () => setAddPhotoModalOpen(true);
  const _handleCloseAddPhotoModal = () => setAddPhotoModalOpen(false);
  const _handleCompleteAddPhoto = (customComponentData) => {
    _addCustomComponent(
      {
        entityType: DRAFT_ENTITY_TYPE_PHOTOS,
        data: customComponentData,
      },
    );
    _handleCloseAddPhotoModal();
  };

  const _handleCancelAddPhoto = () => {
    _handleCloseAddPhotoModal();
  };

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const _handleOpenAddUserModal = () => setAddUserModalOpen(true);
  const _handleCloseAddUserModal = () => setAddUserModalOpen(false);
  const _handleCompleteAddUser = (customComponentData) => {
    _addCustomComponent(
      {
        entityType: DRAFT_ENTITY_TYPE_USERS,
        data: customComponentData,
      },
    );
    _handleCloseAddUserModal();
  };



  const [addBookModalOpen, setAddBookModalOpen] = useState(false);
  const _handleOpenAddBookModal = () => setAddBookModalOpen(true);
  const _handleCloseAddBookModal = () => setAddBookModalOpen(false);
  const _handleCompleteAddBook = (customComponentData) => {
    _addCustomComponent(
      {
        entityType: DRAFT_ENTITY_TYPE_BOOKS,
        data: customComponentData,
      },
    );
    _handleCloseAddBookModal();
  };

  const [addScrapModalOpen, setAddScrapModalOpen] = useState(false);
  const _handleOpenAddScrapModal = () => setAddScrapModalOpen(true);
  const _handleCloseAddScrapModal = () => setAddScrapModalOpen(false);
  const _handleCompleteAddScrap = (customComponentData) => {
    _addCustomComponent(
      {
        entityType: DRAFT_ENTITY_TYPE_SCRAPS,
        data: customComponentData,
      },
    );
    _handleCloseAddScrapModal();
  };

  const _handleClickCustomComponentControl = (type) => {
    switch (type) {
      case DRAFT_ENTITY_TYPE_PHOTOS:
        _handleOpenAddPhotoModal();
        break;
      case DRAFT_ENTITY_TYPE_USERS:
        _handleOpenAddUserModal();
        break;
      case DRAFT_ENTITY_TYPE_BOOKS:
        _handleOpenAddBookModal();
        break;
      case DRAFT_ENTITY_TYPE_SCRAPS:
        _handleOpenAddScrapModal();
        break;
      default:
        break;
    }
  };

  const _addCustomComponent = ({ entityType, data }) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      entityType,
      "IMMUTABLE",
      { ...data },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    setEditorState(AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      " ",
    ));
  };


  return (
    <div style={{ position: 'relative' }}>
      <DraftAddBookComponent
        open={addBookModalOpen}
        handleComplete={_handleCompleteAddBook}
        handleCancel={_handleCloseAddBookModal}
      />
      <DraftAddUserComponent
        open={addUserModalOpen}
        handleComplete={_handleCompleteAddUser}
        handleCancel={_handleCloseAddUserModal}
      />
      <DraftAddPhotoComponent
        open={addPhotoModalOpen}
        handleComplete={_handleCompleteAddPhoto}
        handleCancel={_handleCloseAddPhotoModal}
      />
      <DraftAddScrapComponent
        open={addScrapModalOpen}
        handleComplete={_handleCompleteAddScrap}
        handleCancel={_handleCloseAddScrapModal}
      />
      {/*<DraftAddScrapComponent/>*/}
      <Prompt when={isNOTSaved} message={"페이지에서 나가시겠습니까?"}/>
      <div style={{
        zIndex: 1001,
        position: "absolute",
        top: 10,
        right: 10,
        // width: "100%",
      }}>
        <Button
          loading={uploading}
          disabled={uploading}
          onClick={_handleUpload}
          content={"저장하기"}/>
      </div>
      {
        !props.hideHeader && (
          <>
            <div style={{ width: "100%", height: "400px", position: "relative" }}>
              <div style={{
                position: "relative",
                height: "400px",
                backgroundImage: `url(${content?.coverImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                opacity: 0.5,
              }}>
              </div>
              <div style={{
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                height: "400px",
                paddingTop: "280px",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}>
                <Container text>
                  <div style={{
                    display: "flex",
                    position: "relative",
                  }}>
                    <div style={{ flex: 1, backgroundColor: "transparent" }}>
                      <input
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                          // marginBottom: "1rem",
                          borderRadius: 0,
                          // border: "1px solid #ccc",
                          outline: "none",
                          border: "transparent",
                          padding: "0.5rem",
                          fontSize: "2rem",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        value={content.title || ""}
                        placeholder={"제목을 입력해주세요."}
                        onChange={(e) => {
                          setContent(prev => ( { ...prev, title: e.target.value } ));
                        }}/>
                      <input
                        style={{
                          width: "100%",
                          marginBottom: "1rem",
                          backgroundColor: "transparent",
                          borderRadius: 0,
                          // border: "1px solid #ccc",
                          outline: "none",
                          border: "transparent",
                          padding: "0.5rem",
                          fontSize: "1.4rem",
                          color: "white",
                        }}
                        value={content.subTitle || ""}
                        placeholder={"소제목을 입력하세요."}
                        onChange={(e) => {
                          setContent(prev => ( { ...prev, subTitle: e.target.value } ));
                        }}/>
                    </div>
                    <div style={{ paddingLeft: "1rem" }}>
                      <label>
                        <Button
                          onClick={() => {
                            coverImageUrlInputRef.current.click?.();
                          }}
                          htmlFor="coverImageUpload"
                          icon={"image"}/>
                      </label>
                      <input
                        ref={coverImageUrlInputRef}
                        type="file"
                        id="coverImageUpload"
                        style={{ display: "none" }}
                        onChange={_handleUploadBackgroundImage}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </>
        )
      }

      {
        !!props.children && (
          <Container as={Segment} vertical text>
            {props.children}
          </Container>
        )
      }
      <Container as={Segment} vertical text>
        <>
          {
            !props.hideIsPublic && (
              <div className="RichEditor-controls">
                <span
                  onMouseDown={() => setContent(prev => ( { ...prev, isPublic: !prev.isPublic } ))}
                  className={"RichEditor-styleButton" + ( content.isPublic ? " RichEditor-activeButton" : "" )}
                >
                  공개
                </span>
              </div>
            )
          }
          <BlockStyleControls
            editorState={editorState}
            onToggle={_toggleBlockType}
          />
          <AlignStyleControls
            editorState={editorState}
            onToggle={_toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={_toggleInlineStyle}
          />
          <ColorControls
            editorState={editorState}
            onToggle={_toggleColor}
          />
          <FontFamilyControls
            editorState={editorState}
            onToggle={_toggleFontFamily}
          />
          <CustomComponentControls
            onClick={_handleClickCustomComponentControl}
          />
        </>
        <div
          className={'RichEditor-editor'}
          onClick={_focusOnDraftEditor}
          style={{
            border: "1px solid #ccc",
            cursor: "text",
            minHeight: "70vh",
            padding: 10,
            marginBottom: "1rem",
            // overflowY:'scroll'
          }}>
          <Editor
            spellCheck

            ref={draftEditorRef}
            placeholder="Enter some text..."
            editorState={editorState}
            customStyleMap={customStyleMap}
            blockRenderMap={extendedBlockRenderMap}
            blockStyleFn={_blockStyleFn}
            blockRendererFn={_blockRendererFn}
            // handleReturn={}

            handleKeyCommand={_handleKeyCommand} // command u
            // handleBeforeInput={}
            handlePastedText={_handlePastedText}
            // handlePastedFiles={}
            // handleDroppedFiles={}

            keyBindingFn={_keyBindingFn}
            onChange={_handleEditorStateChange}/>
        </div>
      </Container>
    </div>
  );

}


const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(DraftEditorComponent);


const styles = {
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: "'Georgia', serif",
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: "1px solid #ccc",
    cursor: "text",
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: "center",
  },
  link: {
    color: "#3b5998",
    textDecoration: "underline",
  },
  handle: {
    color: "rgba(98, 177, 254, 1.0)",
    direction: "ltr",
    unicodeBidi: "bidi-override",
  },
  hashtag: {
    color: "rgba(95, 184, 138, 1.0)",
  },
};

const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};
const handleMentionSpan = (props) => {
  return (
    <span
      style={styles.handle}
      data-offset-key={props.offsetKey}
    >
      {props.children}
    </span>
  );
};
const HashtagSpan = (props) => {
  return (
    <span
      style={styles.hashtag}
      data-offset-key={props.offsetKey}
    >
      {props.children}
    </span>
  );
};

function findLinkStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK");
    },
    callback,
  );
}

function handleMentionStrategy(contentBlock, callback, contentState) {
  const HANDLE_REGEX = /@[\w]+/g;
  findWithRegex(HANDLE_REGEX, contentBlock, callback);
}

function hashtagStrategy(contentBlock, callback, contentState) {
  const HASHTAG_REGEX = /#[\w\u0590-\u05ff]+/g;
  findWithRegex(HASHTAG_REGEX, contentBlock, callback);
}

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}


const BLOCK_TYPES = [
  {label: "H1", style: "header-one"},
  {label: "H2", style: "header-two"},
  {label: "H3", style: "header-three"},
  {label: "H4", style: "header-four"},
  {label: "H5", style: "header-five"},
  {label: "H6", style: "header-six"},
  {label: "Blockquote", icon: 'quote left', style: "blockquote"},
  {label: "BlockBox", icon: 'square outline', style: "box-quote"},
  {label: "UL", icon: 'list ul', style: "unordered-list-item"},
  {label: "Code Block", icon: 'code', style: "code-block"},
];
const BlockStyleControls = props => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <DraftStyleButtonComponent
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const ALIGN_TYPES = [
  {
    types: ['unstyled', 'center', 'right'],
    styles: [
      {label: "CENTER", icon: 'align center', style: "center"},
      {label: "RIGHT", icon: 'align right', style: "right"},
    ],
  },
  {
    types: ['header-one', 'header-one-center', 'header-one-right'],
    styles: [
      {label: "CENTER", icon: 'align center', style: "header-one-center"},
      {label: "RIGHT", icon: 'align right', style: "header-one-right"},
    ],
  },
  {
    types: ['header-two', 'header-two-center', 'header-two-right'],
    styles: [
      {label: "CENTER", icon: 'align center', style: "header-two-center"},
      {label: "RIGHT", icon: 'align right', style: "header-two-right"},
    ],
  },
  {
    types: ['header-three', 'header-three-center', 'header-three-right'],
    styles: [
      {label: "CENTER", icon: 'align center', style: "header-three-center"},
      {label: "RIGHT", icon: 'align right', style: "header-three-right"},
    ],
  },
  {
    types: ['header-four', 'header-four-center', 'header-four-right'],
    styles: [
      {label: "CENTER", icon: 'align center', style: "header-four-center"},
      {label: "RIGHT", icon: 'align right', style: "header-four-right"},
    ],
  },
  {
    types: ['header-five', 'header-five-center', 'header-five-right'],
    styles: [
      {label: "CENTER", icon: 'align center', style: "header-five-center"},
      {label: "RIGHT", icon: 'align right', style: "header-five-right"},
    ],
  },
  {
    types: ['header-six', 'header-six-center', 'header-six-right'],
    styles: [
      {label: "CENTER", icon: 'align center', style: "header-six-center"},
      {label: "RIGHT", icon: 'align right', style: "header-six-right"},
    ],
  },
];
const AlignStyleControls = props => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const types = _.find(ALIGN_TYPES, o => o.types.includes(blockType));


  return (
    <div className="RichEditor-controls">
      {types?.styles.map(type => (
        <DraftStyleButtonComponent
          key={type.style}
          active={type.style === blockType}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const FONT_TYPES = [
  {label: "가", style: "SAN_SERIF_BLACK"},
  {label: "가", style: "SAN_SERIF_BOLD"},
  {label: "가", style: "THIN"},
  // { label: "Italic", style: "ITALIC" },
  {label: "가", style: "SERIF_BLACK"},
  {label: "가", style: "SERIF_EXTRA_LIGHT"},
];
const FontFamilyControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {FONT_TYPES.map((type, i) => {
        const textStyle = fontFamilyStyleMap[type.style] || {};
        return (
          <DraftStyleButtonComponent
            key={type.style}
            textStyle={textStyle}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        );
      })}
    </div>
  );
};

const INLINE_STYLES = [
  {label: "Underline", icon: 'underline', style: "UNDERLINE"},
  {label: "LineThrough", icon: 'strikethrough', style: "LINE_THROUGH"},
  {label: "Monospace", style: "CODE"},
];
const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => {
        return (
          <DraftStyleButtonComponent
            key={type.style}
            active={currentStyle.has(type.style)}
            label={type.label}
            icon={type.icon}
            onToggle={props.onToggle}
            style={type.style}
          />
        );
      })}
    </div>
  );
};

const COLORS = [
  {label: "C1", style: "black", color: "#363636"},
  {label: "C2", style: "darkgrey", color: "#A7A7A7"},
  {label: "C3", style: "lightgrey", color: "#DBDBDB"},

  {label: "B1", style: "red", color: "#F7593C"},
  {label: "B2", style: "orange", color: "#F7AF3C"},
  {label: "B3", style: "green", color: "#4ABA2F"},
  {label: "C4", style: "cyan", color: "#21ADA8"},
  {label: "C5", style: "blue", color: "#2172AD"},
  {label: "C1", style: "purple", color: "#B154C3"},

  {label: "C1", style: "red_light", color: "#D98989"},
  {label: "C1", style: "orange_light", color: "#EBB55F"},
  {label: "C1", style: "green_light", color: "#B7D989"},
  {label: "C1", style: "cyan_right", color: "#89D9D7"},
  {label: "C1", style: "blue_right", color: "#89A8D9"},
  {label: "C1", style: "purple_right", color: "#C789D9"},

  // { label: "Orange", style: "orange", color: "orange" },
  // { label: "Yellow", style: "yellow", color: "yellow" },
  // { label: "Green", style: "green", color: "green" },
  // { label: "Blue", style: "blue", color: "blue" },
  // { label: "Indigo", style: "indigo", color: "indigo" },
  // { label: "Violet", style: "violet", color: "violet" },
];
const ColorControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div style={{marginBottom: 5, display: 'flex'}}>
      {COLORS.map((type, i) => {
          const active = currentStyle.has(type.style);
          return (
            <div key={type.style}
                 onMouseDown={(e) => {
                   e.preventDefault();
                   props.onToggle(type.style);
                 }}
                 style={{
                   cursor: 'pointer',
                   userSelect: 'none',
                   width: 30,
                   height: 30,
                   borderRadius: 15,
                   border: active ? '3px solid black' : '3px solid transparent',
                   // borderColor: active ? 'black' : 'transparent',
                   // borderWidth: '2px',
                   backgroundColor: type.color,
                   marginRight: 5,
                 }}>
            </div>
          );
        },
      )}
    </div>
  );
};

const CUSTOM_COMPONENTS = [
  {icon: 'image', type: DRAFT_ENTITY_TYPE_PHOTOS},
  {icon: 'file outline', type: DRAFT_ENTITY_TYPE_SCRAPS},
  {icon: 'book', type: DRAFT_ENTITY_TYPE_BOOKS},
  {icon: 'user', label: '유저', type: DRAFT_ENTITY_TYPE_USERS},
];
const CustomComponentControls = props => {
  return (
    <div className="RichEditor-controls">
      {CUSTOM_COMPONENTS.map((o, i) => {
        let className = "RichEditor-styleButton";
        return (
          <span className={className} onMouseDown={() => props.onClick(o.type)}>
            {!!o.icon ? (
              <Icon name={o.icon}/>
            ) : (
              `${o.label}`
            )}
          </span>
        );
      })}
    </div>
  );
};

const colorStyleMap = {

  black: {color: '#363636'},
  darkgrey: {color: '#A7A7A7'},
  lightgrey: {color: '#DBDBDB'},

  red: {color: '#F7593C'},
  orange: {color: '#F7AF3C'},
  green: {color: '#4ABA2F'},
  cyan: {color: '#21ADA8'},
  blue: {color: '#2172AD'},
  purple: {color: '#B154C3'},
  red_light: {color: '#D98989'},
  orange_light: {color: '#EBB55F'},
  green_light: {color: '#B7D989'},
  cyan_light: {color: '#89D9D7'},
  blue_light: {color: '#89A8D9'},
  purple_light: {color: '#C789D9'},

  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: "\"Inconsolata\", \"Menlo\", \"Consolas\", monospace",
    fontSize: 16,
    padding: 2,
  },
};

const fontFamilyStyleMap = {
  SAN_SERIF_BLACK: {
    fontWeight: '900',
  },
  SAN_SERIF_BOLD: {
    fontWeight: '600',
  },
  SERIF_BLACK: {
    fontFamily: 'Noto Serif KR',
    fontWeight: '900',
  },
  SERIF_EXTRA_LIGHT: {
    fontFamily: 'Noto Serif KR',
    fontWeight: '200',
  },
};


const customStyleMap = {
  ...colorStyleMap,
  ...fontFamilyStyleMap,
};

const blockRenderMap = Immutable.Map({
  'header-one': {element: 'h1'},
  'header-one-center': {element: 'h1'},
  'header-one-right': {element: 'h1'},

  'header-two': {element: 'h2'},
  'header-two-center': {element: 'h2'},
  'header-two-right': {element: 'h2'},

  'header-three': {element: 'h3'},
  'header-three-center': {element: 'h3'},
  'header-three-right': {element: 'h3'},

  'header-four': {element: 'h4'},
  'header-four-center': {element: 'h4'},
  'header-four-right': {element: 'h4'},

  'header-five': {element: 'h5'},
  'header-five-center': {element: 'h5'},
  'header-five-right': {element: 'h5'},

  'header-six': {element: 'h6'},
  'header-six-center': {element: 'h6'},
  'header-six-right': {element: 'h6'},

});
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);
