import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoaderComponent from "./components/shared/LoaderComponent";
import HomeContainer from "./container/home/HomeContainer";
import LoginContainer from "./container/auth/LoginContainer";
import SideBarComponent from "./components/layout/Layout";
import { privateRouterList } from "./route/priveRouterList";
import { PrivateRoute } from "./components/shared/PrivateRoute";
import { connect } from "react-redux";
import { authActionCreators } from "./redux/auth/authStore";
import { auth } from "./constants/firebase";
import "moment/locale/ko";
import { defaults } from "react-chartjs-2";
import Network from "./library/Network";

defaults.animation = false;
let devMode = false;
devMode = true;


function App (props) {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (!!user) {
        Network.setFirebaseUid(user.uid);
        props.fetchMyInfo(() => {
          setIsLoaded(true);
        });
      } else {
        setIsLoaded(true);
      }
    });
  }, []);

  if (!isLoaded) {
    return <LoaderComponent/>;
  }

  if (!props.authReducer?.isLoggedIn) {
    return (
      <LoginContainer/>
    );
  }

  return (
    <BrowserRouter>
      <SideBarComponent>
        <Switch>
          {
            privateRouterList.map((route, i) => {
              return (
                <PrivateRoute
                  key={i}
                  exact
                  isLoggedIn={props.authReducer?.isLoggedIn}
                  path={route.path}
                  component={route.component}
                />
              );
            })
          }
        </Switch>
      </SideBarComponent>
    </BrowserRouter>

  );
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {
    ...authActionCreators,
  },
);

export default enhance(App);
