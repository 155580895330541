import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, Prompt } from "react-router";
import { Button, Card, Container, Form, Grid, Header, Segment, Table } from "semantic-ui-react";
import { Bar, Line } from "react-chartjs-2";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import { useQuery } from "../../library/useQuery";
import { Link } from "react-router-dom";
import LoaderComponent from "../../components/shared/LoaderComponent";
import Truncate from "react-truncate";
import _ from "lodash";
import numeral from "numeral";
import Network from '../../library/Network';

const InquiryMainContainer = (props) => {

  const history = useHistory();

  let { startDate, endDate, unit } = useQuery();
  unit = !!unit ? unit : "day";
  startDate = !!startDate ? startDate : moment().subtract(20, "day").startOf("day").toISOString();
  endDate = !!endDate ? endDate : moment().endOf("day").toISOString();

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});
  const [inquiriesNotAnswered, setInquiriesNotAnswered] = useState([]);
  const [numberOfInquiriesGroupByDate, setNumberOfInquiriesGroupByDate] = useState([]);


  const _load = async () => {
    const res = await Network.inquiryMainGET({
      startDate,
      endDate,
      unit,
    });
    if (!res.err) {
      setIsLoaded(true);
      setInquiriesNotAnswered(res.inquiriesNotAnswered || []);
      setNumberOfInquiriesGroupByDate(res.numberOfInquiriesGroupByDate || []);
    }
  };

  useEffect(() => {
    _load();
  }, [startDate, endDate, unit]);

  const _handleChangeDateAndUnit = (startDate, endDate, unit) => {
    history.push(`/inquiry?startDate=${moment(startDate).toISOString()}&endDate=${moment(endDate).toISOString()}&unit=${unit}`);
  };

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        <Header content={"1:1 문의"}/>
        <Form>
          <Form.Group>
            <Form.Dropdown
              label={"단위"}
              value={unit}
              onChange={(e, { value }) => {
                _handleChangeDateAndUnit(startDate, endDate, value);
              }}
              options={[
                { text: "일", value: "day", key: 0 },
                { text: "주", value: "week", key: 1 },
                { text: "월", value: "month", key: 2 },
              ]}
              selection
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(value, endDate, unit);
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, { value }) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(startDate, value, unit);
                                }}/>
          </Form.Group>
        </Form>
      </Container>
      <Container as={Segment} vertical basic>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"문의 수"}/>
                  <Card.Header content={`${numeral(_.sumBy(numberOfInquiriesGroupByDate, o => o.count)).format("0,0")} 건`}
                               style={{ textAlign: "right", color: "green" }}/>
                  <Card.Description style={{ textAlign: "right" }}>
                    평균 {( _.sumBy(numberOfInquiriesGroupByDate, o => o.count) / ( moment(endDate).diff(startDate, "day") + 1 ) ).toFixed(1) || "0"}건/일
                  </Card.Description>
                  <div style={{}}>
                    <Bar
                      // width={100}
                      height={300}
                      data={{
                        labels: numberOfInquiriesGroupByDate.map(o => moment(o.day).format("MM.DD (ddd)")),//_.map(numberOfBookRequestsGroupByDate, o => moment(o.day).format('YYYY-MM-DD')),
                        datasets: [{
                          label: `1:1문의`,
                          data: _.map(numberOfInquiriesGroupByDate, o => o.count),
                          backgroundColor: ["rgb(3, 57, 117,0.2)"],
                          borderColor: ["rgb(3, 57, 117)"],
                          borderWidth: 1,
                        }],
                        options: {},
                      }}
                      options={{
                        maintainAspectRatio: false,
                        scales: {
                          yAxes: [{
                            stacked: true,
                          }],
                          xAxes: [{
                            // stacked: true,
                            type: "time",
                            time: {
                              unit: unit,
                              displayFormats: {
                                day: "MM/DD(ddd)",
                                week: "MM/DD",
                                month: "YYYY-MM",
                              },
                            },
                          }],
                        },
                      }}
                    />
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header content={"답변 대기 문의"}/>
                  <Card.Header content={`${inquiriesNotAnswered?.length} 건`} textAlign={"right"} style={{ color: "green" }}/>
                  <Table singleLine>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>id</Table.HeaderCell>
                        <Table.HeaderCell>유저</Table.HeaderCell>
                        <Table.HeaderCell>내용</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        inquiriesNotAnswered?.map((o, i) => {
                          return (
                            <Table.Row key={i}>
                              <Table.Cell>
                                <Link to={`/inquiry/${o.id}`}>
                                  {o.id}
                                </Link>
                              </Table.Cell>
                              <Table.Cell>
                                <Link to={`/user/${o.userId}`}>
                                  {o.user?.name}
                                </Link>
                              </Table.Cell>
                              <Table.Cell style={{ maxWidth: 150 }}>
                                <Truncate lines={1}>
                                  {o.question}
                                </Truncate>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      }
                    </Table.Body>
                  </Table>
                  <Button
                    floated={"right"}
                    basic
                    primary
                    as={Link}
                    content={"모두보기"}
                    to={`/inquiry/list`}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container as={Segment} vertical basic>

      </Container>

    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(InquiryMainContainer);
