import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";
import { Button, Card, Container, Grid, Header, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Network from '../../library/Network';

const BusinessNoticeMainContainer = (props) => {



  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});

  const _load = async () => {
    const res = await Network.businessNoticeMainGET({});
    if (!res.err) {
      setIsLoaded(true);
    }
  }

  useEffect(() => {
    _load();
  }, []);


  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical>
        <Header content={"공지"}/>
        BusinessNoticeMainContainer
      </Container>
      <Container as={Segment} vertical>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header
                    content={"공지 조회"}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header
                    content={"최근 공지"}
                  />
                  <Button
                    floated={"right"}
                    content={"모두보기"}
                    basic
                    as={Link}
                    to={`/business-notice/list`}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BusinessNoticeMainContainer);
