import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import LoaderComponent from "../../components/shared/LoaderComponent";
import {Button, Container, Form, Header, Message, Pagination, Breadcrumb, Segment, Table} from "semantic-ui-react";
import {useHistory} from "react-router";
import {useQuery} from "../../library/useQuery";
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import Truncate from "react-truncate";
import UserComponent from "../../components/user/UserComponent";
import {Link} from "react-router-dom";
import Network from '../../library/Network';

const InquiryListContainer = (props) => {

  const history = useHistory();
  let {startDate, endDate, activePage, perPage, filter} = useQuery();
  startDate = !!startDate ? startDate : moment().startOf("month").toISOString();
  endDate = !!endDate ? endDate : moment().endOf("month").toISOString();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;
  filter = !!filter ? filter : "BEFORE_ANSWER";

  const [isLoaded, setIsLoaded] = useState(false);
  const [total, setTotal] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [inquiries, setInquiries] = useState([]);

  const _load = async () => {
    const res = await Network.inquiryListGET(
      {
        offset: (activePage - 1) * perPage,
        limit: perPage,
        filter: filter,
        startDate,
        endDate,
      });
    if (!res.err) {
      setIsLoaded(true);
      setInquiries(res.inquiries);
      setTotalPage(res.meta?.totalPage || 0);
      setTotal(res.meta?.total || 0);
    }
  };

  useEffect(() => {
    setInquiries([]);
    // setTotalPage(0);
    // setTotal(0);
    _load();
  }, [startDate, endDate, activePage, perPage, filter]);
  const _handleChangeDateAndUnit = (startDate, endDate, activePage, perPage, filter) => {
    history.push(`/inquiry/list?startDate=${moment(startDate).toISOString()}&endDate=${moment(endDate).toISOString()}&activePage=${activePage}&perPage=${perPage}&filter=${filter}`);
  };

  if (!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>
        <Header content={"1:1문의 내역"}/>
        <Breadcrumb>
          <Breadcrumb.Section
            as={Link}
            to={'/inquiry'}
          >
            1:1문의
          </Breadcrumb.Section>
          <Breadcrumb.Divider/>
          <Breadcrumb.Section
            active
          >
            리스트
          </Breadcrumb.Section>
        </Breadcrumb>
      </Container>
      <Container as={Segment} vertical>
        <Form>
          <Form.Group style={{alignItems: "flex-end"}}>
            <Form.Dropdown
              label={"filter"}
              selection
              value={filter}
              onChange={(e, {value}) => {
                console.log({value});
                _handleChangeDateAndUnit(startDate, endDate, 1, perPage, value);
              }}
              options={[
                {key: 0, text: "답변전", value: "BEFORE_ANSWER"},
                {key: 1, text: "답변완료", value: "ANSWERED"},
              ]}
            />
            <Form.Dropdown
              label={"표시개수"}
              selection
              value={perPage}
              onChange={(e, {value}) => {
                console.log({value});
                _handleChangeDateAndUnit(startDate, endDate, 1, value, filter);
              }}
              options={[
                {key: 0, text: "20", value: 20},
                {key: 1, text: "50", value: 50},
                {key: 2, text: "100", value: 100},
                {key: 3, text: "500", value: 500},
                {key: 4, text: "5000", value: 5000},
              ]}
            />
            <SemanticDatepicker locale={"ko-KR"}
                                label={"시작일"}
                                clearable={false}
                                value={moment(startDate).toDate()}
                                onChange={(e, {value}) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(value, endDate, 1, perPage, filter);
                                }}/>
            <SemanticDatepicker locale={"ko-KR"}
                                label={"종료일"}
                                clearable={false}
                                value={moment(endDate).toDate()}
                                onChange={(e, {value}) => {
                                  if (!value) return;
                                  _handleChangeDateAndUnit(startDate, value, 1, perPage, filter);
                                }}/>
            <Button.Group>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).subtract(1, "month").startOf("month").toISOString(),
                    moment(startDate).subtract(1, "month").endOf("month").toISOString(),
                    1,
                    perPage,
                    filter,
                  );
                }}
                icon={"chevron left"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment().startOf("month").toISOString(),
                    moment().endOf("month").toISOString(),
                    1,
                    perPage,
                    filter,
                  );
                }}
                content={"이번달"}/>
              <Button
                onClick={() => {
                  _handleChangeDateAndUnit(
                    moment(startDate).add(1, "month").startOf("month").toISOString(),
                    moment(startDate).add(1, "month").endOf("month").toISOString(),
                    1,
                    perPage,
                    filter,
                  );
                }}
                icon={"chevron right"}/>
            </Button.Group>
          </Form.Group>
        </Form>
        <Message>
          {total} 개의 문의 내역이 있습니다.
        </Message>
      </Container>
      <Container as={Segment} vertical>
        <Pagination totalPages={totalPage}
                    activePage={activePage}
                    onPageChange={(e, {activePage}) => {
                      _handleChangeDateAndUnit(startDate, endDate, activePage, perPage, filter);
                    }}/>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>유저</Table.HeaderCell>
              <Table.HeaderCell>문의내용</Table.HeaderCell>
              <Table.HeaderCell>문의시각</Table.HeaderCell>
              <Table.HeaderCell>답변시각</Table.HeaderCell>
              <Table.HeaderCell>소요시간</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              inquiries.map((o, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {(activePage - 1) * perPage + i + 1}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/inquiry/${o.id}`}>
                        {o.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <UserComponent user={o.user}/>
                    </Table.Cell>
                    <Table.Cell style={{maxWidth: "200px"}}>
                      <Link to={`/inquiry/${o.id}`}>
                        {o.question.substring(0,20)}..
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.createdAt).format("MM.DD(ddd) HH:mm")}
                    </Table.Cell>
                    <Table.Cell>
                      {o.isAnswered ? `${moment(o.answeredAt).format("MM.DD(ddd) HH:mm")}` : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {o.isAnswered ? `${moment(o.answeredAt).diff(o.createdAt, "hour")}시간` : "-"}
                    </Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(InquiryListContainer);
