import React from "react";
import { connect } from "react-redux";
import Lottie from "react-lottie";
import animationData from "../../asset/lottie/loading.json";

const LoaderComponent = (props) => {

  return (
    <div style={{
      // position: "fixed",
      width: "100%",
      height: "100vh",
      display: "flex",
      alignItems: "center",
    }}>
      <div style={{ margin: "auto" }}>
        <Lottie
          options={{
            loop: true,
            autoPlay: true,
            animationData: animationData,
            rendererSettings: {
              // preserveAspectRatio: 'xMidYMid slice'
            },
          }}
          style={{}}
          height={"100px"}
          width={"100px"}
          isStopped={false}
          isPaused={false}
        />
        <p style={{ textAlign: "center" }}>
          {!!props.text ? (
            <>
              {props.text}
            </>
          ) : (
            "불러오고 있습니다"
          )}
        </p>
      </div>

    </div>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(LoaderComponent);
