import React from "react";
import { Icon } from 'semantic-ui-react';

class DraftStyleButtonComponent extends React.Component {
  constructor () {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render () {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle} style={{...this.props.textStyle}}>
        {!!this.props.icon ? (
          <Icon name={this.props.icon}/>
        ) : (
          `${this.props.label}`
        )}

      </span>
    );
  }
}

export default DraftStyleButtonComponent;
