import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Grid, Segment } from "semantic-ui-react";
import { authActionCreators } from "../../redux/auth/authStore";

const LoginContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState({});
  const [fetching, setFetching] = useState(false);
  const [signInId, setSignInId] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {

  }, []);

  const _handleSignIn = async () => {
    const businessId = 1;
    setFetching(true);
    props.signIn(signInId, password, businessId, () => {
      setFetching(false);
    });
  };

  return (
    <div>
      <Grid
        stackable
        columns={2}
        // padded
        style={{
          height: "100vh",
          // alignItems: 'center'
        }}
        verticalAlign={"middle"}
      >
        <Grid.Column style={{ maxWidth: 450, margin: "auto" }}>
          <Segment basic>
            <div style={{}}>
              <Form>
                <Form.Input
                  placeholder={"id"}
                  name={"id"}
                  label={"id"}
                  onChange={(e, { value }) => {
                    setSignInId(value);
                  }}
                />
                <Form.Input
                  onChange={(e, { value }) => {
                    setPassword(value);
                  }}
                  name={"password"}
                  type="password"
                  label={"password"}/>
                <Form.Button
                  fluid
                  primary
                  active={fetching}
                  content={"로그인"}
                  disabled={fetching}
                  onClick={_handleSignIn}/>
              </Form>

            </div>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {
    ...authActionCreators,
  },
);

export default enhance(LoginContainer);
