import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router";
import LoaderComponent from "../../components/shared/LoaderComponent";
import moment from "moment";
import {Button, Card, Container, Grid, Header, Icon, Image, Modal, Segment, Table} from "semantic-ui-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SmsButtonComponent from "../../components/sms/SmsButtonComponent";
import Truncate from "react-truncate";
import {Link} from "react-router-dom";
import Network from '../../library/Network';
import numeral from 'numeral';


const UserDetailContainer = (props) => {

  const {userId} = useParams();
  const [refreshing, setRefreshing] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
  const [user, setUser] = useState({});


  const _load = async () => {
    setRefreshing(true);
    const res = await Network.userGET({userId});
    if (!res.err) {
      setUser(res.user);
      setIsLoaded(true);
    }
    setRefreshing(false);
  };

  const _resetPassword = async () => {
    const a = window.confirm(`패스워드가 [${user.businessMember?.signInId}]로 변경됩니다`);
    if (!a) return;
    const res = await Network.userPasswordPUT(
      {
        userId,
        password: user.businessMember?.signInId,
      },
    );
    if (!res.err) {
      window.alert("패스워드가 변경되었습니다.");
      _load();

    }
  };
  useEffect(() => {
    _load();
  }, [userId]);


  if (!isLoaded) return <LoaderComponent/>;


  return (
    <>
      <Container as={Segment} vertical basic>
        <Button icon={'refresh'} loading={refreshing} onClick={_load}/>
      </Container>
      <Container as={Segment} vertical basic>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Image size={'small'}
                         src={user.photoUrl}
                  />
                </Card.Content>
                <Card.Content>
                  <Card.Header>
                    {user.name}
                  </Card.Header>

                  <Card.Description>
                    {user.businessMember?.signInId} <br/>
                    {user.businessDepartment?.title} <br/>
                  </Card.Description>
                  <Card.Description>
                    {user.phoneNumber}
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    onClick={_resetPassword}
                    content={"비밀번호 초기화"}/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    포인트
                  </Card.Header>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>총 누적</Table.Cell>
                        <Table.Cell textAlign={'right'}>
                          {numeral(user.businessPointStat?.accuAll).format('0,0')}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>이번달</Table.Cell>
                        <Table.Cell textAlign={'right'}>
                          {numeral(user.businessPointStat?.accMonth).format('0,0')}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>오늘</Table.Cell>
                        <Table.Cell textAlign={'right'}>
                          {numeral(user.businessPointStat?.accDay).format('0,0')}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>남은</Table.Cell>
                        <Table.Cell textAlign={'right'}>
                          {numeral(user.businessPointStat?.left).format('0,0')}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Button content={'포인트 내역 상세보기'}/>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    교육이수시간
                  </Card.Header>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          이수시간
                        </Table.Cell>
                        <Table.Cell textAlign={'right'}>
                          {user.businessLearningCreditStat?.sum}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Button
                    as={Link}
                    to={`/user/${userId}/business-learning-credit`}
                    content={'이수시간 상세보기'}/>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container as={Segment} vertical style={{marginTop: '3rem'}}>
        <Header>
          도서 신청 내역 ({user.numberOfBusinessBookRequests})
        </Header>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>bbrId</Table.HeaderCell>
              <Table.HeaderCell>상태</Table.HeaderCell>
              <Table.HeaderCell>책</Table.HeaderCell>
              <Table.HeaderCell>시간</Table.HeaderCell>
              <Table.HeaderCell>-</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              user.businessBookRequests?.map((bbr, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Link to={`/business-book-request/${bbr.id}`}>
                        {bbr.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {bbr.businessBookRequestStatus?.text}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={`/book/${bbr.book?.id}`}>
                        {bbr.book?.title}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      {moment(bbr.createdAt).format('YYYY.MM.DD(ddd)HH:mm')}
                    </Table.Cell>
                    <Table.Cell>
                      {bbr.businessBookRequestStatusId === 1 && (
                        <SmsButtonComponent
                          onComplete={_load}
                          title={'[북스타그램/독서공방]'}
                          message={`${user.name}님 안녕하세요.\n도서 배송이 지연되어 안내 연락드립니다.\n출판사로부터 신청하신 도서의 입고가 지연되어 배송이 늦어지고 있습니다.\n빠르면 ${moment().weekday() <5 ? '내일':'다음주 월요일'} 늦으면, ${moment().weekday() <5 ? moment().add(2,'day').format('ddd요일'):'차주 화요일'}에 출고될 예정입니다. 최대한 빠르게 받아보실 수 있도록 준비하겠습니다. \n배송이 늦어져서 진심으로 죄송합니다.`}
                          phoneNumbers={
                            !!user.phoneNumber ?
                              [user.phoneNumber] :
                              []
                          }
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
        <Button
          as={Link}
          to={`/user/${userId}/business-book-request`}
          content={'전체보기'}/>
      </Container>

      <Container as={Segment} vertical style={{marginTop: '3rem'}}>
        <Header>
          도서 신청권
        </Header>
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>id</Table.HeaderCell>
              <Table.HeaderCell>상태</Table.HeaderCell>
              <Table.HeaderCell width={3}>만료일</Table.HeaderCell>
              <Table.HeaderCell width={3}>생성일</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              user.businessBookVouchers?.map((bbv, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {bbv.id}
                    </Table.Cell>
                    <Table.Cell>
                      {!!bbv.isUsed ? '사용' : ''}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(bbv.expiredAt).format('YYYY.MM.DD(ddd) HH:mm')}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(bbv.createdAt).format('YYYY.MM.DD(ddd) HH:mm')}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
        <Button
          as={Link}
          to={`/user/${userId}/business-book-request`}
          content={'전체보기'}/>
      </Container>

      <Container as={Segment} vertical style={{marginTop: '3rem'}}>
        <Header content={'1:1 문의'}/>
        <Table singleLine>
          <Table.Header>
            <Table.Row textAlign={'center'}>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>질문</Table.HeaderCell>
              <Table.HeaderCell>답변여부</Table.HeaderCell>
              <Table.HeaderCell>질문일시</Table.HeaderCell>
              <Table.HeaderCell>답변일시</Table.HeaderCell>
              <Table.HeaderCell>소요시간</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              user?.inquiries?.map((o, i) => {
                return (
                  <Table.Row key={i} textAlign={'center'}>
                    <Table.Cell>
                      <Link to={`/inquiry/${o.id}`}>
                        {o.id}
                      </Link>
                    </Table.Cell>
                    <Table.Cell style={{maxWidth: '150px'}}>
                      <Truncate
                        lines={1}>
                        {o.question}
                      </Truncate>
                    </Table.Cell>
                    <Table.Cell>
                      <Icon name={o.isAnswered ? 'checkmark box outline' : 'square outline'} style={{fontSize: '1.2rem'}}/>
                    </Table.Cell>
                    <Table.Cell>
                      {moment(o.createdAt).format('YY.MM.DD(ddd)HH:mm')}
                    </Table.Cell>
                    <Table.Cell>
                      {o.isAnswered ? `${moment(o.answeredAt).format("MM.DD(ddd) HH:mm")}` : "-"}
                    </Table.Cell>
                    <Table.Cell>
                      {o.isAnswered ? `${moment(o.answeredAt).diff(o.createdAt, "hour")}시간` : "-"}
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
        <Button
          to={`/user/${userId}/sms-history`}
          content={'전체보기'}/>
      </Container>

      <Container as={Segment} vertical style={{marginTop: '3rem'}}>
        <Header content={'SMS 발송 내역'}/>
        <Table>
          <Table.Header>

          </Table.Header>
          <Table.Body>
            {
              user?.smsHistories?.map((o, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{o.id}</Table.Cell>
                    <Table.Cell>{o.content}</Table.Cell>
                    <Table.Cell>{moment(o.createdAt).format('YY.MM.DD(ddd)HH:mm')}</Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>
        <SmsButtonComponent
          title={`[북스타그램/독서공방]`}
          onComplete={_load}
          phoneNumbers={
            !!user.phoneNumber ?
              [user.phoneNumber] :
              // ['01051136452','01047751779'] :
              []
          }
        />
        <Button
          to={`/user/${userId}/business-book-request`}
          content={'전체보기'}/>
      </Container>

      <Container as={Segment} vertical style={{marginTop: '3rem'}}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8}>
                token
              </Table.HeaderCell>
              <Table.HeaderCell>
                os
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                createdAt
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                modifiedAt
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {user?.userTokens?.map((o, i) => {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                    {o.token.slice(0, 10)}
                    <CopyToClipboard text={o.token} onCopy={() => {
                      window.alert('복사되었습니다.')
                    }}>
                      <Button
                        basic
                        icon={'copy'}
                        size={'mini'}/>
                    </CopyToClipboard>
                  </Table.Cell>
                  <Table.Cell>{o.os}</Table.Cell>
                  <Table.Cell>{moment(o.createdAt).format("MM/DD(ddd) HH:mm")}</Table.Cell>
                  <Table.Cell>{moment(o.modifiedAt).format("MM/DD(ddd) HH:mm")}</Table.Cell>
                </Table.Row>
              )
            })}

          </Table.Body>
        </Table>
      </Container>
    </>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(UserDetailContainer);
