import React, {Component, useState, useEffect} from "react";
import {connect} from "react-redux";
import {Sidebar, Menu, Icon, Segment, Dropdown, Label, Header, Form, Input, Button, Select} from "semantic-ui-react";
import {Link, NavLink} from "react-router-dom";
import Colors from "../../constants/Colors";
import {useHistory} from "react-router";

const SideBarComponent = (props) => {


  const history = useHistory();
  const [visible, setVisible] = useState(true);
  const [metaLeftPressed, setMetaLeftPressed] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectedItemType, setSelectedItemType] = useState('USER')
  const searchInputRef = React.useRef()

  useEffect(() => {
    const _handleKeyDown = (e) => {
      if (e.code === "MetaLeft") setMetaLeftPressed(true);
      // if(e.code === "Slash") {
      //   e.preventDefault();
      //   searchInputRef.current.focus?.()
      // }
      // console.log(e.code);
    }
    const _handleKeyUp = (e) => {
      if (e.code === "MetaLeft") setMetaLeftPressed(false);
    }
    document.addEventListener("keydown", _handleKeyDown);
    document.addEventListener("keyup", _handleKeyUp);
    return () => {
      document.removeEventListener("keydown", _handleKeyDown);
      document.removeEventListener("keyup", _handleKeyUp);
    }
  }, []);

  const _handleInputFocus = (e) => {

  }
  const _handleInputBlur = (e) => {

  }

  const menus = [
    {
      type: "MENU",
      text: "홈",
      path: "/",
      hasSubMenu: false,
      iconName: "home",
    },
    {
      type: "MENU",
      text: "대시보드",
      path: "/dashboard",
      hasSubMenu: false,
      iconName: "dashboard",
    },
    {
      type: "SUBMENU",
      text: "도서 신청",
      // path: '/business-book-request',
      hasSubMenu: true,
      subMenus: [
        {text: "도서 신청 내역", path: "/business-book-request", iconName: "book", label: ""},
        {text: "도서 신청 정산", path: "/business-book-request/for-calculate", iconName: "book", label: ""},
        {text: "신청 가능 카테고리", path: "/business-book-category", iconName: "folder", label: ""},
      ],
    },
    {
      type: "MENU",
      text: "독서토론회",
      path: "/business-book-forum",
      hasSubMenu: false,
      iconName: "users",
    },
    {
      type: "MENU",
      text: "북클럽",
      path: "/business-book-club",
      hasSubMenu: false,
      iconName: "users",
    },
    {
      type: "SUBMENU",
      // iconName: 'user',
      text: "구성원",
      hasSubMenu: true,
      subMenus: [
        {text: "구성원 관리", path: "/business-member", iconName: "user", label: ""},
        {text: "조직 관리", path: "/business-department", iconName: "users", label: ""},
      ],
    },
    {
      type: "SUBMENU",
      // iconName: 'user',
      text: "포인트/교육이수",
      hasSubMenu: true,
      subMenus: [
        {text: "포인트", path: "/business-point", iconName: "user", label: ""},
        {text: "교육이수", path: "/business-learning-credit", iconName: "credit card", label: ""},
      ],
    },
    {
      type: "SUBMENU",
      // iconName: 'user',
      text: "기타",
      hasSubMenu: true,
      subMenus: [
        {text: "공지", path: "/business-notice", iconName: "user", label: ""},
        {text: "배너설정", path: "/business-banner", iconName: "user", label: ""},
        {text: "추천 책", path: "/business-book-recommendation-collection", iconName: "folder", label: ""},
        {text: "컨텐츠", path: "/business-content", iconName: "folder", label: ""},
        //  TODO 여기다 여기..
      ],
    },
    {
      type: "MENU",
      // iconName: 'user',
      text: `1:1문의`,
      path: "/inquiry",
      hasSubMenu: false,
    },
    {
      type: "MENU",
      // iconName: 'user',
      text: `스크랩`,
      path: "/scrap",
      hasSubMenu: false,
    },
    {
      type: "MENU",
      // iconName: 'user',
      text: `푸시메시지 보내기`,
      path: "/inquiry",
      hasSubMenu: false,
    },
    {
      type: "MENU",
      text: "마이페이지",
      path: "/my-page",
      hasSubMenu: false,
      iconName: "setting",
    },
    {
      type: "SUBMENU",
      text: "이벤트",
      path: "/event",
      hasSubMenu: true,
      subMenus: [
        {
          text: "해시태그이벤트",
          path: "/event/temp/monthly-hash-tag",
          iconName: "folder",
          label: "",
        },
        //  TODO 여기다 여기..
      ],
    },
    /*{
     type: "DROPDOWN",
     iconName: "home",
     text: "헤더",
     hasSubMenu: true,
     subMenus: [
     { text: "메뉴 안녕하세요", path: "/title", iconName: "refresh", label: "" },
     ],
     },*/
  ];

  return (
    <Sidebar.Pushable>
      <Sidebar
        size={"large"}
        as={Menu}
        onHide={() => setVisible(false)}
        style={{
          minHeight: "calc(100vh - 40px)",
          width: "275px",
          backgroundColor: Colors.kyoboThemeBlue,
        }}
        animation='overlay'

        visible
        inverted
        vertical
        // borderless
      >
        <Segment inverted>
          <Header content={"교보생명"} style={{textAlign: "center"}}/>
          {/*{metaLeftPressed ? "눌림" : "안눌림"}*/}
          <Form>
            <Form.Input
              onFocus={() => {
                console.log('focus');
              }}
              onBlur={() => {
                console.log('blur');
              }}
              fluid
              action
              value={keyword}
              onChange={(e, {value}) => {
                setKeyword(value);
              }}>
              <input ref={searchInputRef}/>
              <Select
                compact
                onChange={(e, {value}) => {
                  setSelectedItemType(value);
                }}
                options={[{text: '🙋🏻', key: 0, value: 'USER'}, {text: '📕', key: 1, value: 'BOOK'}]}
                value={selectedItemType}/>
              <Button
                onClick={() => {
                  history.push(`/search?keyword=${keyword}&itemType=${selectedItemType}`)
                  searchInputRef.current?.blur?.();
                }}
                icon={"search"}/>
            </Form.Input>
          </Form>
        </Segment>
        {
          menus.map((menu, i) => {
            switch (menu.type) {
              case "MENU":
                return (
                  <Menu.Item
                    key={i}
                    as={NavLink}
                    to={menu.path}
                  >
                    <Icon name={menu.iconName}/>
                    {menu.text}
                  </Menu.Item>
                );
              case "SUBMENU":
                return (
                  <Menu.Item key={i}>
                    <Menu.Header content={menu.text}/>
                    <Menu.Menu>
                      {
                        menu.subMenus?.map((subMenu, j) => {
                          return (
                            <Menu.Item
                              as={Link}
                              name={subMenu.text}
                              to={subMenu.path}
                              icon={subMenu.iconName}
                              label={subMenu.label}
                              key={i * 10 + j}/>
                          );
                        })
                      }
                    </Menu.Menu>

                  </Menu.Item>
                );
              case "DROPDOWN":
                return (
                  <Dropdown key={i} item text={menu.text} direction={"left"}>
                    <Dropdown.Menu>
                      {
                        menu.subMenus?.map((subMenu, j) => {
                          return (
                            <Dropdown.Item
                              key={i * 10 + j}
                              as={Link}
                              icon={subMenu.iconName}
                              text={subMenu.text}
                              to={subMenu.path}
                            />
                          );
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                );
              default:
                return null;

            }
          })
        }
      </Sidebar>
      <Sidebar.Pusher style={{
        height: "calc(100vh)",
        overflow: "scroll",
        marginLeft: "275px",
        paddingLeft: '1rem',
        paddingRight: '1rem',
      }}>
        {props.children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(SideBarComponent);
