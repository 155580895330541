import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';

const BusinessBookCategoryExceptionListContainer = (props) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const [state, setState] = useState({})

  useEffect(() => {

  })

  return (
    <div>
      BusinessBookCategoryExceptionListContainer
    </div>
  )
}

const enhance = connect(
  state => ({
    ...state
  }),
  {}
);

export default enhance(BusinessBookCategoryExceptionListContainer);
