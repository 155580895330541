import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Network from "../../library/Network";
import { useQuery } from "../../library/useQuery";
import LoaderComponent from "../../components/shared/LoaderComponent";
import { Container, Segment } from "semantic-ui-react";
import ScrapComponent from "../../components/scrap/ScrapComponent";

const EventTempMonthlyHashTagDetailContainer = (props) => {


  const { hashTag } = useQuery();
  const [isLoaded, setIsLoaded] = useState(false)
  const [scraps, setScraps] = useState([])

  const _load = async () => {
    if(!hashTag) return;
    const res = await Network.eventTempMonthlyHashTagGET({
      hashTag: hashTag,
    })
    if(!res.err) {
      setIsLoaded(true);
      setScraps(res.scraps || [])
    }
  }

  useEffect(() => {
    _load();
  }, [])

  if(!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical basic>

      </Container>
      <Container as={Segment} vertical basic>
        {
          scraps.map((o,i)=>{
            return (
              <ScrapComponent
                key={i}
                scrap={o}
              />
            )
          })
        }
      </Container>
    </>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(EventTempMonthlyHashTagDetailContainer);
