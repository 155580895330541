import {createLogicMiddleware} from 'redux-logic';
import {composeWithDevTools} from 'redux-devtools-extension';
import {combineReducers, createStore, applyMiddleware} from "redux"

import rootReducer from './rootReducer';
import arrLogic from './logic';

const deps = {};


const logicMiddleware = createLogicMiddleware(arrLogic, deps);
const middleware = applyMiddleware(logicMiddleware);
const enhance = composeWithDevTools(middleware);

const store = createStore(
  rootReducer,
  enhance
);

export default store;
