import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from "../../library/useQuery";
import { useHistory } from "react-router";
import Network from "../../library/Network";
import LoaderComponent from "../../components/shared/LoaderComponent";
import { Button, Container, Form, List, Message, Pagination, Radio, Segment, Table } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const BookCategoryListContainer = (props) => {

  let { activePage, perPage, keyword } = useQuery();
  activePage = !!activePage ? Number(activePage) : 1;
  perPage = !!perPage ? Number(perPage) : 20;
  keyword = !!keyword ? keyword : '';

  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [meta, setMeta] = useState({})
  const [searchValue, setSearchValue] = useState(keyword)
  const [bookCategories, setBookCategories] = useState([])

  const _load = async () => {
    let res
    if(!!keyword) {
      res = await Network.bookCategorySearchGET(
        {
          offset: ( activePage - 1 ) * perPage,
          limit: perPage,
          keyword,
        },
      )
    } else {
      res = await Network.bookCategoryListGET(
        {
          offset: ( activePage - 1 ) * perPage,
          limit: perPage,
        },
      )
    }
    if(!res.err) {
      setMeta(res.meta);
      setBookCategories(res.bookCategories || []);
      setIsLoaded(true);
    }
  }
  const _handleAddBusinessBookCategoryDisabled = async (bookCategoryId) => {
    setUploading(true);
    const res = await Network
      .businessBookCategoryDisabledPOST({
        bookCategoryId,
      })

    if(!res.err) {
      await _load()
    }
    setUploading(false);
  }
  const _handleRemoveBusinessBookCategoryDisabled = async (bookCategoryId) => {
    setUploading(true);
    const res = await Network
      .businessBookCategoryDisabledDELETE(
        {
          bookCategoryId,
        },
      )
    if(!res.err) {
      await _load();
    }
    setUploading(false);
  }


  useEffect(() => {
    _load();
  }, [activePage, perPage, keyword])

  const _handleChangeQuery = (
    {
      newActivePage = activePage,
      newPerPage = perPage,
      newKeyword = keyword,
    },
  ) => {
    history.push(`/book-category/list?activePage=${newActivePage}&perPage=${newPerPage}&keyword=${newKeyword}`)
  }

  if(!isLoaded) return <LoaderComponent/>;

  return (
    <>
      <Container as={Segment} vertical>
        <Message>
          <Message.Content>
            <List bulleted>
              <List.Item>
                {meta.total}개의 카테고리가 있습니다.
              </List.Item>
              <List.Item>
                {meta.totalDisabled}개의 카테고리가 신청불가로 처리되어 있습니다.
              </List.Item>
            </List>
          </Message.Content>
        </Message>
        <Form>
          <Form.Input
            action
            value={searchValue}
            onChange={(e, { value }) => {
              setSearchValue(value);
            }}
          >
            <input/>
            <Button
              onClick={( event => {
                setBookCategories([]);
                _handleChangeQuery({ newKeyword: searchValue, newActivePage: 1 })
              } )}
              icon={'search'}/>
          </Form.Input>
        </Form>
      </Container>
      <Container as={Segment} vertical>
        <Pagination
          activePage={activePage}
          totalPages={meta?.totalPage}
          onPageChange={(e, { activePage }) => {
            _handleChangeQuery({ newActivePage: activePage });
          }}
        />
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>id</Table.HeaderCell>
              <Table.HeaderCell>mall</Table.HeaderCell>
              <Table.HeaderCell>카테고리명</Table.HeaderCell>
              <Table.HeaderCell>Depth1</Table.HeaderCell>
              <Table.HeaderCell>Depth2</Table.HeaderCell>
              <Table.HeaderCell>Depth3</Table.HeaderCell>
              <Table.HeaderCell>신청불가처리</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              bookCategories?.map((bc, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{( activePage - 1 ) * perPage + i + 1}</Table.Cell>
                    <Table.Cell>{bc.id}</Table.Cell>
                    <Table.Cell>{bc.mall}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/book-category/${bc.id}`}>
                        {bc.aladinCategoryName}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{bc.depth_1}</Table.Cell>
                    <Table.Cell>{bc.depth_2}</Table.Cell>
                    <Table.Cell>{bc.depth_3}</Table.Cell>
                    <Table.Cell>
                      <Radio
                        onChange={(e, { checked }) => {
                          console.log(checked)
                          if(checked){
                            _handleAddBusinessBookCategoryDisabled(bc.id);
                          } else {
                            _handleRemoveBusinessBookCategoryDisabled(bc.id);
                          }
                        }}
                        checked={!!bc.businessBookCategoryDisabled}/>
                    </Table.Cell>
                  </Table.Row>
                )
              })
            }
          </Table.Body>
        </Table>

      </Container>
    </>
  )
}

const enhance = connect(
  state => ( {
    ...state,
  } ),
  {},
);

export default enhance(BookCategoryListContainer);
