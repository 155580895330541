import React, {Component, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Form, Modal} from "semantic-ui-react";
import Network from "../../library/Network";

const SmsButtonComponent = (props) => {

  const [uploading, setUploading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const [phoneNumbers, setPhoneNumbers] = useState(props.phoneNumbers || [])
  const [phoneNumberOptions, setPhoneNumberOptions] = useState(props.phoneNumbers || [])
  const [message, setMessage] = useState(props.message || '');
  const [title, setTitle] = useState(props.title || '');
  const [callback, setCallback] = useState('0221351779');


  const _handleOpenModal = () => {
    setModalOpen(true);
  }
  const _handleCloseModal = () => {
    setModalOpen(false);
    setMessage('');
    setTitle('');
  }

  const _handleComplete = async () => {
    await props.onComplete?.();
  }

  const _sendSms = async () => {
    if (!message || message.trim() === '') return window.alert('메시지를 입력해 주세요.');
    if (!window.confirm('메시지를 발송할까요?')) return;
    setUploading(true);
    const res = await Network.smsPOST({
      phoneNumbers,
      message,
      title,
      callback,
    })
    if (!res.err) {
      setMessage('');
      setTitle('');
      _handleCloseModal();
    }
    setUploading(false);
    await _handleComplete();

  }


  useEffect(() => {
    setPhoneNumbers(props.phoneNumbers);
    setPhoneNumberOptions(props.phoneNumbers)
  }, [props.phoneNumbers])


  return (
    <>
      <Modal
        size={'small'}
        open={modalOpen}>
        <Modal.Header>
          문자보내기
        </Modal.Header>
        <Modal.Content
          scrolling
          // style={{ height: 500 }}
        >
          <Form>
            <Form.Dropdown
              label={'수신 번호'}
              selection
              search
              clearable
              multiple
              value={phoneNumbers}
              onChange={(e, {value}) => {
                setPhoneNumbers(value)
              }}
              options={phoneNumberOptions?.map(o => ({key: o, value: o, text: o}))}
            />
            <Form.Input
              label={'제목'}
              placeholder={'제목 (선택)'}
              value={title}
              onChange={(e, {value}) => {
                setTitle(value);
              }}
            />
            <Form.TextArea
              label={'내용'}
              placeholder={'내용을 입력해 주세요.'}
              rows={8}
              value={message}
              onChange={(e, {value}) => {
                setMessage(value);
              }}
            />
            <Form.Input
              label={'발신번호'}
              disabled={true}
              placeholder={'제목 (선택)'}
              value={callback}

            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content={'취소'}
                  disabled={uploading}
                  onClick={_handleCloseModal}
                  basic
                  floated={'left'}/>
          <Button content={'보내기'}
                  disabled={uploading}
                  loading={uploading}
                  onClick={_sendSms}
                  positive/>
        </Modal.Actions>
      </Modal>
      <Button
        size={props.size || 'medium'}
        basic={props.basic}
        content={props.buttonText ||'SMS 보내기'}
        onClick={_handleOpenModal}/>
    </>
  )
}

const enhance = connect(
  state => ({
    ...state,
  }),
  {},
);

export default enhance(SmsButtonComponent);
